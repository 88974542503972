// this is the Drive module come soon page with introduction
import React, {useState, useEffect} from "react";
import "./ComeSoon.css";
import Buttoon from '../../Components/ui/Button/Button'
import Mail from "../../Components/icons/General/mail";
import DriveImage from "../../image/DriveImage.png"

const DriveComeSoon = () => {
    const [isNoticeAdmin, setIstNoticeAdmin] = useState(false);

    useEffect(() => {
        const localNoticed = localStorage.getItem("noticedDrive");
        setIstNoticeAdmin(localNoticed);
    }, [setIstNoticeAdmin]);

    // the button function: notice admin
    const noticeAdminButton = () => {
        setIstNoticeAdmin(true);
        localStorage.setItem("noticedDrive", isNoticeAdmin);
    }

    return <div className="achieve-area">
        <div className="achieveContext">
            <div className="InfoSection">
                <h1>
                    Unlock <span className="highlight">Holistic Decision Making & Scenario Modeling</span> in The Drive Module
                </h1>
                <p>
                    Contact Your Team Admin to Make Billions of Engineering Data Points Now Made Actionable
                </p>
                <Buttoon
                    variant="primary"
                    size="small"
                    width="100%"
                    onClick={noticeAdminButton}
                > 
                   {isNoticeAdmin ? "Admin Notified" : "Notify admin"}
                </Buttoon>

                <div className="svgIcon"> <Mail size={24} color="white" /> </div>

                <div className="contact">
                    <p className="title">Contact us</p>
                    <p className="subTitle">We're here to help.</p>
                    <a href="mailto:Support@apiphany.ai" className="emailText">Support@apiphany.ai</a>
                </div>
            </div>

            <div className="DashboardImage">
                <img src={DriveImage} alt="Drive module" />
                <h3>Issue & Component interaction and visibility of</h3>
                <ul>
                    <li>Dependencies and bottlenecks</li>
                    <li>Corrective action implications on properties including time, cost, and investment</li>
                    <li>Business case generation</li>
                    <li>N-th degree interactions and stakeholder updates</li>
                    <li>Component performance and failure rate history</li>
                </ul>
            </div>
        </div>

    </div>

};

export default DriveComeSoon;