// Manufacturer Data Page: the page below the Achieve mdoule issue overview 
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import './ManufacturerPage.css'
import { ChevronRight } from "../../../../Components/icons/Arrows";

const ManufacturerPage = () => {

    const { manufacturerName } = useParams(); // get the user selected Manufacturer

    const navigate = useNavigate();

    //Jump back to issue overview page
    const handleBackPage = () => {
        navigate("/achieve/status-overview");
    }

    return <div>
         <div className="pageRoute">
            <button className={`routeButton DisableClicked`}>Achieve</button>
            <ChevronRight size={16} color="#D0D5DD" />
            <button className="routeButton" onClick={handleBackPage}> Status overview </button>
            <ChevronRight size={16} color="#D0D5DD" />
            <button className={`routeButton isClicked`}> Manufacturer: {manufacturerName} </button>
        </div>
    </div>
}

export default ManufacturerPage;
