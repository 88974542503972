// the Bar chart customize area and UI
import React from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import "./BarChart.css";

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const BarChart = ({ height, width, data }) => {
  const chartData = {
    labels: Object.keys(data), // year
    datasets: [
      {
        label: "Total Cost Index",
        data: Object.values(data), // value
        backgroundColor: "#2E90FA",
        borderColor: "#2E90FA",
        borderWidth: 1,
        barThickness: 32,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: { display: false },  
        title: { display: false },  
      },
    scales: {
      x: {
        title: {
          display: true,
          text: "Year",
          font: { size: 12, family: "Inter, sans-serif" },
        },
      },
      y: {
        title: {
          display: true,
          text: "Total Cost Index (1999-2012)",
          font: { size: 12, family: "Inter, sans-serif" },
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="BarChartArea" style={{ minHeight: height, maxHeight: height, minWidth: width, maxWidth: width }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

BarChart.propTypes = {
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default BarChart;