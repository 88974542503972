// This is search field component with tag menu: The tag menu is highest level with input search to search the relative issue first
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./SearchField.module.css";
import { Badge } from "../Badge";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "../DropdownMenu/DropdownMenu";
import { Close } from "../../icons/General";
import { useEffect } from "react";
import Button from "../Button/Button";

// judge the "Add All" checkbox display checked mark or indeterminate mark ==> based on how many item user selcted
const getSelectionState = (filters) => {
  const checkedCount = filters.filter((f) => f.checked).length;
  return {
    checked: checkedCount > 0 && checkedCount <= filters.length,
    indeterminate: checkedCount > 0 && checkedCount < filters.length,
  };
};

export default function SearchField({
  placeholder = "Search...", // Setting a default value here
  onSearch,
  initialTerms = [], // Setting a default value here
  filterOptions = {
    status: [],
    system: [],
    requestor: [],
    stages: [],
    areas: [],
  },
  basicValue = [],
  setBasicValue,
  handleSubmit,
  dropDownMenuData = [],
}) {
  const [searchTerms, setSearchTerms] = useState(initialTerms); // the tag that user added it
  const [inputValue, setInputValue] = useState(""); // the user input string of search field
  const inputRef = useRef(null); // judge the scroll effect of search field input value area
  const searchFieldRef = useRef(null); // the search field ref
  const dropdownRef = useRef(null); // the filter dropdown menu ref
  const [activeMenu, setActiveMenu] = useState("status"); // the default page of filter menu open
  const [open, setOpen] = useState(false); 
  const searchTermsRef = useRef(null); // the search field tags ref

  const [filters, setFilters] = useState({}); // the filter menu of search field with user selected

  // the dropdown menu based on user input and support possible issue ids
  const [filteredIssues, setFilteredIssues] = useState([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    if(basicValue && basicValue.length > 0){
      setSearchTerms(basicValue);
    }
  }, [basicValue]);
  
  
  // the filter menu show up filter selection 
  useEffect(() => {
    if (
      filterOptions &&
      Object.values(filterOptions).some((arr) => Array.isArray(arr) && arr.length > 0)
    ) {
      const newFilters = Object.entries(filterOptions).reduce(
        (acc, [key, values]) => {
          if (!Array.isArray(values)) return acc; // Make sure values ​​is an array
          acc[key] = values
            .filter((value) => value != null && value !== "")
            .map((value) => {
              const valueString = value.toString().toLowerCase().replace(/\s+/g, "-");
              const id = `${key}-${valueString}`;
              const checked = !!searchTerms.find((term) => term.id === `filter-${id}`);
              //  const checked = !!searchTerms.find((term) => term.id === `filter-${id}` || (term.fieldKey === 'tags' && term.value === value.toString()));
              return { id, label: value, checked };
            });
          return acc;
        },
        {}
      );
  
      // console.log(newFilters);
      setFilters(newFilters);
    }
  }, [filterOptions, searchTerms]);
  

  // the dropdown menu of search field filter menu layout and style
  useEffect(() => {
    if (searchFieldRef.current && dropdownRef.current) {
      const searchFieldRect = searchFieldRef.current.getBoundingClientRect();
      dropdownRef.current.style.width = `${searchFieldRect.width}px`;
      dropdownRef.current.style.left = `${searchFieldRect.left}px`;
      dropdownRef.current.style.top = `${searchFieldRect.bottom}px`;
    }
  }, []);

  // user input function of search field
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    // Filter issues based on input value shows in dropdown menu
    const matches = dropDownMenuData.filter((issue) =>
      issue.startsWith(value)
    );
    setFilteredIssues(matches);

    setDropdownVisible(value.length > 0 && matches.length > 0);
  };

  // the drop down menu: added user selected issue id or issue query into search field
  const handleSelectIssue = (e, issue) => {
    setInputValue(issue);

    const newTerm = {
      id: Date.now().toString(),
      value: issue.toString().trim(),
      isIssueId: true,
      fieldKey: "query"
    };

    const updatedTerms = [...searchTerms, newTerm];
    setSearchTerms(updatedTerms);
    setBasicValue(updatedTerms);
    setDropdownVisible(false);
    setInputValue("");
    onSearch(e, updatedTerms, true);
  };

  // the function used for user input tags, "," to split user input value to be tags
  // and call the search function
  const handleInputKeyDown = (e) => {
    if (e.key === "," && inputValue.trim()) {
      e.preventDefault(); // Prevent comma from being typed
      const isNumber = /^-?\d+(\.\d+)?$/.test(inputValue);
      // console.log(isNumber);
      const newTerm = {
        id: isNumber ? Date.now().toString() : `filter-keywords-${inputValue.trim().toLowerCase().replace(/\s+/g, "-")}`,
        value: inputValue.trim(),
        isIssueId: isNumber,
        fieldKey: isNumber ? 'query' : 'keywords',
      };
      const updatedTerms = [...searchTerms, newTerm];
      setSearchTerms(updatedTerms);
      setInputValue("");
      setDropdownVisible(false);
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (inputValue.trim()) {
        // If there's text in the input, add it as a term first
        const isNumber = /^-?\d+(\.\d+)?$/.test(inputValue);
        // console.log(isNumber);
        const newTerm = {
          id: isNumber ? Date.now().toString() : `filter-keywords-${inputValue.trim().toLowerCase().replace(/\s+/g, "-")}`,
          value: inputValue.trim(),
          isIssueId: isNumber,
          fieldKey: isNumber ? 'query' : 'keywords',
        };
        const updatedTerms = [...searchTerms, newTerm];
        setSearchTerms(updatedTerms);
        setInputValue("");
        setDropdownVisible(false);
        onSearch(e, updatedTerms, true); // Pass true to indicate Enter was pressed
      } else {
        // If input is empty, just trigger search with existing terms
        onSearch(e, searchTerms, true);
      }
    }
  };

  // the search button click function ==> if only input value, without keywords, it also could start searching
  const handleClick = (e) => {
    e.preventDefault();
    if (inputValue.trim()) {
      // If there's text in the input, add it as a term first
      const isNumber = /^-?\d+(\.\d+)?$/.test(inputValue);
      // console.log(isNumber);
      const newTerm = {
        id: isNumber ? Date.now().toString() : `filter-keywords-${inputValue.trim().toLowerCase().replace(/\s+/g, "-")}`,
        value: inputValue.trim(),
        isIssueId: isNumber,
        fieldKey: isNumber ? 'query' : 'keywords',
      };
      const updatedTerms = [...searchTerms, newTerm];
      setSearchTerms(updatedTerms);
      setInputValue("");
      setDropdownVisible(false);
      handleSubmit(e, updatedTerms); // Pass true to indicate Enter was pressed
    } else{
      handleSubmit(e, searchTerms); 
    }
  }

  // the function used for remove the keywords of badges close icon
  const removeTerm = (id) => {
    const updatedTerms = searchTerms.filter((term) => term.id !== id);
    if (id.startsWith("filter-status-")) { // the status
      const filterId = id.replace("filter-", "");
      setFilters((prev) => ({
        ...prev,
        status: prev.status.map((filter) =>
          filter.id === filterId ? { ...filter, checked: false } : filter,
        ),
      }));
    } else if (id.startsWith("filter-system-")) { // the system
      const filterId = id.replace("filter-", ""); 
      setFilters((prev) => ({
        ...prev,
        system: prev.system.map((filter) =>
          filter.id === filterId ? { ...filter, checked: false } : filter,
        ),
      }));
    } else if (id.startsWith("filter-requestor-")) { // the issue owner
      const filterId = id.replace("filter-", "");
      setFilters((prev) => ({
        ...prev,
        requestor: prev.requestor.map((filter) =>
          filter.id === filterId ? { ...filter, checked: false } : filter,
        ),
      }));
    } 
    setSearchTerms(updatedTerms);
  };

  // the clear icon of search field: remove all the badges item
  const clearAllTerms = () => {
    setSearchTerms([]);
    onSearch([]);
    inputRef.current?.focus();
  };

 
  // the filter drop down menu of search field: user could select the filter tags
  const handleFilterChange = (type, id) => {
    setFilters((prev) => {
      const newFilters = {
        ...prev,
        [type]: prev[type].map((filter) => {
          if (filter.id === id) {
            const newChecked = !filter.checked;
            if (newChecked) {
              setSearchTerms((prevTerms) => {
                const newTerms = [
                  ...prevTerms,
                  {
                    id: `filter-${filter.id}`,
                    value: filter.label,
                    isIssueId: false,
                    fieldKey: type,
                  },
                ];
                return newTerms;
              });
            } else {
              setSearchTerms((prevTerms) => {
                const newTerms = prevTerms.filter(
                  (term) => term.id !== `filter-${filter.id}`,
                );
                return newTerms;
              });
            }
            return { ...filter, checked: newChecked };
          }
          return filter;
        }),
      };
      return newFilters;
    });
  };

  const handleSelectAll = (type) => {
    setFilters((prev) => {
      const newFilters = {
        ...prev,
        [type]: prev[type].map((filter) => {
          if (!filter.checked) {
            setSearchTerms((prevTerms) => {
              const newTerms = [
                ...prevTerms,
                {
                  id: `filter-${filter.id}`,
                  value: filter.label,
                  isIssueId: false,
                  fieldKey: type,
                },
              ];
              // onSearch(newTerms); ==> only when user click search button or enter keyboard, it will call search function
              return newTerms;
            });
          }
          return { ...filter, checked: true };
        }),
      };
      return newFilters;
    });
  };

  // the filter menu "Clear All" Button function: remove the single page tags
  const handleClearAll = (type) => {
    setFilters((prev) => ({
      ...prev,
      [type]: prev[type].map((filter) => ({ ...filter, checked: false })),
    }));

    setSearchTerms((prevTerms) => {
      const newTerms = prevTerms.filter(
        (term) => !term.id.startsWith(`filter-`),
      );
      // onSearch(newTerms); ==> only when user click search button or enter keyboard, it will call search function
      return newTerms;
    });
  };

  // based on the searchTerms total length, will have scroll effect
  useEffect(() => {
    if (searchTermsRef.current) {
      const element = searchTermsRef.current;
      const parent = element.parentElement;
      parent.scrollTo({
        left: element.scrollWidth,
        behavior: "smooth",
      });
    }
  }, [searchTerms]);

  // if did not have any data, display "No content available"
  const renderFilterContent = () => {
    if (!filters[activeMenu]) {
      return (
        <div className={styles.filterMenuSubContent}>
          <div>No content available</div>
        </div>
      );
    }

    const { checked, indeterminate } = getSelectionState(filters[activeMenu]);

    return (
      <div className={styles.filterMenuSubContent}>
        <div className={styles.filterMenuFixedTop}>
          <DropdownMenuCheckboxItem
            checked={checked}
            onClick={(e) => {
              e.preventDefault();
              handleSelectAll(activeMenu);
            }}
            indeterminate={indeterminate}
          >
            Select all
          </DropdownMenuCheckboxItem>
        </div>
        <DropdownMenuSeparator />
        <div className={styles.filterMenuScrollable}>
          {filters[activeMenu].map((filter) => (
            <DropdownMenuCheckboxItem
              key={filter.id}
              checked={filter.checked}
              onClick={(e) => {
                e.preventDefault();
                handleFilterChange(activeMenu, filter.id);
              }}
            >
              {activeMenu === "status" ? (  
                
                <Badge type="status" variant={(filter.label === "Closed" || filter.label === "Open" || filter.label === "Current") ? filter.label : "Other"}>{filter.label}</Badge>
              ) : (
                filter.label
              )}
            </DropdownMenuCheckboxItem>
          ))}
        </div>
        <DropdownMenuSeparator />
        <div className={styles.filterMenuFixedBottom} style={{ height: "32px", marginLeft: "2px" }}>
          <Button variant="danger-link" size="small"
            onClick={(e) => {
              e.preventDefault();
              handleClearAll(activeMenu);
            }}
          >
            Clear all
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.searchArea}>
      <div className={styles.searchField} ref={searchFieldRef}>
        <div style={{ display: "flex", alignItems: "center", flex: 1, height: "28px" }}>
          <div
            className={styles.searchTermsContainer}
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.focus(); // handle setup the active pointer
              }
            }}
          >
            <div className={styles.searchTerms} ref={searchTermsRef}>
              {searchTerms.map((term) => (
                <Badge
                  key={term.id}
                  type="input"
                  variant="default"
                  onClick={() => removeTerm(term.id)}
                >
                  {term.isIssueId ? `issue id: ${term.value}` : `${term.fieldKey}: ${term.value}`}
                </Badge>
              ))}
            </div>
          </div>


          <input
            ref={inputRef}
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            className={styles.searchInput}
            placeholder={placeholder}
          />

          {/* the dropdown menu to display the possible issue Id and auto complete */}
          {isDropdownVisible && (
            <div className={styles.dropDown}>
              {filteredIssues.map((issue) => (
                <div
                  key={issue}
                  onClick={(e) => handleSelectIssue(e, issue)}
                  className={styles.dropDownRow}
                >
                  {issue}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={styles.searchActions}>
          <div>
            {searchTerms.length > 0 && (
              <button onClick={clearAllTerms} className={styles.clearButton}>
                <Close size={16} className={styles.clearIcon} />
              </button>
            )}
          </div>
          <DropdownMenu open={open} onOpenChange={setOpen}>
            {searchTerms.length > 0 && <DropdownMenuTrigger filterIcon height="32px" width="20px">
              <span className={styles.dropdownMenuTriggerText}>Filters</span>
            </DropdownMenuTrigger>}

            <DropdownMenuContent
              className={styles.filterMenuContent}
              filterIcon
              align="end"
              alignOffset={-14}
              sideOffset={8}
            >
              <div className={styles.filterMenuContainer}>
                <div className={styles.filterMenuMain}>
                  <DropdownMenuSub defaultOpen>
                    {Object.keys(filters).map((filterType) => (
                      <DropdownMenuSubTrigger
                        key={filterType}
                        filterIcon
                        onClick={() => setActiveMenu(filterType)}
                        data-active={activeMenu === filterType}
                      >
                        <span className="filterMenuSubContentItem">
                          {filterType.charAt(0).toUpperCase() +
                            filterType.slice(1)}
                        </span>
                      </DropdownMenuSubTrigger>
                    ))}
                  </DropdownMenuSub>
                </div>

                <div className={styles.filterMenuSub}>
                  {renderFilterContent()}
                </div>
              </div>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      <Button size="small" onClick={handleClick}>
        Search
      </Button>
    </div>
  );
}

SearchField.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
  initialTerms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      isIssueId: PropTypes.bool.isRequired,
      fieldKey: PropTypes.string.isRequired,
    }),
  ),
  filterOptions: PropTypes.shape({
    status: PropTypes.arrayOf(PropTypes.string),
    system: PropTypes.arrayOf(PropTypes.string),
    requestor: PropTypes.arrayOf(PropTypes.string),
    stages: PropTypes.arrayOf(PropTypes.string),
    areas: PropTypes.arrayOf(PropTypes.string),
  }),
  dropDownMenuData: PropTypes.array,
  basicValue: PropTypes.array,
  setBasicValue: PropTypes.func,
  handleSubmit: PropTypes.func,
};
