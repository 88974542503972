import React, { useState, useRef, useEffect } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import './tooltip.css';
import TooltipManager from './tooltipManager';

const RowTooltip = ({ 
  children, 
  content,
  enabled = true,
  delay = [200, 0],
  placement: initialPlacement = "top"
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [placement, setPlacement] = useState(initialPlacement);

  const tooltipTimer = useRef(null);
  const tooltipId = useRef(`row-${Math.random().toString(36).substring(2, 9)}`).current;

  useEffect(() => {
    return () => {
      if (tooltipTimer.current) {
        clearTimeout(tooltipTimer.current);
      }
      TooltipManager.unregister(tooltipId);
    };
  }, [tooltipId]);

  // Helper to see if event is within sticky columns
  const isInStickyColumns = (e) => {
    // Check if the target or any ancestor has class="sticky-columns"
    return !!e.target.closest('.sticky-columns');
  };

  // =========== MOUSE ENTER =============
  const handleRowHover = (row, e) => {
    if (tooltipTimer.current) {
      clearTimeout(tooltipTimer.current);
    }

    // If inside sticky columns, DO NOT start the tooltip timer
    if (isInStickyColumns(e)) {
      return;
    }

    tooltipTimer.current = setTimeout(() => {
      const recommendedPlacement = TooltipManager.getRecommendedPlacement('top', 'row');
      setPlacement(recommendedPlacement);
      
      setShowTooltip(true);

      if (e) {
        setTooltipPosition({ 
          x: e.clientX, 
          y: e.clientY 
        });
      }
      TooltipManager.register(tooltipId, 'row', recommendedPlacement);
    }, delay[0]);
  };

  // =========== MOUSE LEAVE =============
  const handleRowLeave = (e) => {
    if (tooltipTimer.current) {
      clearTimeout(tooltipTimer.current);
      tooltipTimer.current = null;
    }
    setShowTooltip(false);
    TooltipManager.unregister(tooltipId);
  };

  // =========== MOUSE MOVE =============
  const handleMouseMove = (e) => {
    if (!enabled) return;

    // IF user enters sticky columns, hide tooltip immediately
    // (and clear any delayed show)
    if (isInStickyColumns(e)) {
      if (tooltipTimer.current) {
        clearTimeout(tooltipTimer.current);
        tooltipTimer.current = null;
      }
      setShowTooltip(false);
      TooltipManager.unregister(tooltipId);
      return;
    }

    // ELSE user is outside sticky columns
    if (showTooltip) {
      // If tooltip is currently visible, just update position
      setTooltipPosition({ 
        x: e.clientX, 
        y: e.clientY 
      });
    } else {
      // If tooltip was hidden, we can *optionally* re-show it after a delay
      // to simulate a mouseenter once again. For example:
      if (!tooltipTimer.current) {
        tooltipTimer.current = setTimeout(() => {
          const recommendedPlacement = TooltipManager.getRecommendedPlacement('top', 'row');
          setPlacement(recommendedPlacement);
          setShowTooltip(true);
          setTooltipPosition({ 
            x: e.clientX, 
            y: e.clientY 
          });
          TooltipManager.register(tooltipId, 'row', recommendedPlacement);
        }, delay[0]);
      }
    }
  };

  // =========== RENDER =============
  return (
    <>
      {React.cloneElement(children, {
        onMouseEnter: (e) => {
          if (enabled) {
            handleRowHover(content, e);
            if (children.props.onMouseEnter) {
              children.props.onMouseEnter(e);
            }
          }
        },
        onMouseLeave: (e) => {
          if (enabled) {
            handleRowLeave(e);
            if (children.props.onMouseLeave) {
              children.props.onMouseLeave(e);
            }
          }
        },
        onMouseMove: (e) => {
          if (enabled) {
            handleMouseMove(e);
            if (children.props.onMouseMove) {
              children.props.onMouseMove(e);
            }
          }
        }
      })}

      {showTooltip && content && (
        <Tippy
          content={content}
          placement={placement}
          theme="custom"
          visible={true}
          appendTo={document.body}
          popperOptions={{
            modifiers: [{
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            }],
          }}
        >
          <div style={{ 
            position: 'fixed', 
            top: `${tooltipPosition.y}px`, 
            left: `${tooltipPosition.x}px`,
            width: '1px',
            height: '1px',
            pointerEvents: 'none'
          }}></div>
        </Tippy>
      )}
    </>
  );
};

export default RowTooltip; 