import React, { useState, useEffect } from 'react';
import './CustomizeTable.css';
import { Checkbox, SwitchButton, TablePopWindow } from "../../../../Components";
import { ChevronLeft, ChevronRight} from "../../../../Components/icons/Arrows";
import { useDrop } from 'react-dnd';
import { RowTooltip } from "../../../../Components/tooltip";

const CustomizeTable = ({
    clickedIssue,
    rows,
    displayColumnswithCustomHeader,
    checkedRows,
    handleHeaderCheckboxChange,
    handleCheckboxChange,
    currentRowIndex,
    handleColumnResize,
    handleCellClick,
    IssuePageSize,
    setIssuePageSize,
    rowsHeight, // every rows default height
    tableViews,
    rowsHeightMap,  // the rows height that user changed it
    setRowsHeightMap,
    isOpenSpecification,
    handleRowHover,
    handleRowLeave,
    handleMouseMove,
    hoveredRow,
    blockIndex,

    // table pop window used function
    currentQuery,
    handleDelete,
    handleSave,
    handleShare,
    handleUnhide,
    handleHide,
    handleUnfavorite,
    handleFavorite,
    teamMembers,
    isTeamMemberLoading,
    isSharedView
}) => {

    /************************************** 1. the feature: column Resizing: click to change selected column width  ***************************************/ 
    // Track if we're currently resizing a column
    const [isResizing, setIsResizing] = useState(false); // whether starting resizing the column width
    const [resizeStartX, setResizeStartX] = useState(0); // the position of column width
    const [resizingColumn, setResizingColumn] = useState(null);
    const [resizeLineX, setResizeLineX] = useState(null); // the resize line used to show the new position

    // Handle the start of column resize
    const startResize = (e, column) => {
        e.stopPropagation();
        setIsResizing(true);
        setResizeStartX(e.clientX);
        setResizingColumn(column);
        setResizeLineX(e.clientX); // the mouse start position
    };

    // Handle the mouse move during resize
    const handleResize = (e) => {
        if (!isResizing) return;
        setResizeLineX(e.clientX); // update the new column width line
    };

    // Handle the end of resize
    const endResize = () => {
        if (isResizing && resizingColumn) {
            const deltaX = resizeLineX - resizeStartX;
            const newWidth = Math.max(25, resizingColumn.width + deltaX);
    
            // Update column width
            handleColumnResize({
                width: newWidth,
                colDef: { field: resizingColumn.field },
            });
        }
        setIsResizing(false);
        setResizingColumn(null);
        setResizeLineX(null); // hiden the resize line
    };

    // Add resize event listeners
    useEffect(() => {
        if (isResizing) {
            window.addEventListener('mousemove', handleResize);
            window.addEventListener('mouseup', endResize);
        }
        return () => {
            window.removeEventListener('mousemove', handleResize);
            window.removeEventListener('mouseup', endResize);
        };
    }, // eslint-disable-next-line 
    [isResizing, resizingColumn, resizeLineX]);

    /************************************************ 2. the feature: row height Resizing: click to change selected rows height  ***************************************/ 
    // Issue ID was each row union key
    const [selectedRows, setSelectedRows] = useState(new Set()); // save user selected rows (Issue ID)
    const [isRowResizing, setIsRowResizing] = useState(false); // means was changing the height
    const [resizeStartY, setResizeStartY] = useState(0); //   used to caculate the rows height
    const [resizeLineY, setResizeLineY] = useState(null); // the resize line position => used to caculate the rows height
    const [lastSelectedRow, setLastSelectedRow] = useState(null); // used for Shift multiple selection logic
    // multiple selection of non-contiguous rows
    // the function used for user selection rows: single select and shift+left click do multiple selection
    const handleRowSelect = (rowId, event) => {
        // Shift-click for multiple selection
        if (event.shiftKey && lastSelectedRow) {
            const newSelected = new Set(selectedRows);
            newSelected.add(rowId);
            setSelectedRows(newSelected);
            setLastSelectedRow(rowId);
        }
        // Command/Meta key for multiple selection
        else if(event.metaKey) {
            const newSelected = new Set(selectedRows);
            if (newSelected.has(rowId)) {
                // If the row is already selected, unselect it
                newSelected.delete(rowId);
            } else {
                // If the row is not selected, select it
                newSelected.add(rowId);
            }
            setSelectedRows(newSelected); // update selection rows
            setLastSelectedRow(rowId);
        }
        // Single click behavior
        else {
            const newSelected = new Set(selectedRows);
            if (newSelected.has(rowId)) {
                // If the row is already selected, unselect it
                newSelected.delete(rowId);
            } else {
                // If not selected, clear other selections and select this one
                newSelected.clear();
                newSelected.add(rowId);
            }
            setLastSelectedRow(rowId); // Save the user's last picked row (used for multiple selection)
            setSelectedRows(newSelected); // Update user selection row array
        }
    };

    // the function used to select all rows
    const handleAllRowSelect = (e) => {
        e.preventDefault();
        setSelectedRows((prev) => {
            // Convert prev (Set) to an array to check if all rows are selected
            const prevArray = Array.from(prev);
            const allRowIds = rows.map(row => row['Issue ID']);
            const allSelected = allRowIds.every(id => prevArray.includes(id));
            return allSelected ? new Set() : new Set(allRowIds);    
        });
    }
    
    
    // start working resize row's height
    const startRowResize = (e) => {
        if (selectedRows.size === 0) return; // if not any selection, return
        e.preventDefault();  
        setIsRowResizing(true); 
        setResizeStartY(e.clientY); // the start position Y
        setResizeLineY(e.clientY); // the resize line position
    };

    // the function to changing height through change the resize line Y position
    const handleRowResize = (e) => {
        if (!isRowResizing) return;
        setResizeLineY(e.clientY);
        // console.log("Mouse move Y:", e.clientY);
    };

    // closed the changing resize height ==> calculate the new row height
    const endRowResize = () => {
        if (isRowResizing) {
            const deltaY = resizeLineY - resizeStartY; // Calculate mouse moving distance
            const baseHeight = 100; // Minimum row height
            const miniHeight = 25;
            const currentHeight = rowsHeightMap[Array.from(selectedRows)[0]] || baseHeight; // Get the current height
            const newHeight = Math.max(miniHeight, currentHeight + deltaY); // Ensure new height is valid
    
            if (deltaY !== 0) { // Only update if there's an actual change
                const newHeightMap = { ...rowsHeightMap };
                selectedRows.forEach(rowId => {
                    newHeightMap[rowId] = newHeight; 
                });
    
                setRowsHeightMap(newHeightMap); // Update the height map
            }
        }
    
        // Reset resizing state
        setIsRowResizing(false);
        setResizeLineY(null);
    };

    // add the listener event to check whether happened row height change, and update in first time
    useEffect(() => {
        if (isRowResizing) {
            const handleMouseMove = (e) => handleRowResize(e);
            const handleMouseUp = () => endRowResize();
    
            window.addEventListener("mousemove", handleMouseMove);
            window.addEventListener("mouseup", handleMouseUp);
    
            return () => {
                window.removeEventListener("mousemove", handleMouseMove);
                window.removeEventListener("mouseup", handleMouseUp);
            };
        }
        // eslint-disable-next-line
    }, [isRowResizing, resizeLineY]);

    /************************************************ 3. the page navigation part including pervious and next page function  ***************************************************************/ 
    const { pageSize, page } = IssuePageSize;

    const totalPages = Math.ceil(rows.length / pageSize);

    const handlePrevPage = () => {
        if (page > 1) {
            setIssuePageSize({ ...IssuePageSize, page: page - 1 });
        }
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setIssuePageSize({ ...IssuePageSize, page: page + 1 });
        }
    };

    const startItem = Math.max(1, (page - 1) * pageSize + 1);
    const endItem = Math.min(page * pageSize, rows.length);

    
    /************************************************ 4. The all the column of issue table ***************************************************************/ 
    const allDisplayColumns = [
        {
            field: "Checkbox",
            headerName: "",
            width: 40,
            renderHeader: () => {
                return (
                    <div className='checkbox-header' onClick={(e) => e.stopPropagation()}>
                        <Checkbox
                            id={`header-checkbox-${blockIndex}`}
                            size="sm"
                            checked={checkedRows.size === rows.length && rows.length > 0}
                            indeterminate={checkedRows.size > 0 && checkedRows.size < rows.length}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (tableViews === 'Saved' || tableViews === 'Hidden' || tableViews === 'Shared' || tableViews === 'Starred') {
                                    handleHeaderCheckboxChange(blockIndex);
                                } else {
                                    handleHeaderCheckboxChange();
                                }
                            }}
                        />
                    </div>
                );
            },
            renderCell: (row) => (
                <div className='checkbox-cell' onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                        id={`checkbox-${row["Issue ID"]}`}
                        size="sm"
                        checked={checkedRows.has(row["Issue ID"])}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleCheckboxChange(row["Issue ID"])
                        }}
                    />
                </div>
            ),
        },
        ,
        ...displayColumnswithCustomHeader,
    ];

    // console.log(allDisplayColumns);

    const fixedColumns = allDisplayColumns.slice(0, 1); // the sticky column area: check box and star column
   // const scrollableColumns = allDisplayColumns.slice(1); // the general column area

    /************************************************ 4.2 The all the column of issue table: dropping and add column part ***************************************************************/ 
    const [scrollableColumns, setScrollableColumns] = useState(allDisplayColumns.slice(1)); // the state used for our scroall column area

    useEffect(() => {
        setScrollableColumns(allDisplayColumns.slice(1));
    }, [displayColumnswithCustomHeader]);

    // the react dnd library dropping function: display the column thet user selected in the issue table ==> tempoary not use it
    const DroppableColumnArea = ({ column, children, moveColumn }) => {
        const [{ isOver }, drop] = useDrop({
            accept: 'COLUMN',
            drop: (item, monitor) => {
                moveColumn(item.column, column.field); // call the move function
                return { targetField: column.field }; // return the correct target column that user selected it
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
            }),
        });
    
        return (
            <div
                ref={drop}
                className="droppable-column"
                style={{
                    display: "flex",
                    boxShadow: isOver ? "2px 0px 0px 0px black" : "none",  // Instead of borderLeft, use boxShadow
                }}
            >
                {children}
            </div>
        );        
    };
    
    
    // the function about drop column ==> tempoary not use it
    const handleColumnDrop = (draggedField, targetField) => {
        setScrollableColumns((prevColumns) => {
            const newColumns = [...prevColumns];
            const draggedIndex = newColumns.findIndex((col) => col.field === draggedField);
            const targetIndex = newColumns.findIndex((col) => col.field === targetField);
    
            if (draggedIndex === -1 || targetIndex === -1) return prevColumns;
    
            // Remove the dragged column
            const [draggedColumn] = newColumns.splice(draggedIndex, 1);
            // Insert the dragged column at the target position
            newColumns.splice(targetIndex, 0, draggedColumn);
    
            return newColumns;
        });
    };

    // the main render area
    return (
        <div className="table-container">
            {checkedRows.size > 0 && (
                <TablePopWindow
                    selectedCount={checkedRows.size}
                    checkedRows={checkedRows}

                    onSave={() => {
                        if (tableViews === 'Saved') {
                            return(tableViews === 'Saved' || tableViews === 'Hidden' || tableViews === 'Shared' || tableViews === 'Starred') ? handleDelete(blockIndex) : handleDelete();
                        } else {
                            return (tableViews === 'Saved' || tableViews === 'Hidden' || tableViews === 'Shared' || tableViews === 'Starred') ? handleSave(blockIndex) : handleSave();
                        }
                    }}
                    onShare={(selectedEmails) => (tableViews === 'Saved' || tableViews === 'Hidden' || tableViews === 'Shared' || tableViews === 'Starred') ? handleShare(blockIndex, selectedEmails, currentQuery) : handleShare(selectedEmails)}
                    onHide={() => {
                        if (tableViews === 'Hidden') {
                            return (tableViews === 'Saved' || tableViews === 'Hidden' || tableViews === 'Shared' || tableViews === 'Starred') ? handleUnhide(blockIndex) : handleUnhide();
                        } else {
                            return (tableViews === 'Saved' || tableViews === 'Hidden' || tableViews === 'Shared' || tableViews === 'Starred') ? handleHide(blockIndex) : handleHide();
                        }
                    }}
                    onFavorite={() => {
                        if (tableViews === 'Starred') {
                            return (tableViews === 'Saved' || tableViews === 'Hidden' || tableViews === 'Shared' || tableViews === 'Starred') ? handleUnfavorite(blockIndex) : handleUnfavorite();
                        } else {
                            return (tableViews === 'Saved' || tableViews === 'Hidden' || tableViews === 'Shared' || tableViews === 'Starred') ? handleFavorite(blockIndex) : handleFavorite();
                        }
                    }}
                    teamMember={teamMembers}
                    isTeamMemberLoading={isTeamMemberLoading}
                    tableView={tableViews}
                    top={isSharedView ? "142px" : "40px"}
                />
            )}

            {/*  the line used for row height change  */}
            {resizeLineY !== null && (
                <div
                    className="row-resize-line"
                    style={{
                        top: `${resizeLineY}px`,
                        minWidth: isOpenSpecification ? `calc(68vw - 35px)` : `calc(100vw - 38px)`,
                        maxWidth: isOpenSpecification ? `calc(68vw - 35px)` : `calc(100vw - 38px)`
                    }}
                />
            )}

            {/* the line used for column width change */}
            {resizeLineX !== null && (
                <div
                    className="resize-line"
                    style={{ left: `${resizeLineX}px` }}
                />
            )}

            <div className="table-scrollable-area">
                {/* Header Area */}
                <div className="table-header">

                    {/* the sticky two column header area */}
                    <div className="sticky-columns">
                        {fixedColumns.map((column, index) => (
                            <div
                                key={column.field + index }
                                className="header-cell"
                                style={{ width: `${column.width}px`, minWidth: `${column.minWidth}px`}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleAllRowSelect(e);
                                }}
                            >
                                <div className="header-content">
                                    {column.renderHeader ? column.renderHeader() : column.headerName}
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* the general area */}
                    {scrollableColumns.map((column) => (
                        // <DroppableColumnArea key={column.field} column={column} moveColumn={handleColumnDrop}>
                            <div
                                key={column.field}
                                className="header-cell"
                                style={{ width: `${column.width}px`, minWidth: `${column.minWidth}px` }}
                            >
                                <div className="header-content">
                                    {column.renderHeader
                                        ? column.renderHeader()
                                        : column.headerName}
                                </div>
                                {(column.field !== "Checkbox" && column.field !== "Star Issue") && <div
                                    className="resize-handle"
                                    onMouseDown={(e) => startResize(e, column)}
                                />}
                            </div>
                        // </DroppableColumnArea>
                    ))}
                </div>

                {/* Clicked Issue Row */}
                {(tableViews !== "Saved" && tableViews !== "Starred" && tableViews !== "Shared" && tableViews !== "Hidden" && clickedIssue) && (
                    <RowTooltip 
                        content={clickedIssue.issue_summary || clickedIssue.simplified_text || "No summary available"}
                    >
                        <div 
                            className="table-row clicked-issueRow"
                            style={{
                                minHeight: `${rowsHeightMap[clickedIssue["Issue ID"]] || rowsHeight}px`,
                                maxHeight: `${rowsHeightMap[clickedIssue["Issue ID"]] || rowsHeight}px`,
                            }}
                        >
                            {/* the sticky two column current click issue area */}
                            <div className="sticky-columns" style={{pointerEvents: isRowResizing ? 'none' : 'auto'}}>
                                {fixedColumns.map((column) => (
                                    <div
                                        key={column.field}
                                        className="stickyColumn-cell"
                                        style={{ width: `${column.width}px`, 
                                            minWidth: `${column.minWidth}px`,
                                            backgroundColor: selectedRows.has(clickedIssue["Issue ID"]) ? '#D1E9FF' : 'inherit'
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleRowSelect(clickedIssue["Issue ID"], e);
                                       // handleCellClick({ field: column.field, row: clickedIssue }, 0);
                                        }}
                                        onMouseEnter={() => handleRowHover(clickedIssue)}
                                        onMouseLeave={() => handleRowLeave()}
                                    >
                                        {column.renderCell ? column.renderCell(clickedIssue) : clickedIssue[column.field]}
                                        {(column.field === "Checkbox" && selectedRows.has(clickedIssue["Issue ID"])) && (
                                            <div
                                                className="row-resize-handle"
                                                onMouseDown={startRowResize}
                                                style={{
                                                    width: '100vw',
                                                    height: '4px',
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    cursor: 'row-resize',
                                                    position: 'absolute',
                                                    left: 0,
                                                    bottom: -1,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        height: '3px',
                                                        backgroundColor: "transparent",
                                                        // backgroundColor: 'black',
                                                    }}
                                                />
                                                
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>

                            {/* the general area and Package the item we need to dropping */}
                            {scrollableColumns.map((column) => (
                             
                                    <div
                                        key={column.field}
                                        className="table-cell"
                                        style={{
                                        width: `${column.width}px`,
                                            minWidth: `${column.minWidth}px`,
                                            backgroundColor: selectedRows.has(clickedIssue["Issue ID"]) ? '#F5FAFF' : 'inherit'
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleCellClick({ field: column.field, row: clickedIssue }, 0);
                                        }}
                                        onMouseEnter={(e) => handleRowHover(clickedIssue, e)}
                                    onMouseLeave={() => handleRowLeave()}
                                    onMouseMove={handleMouseMove}
                                >
                                        {column.renderCell
                                            ? column.renderCell(clickedIssue)
                                            : clickedIssue[column.field]
                                        }
                                    </div>
                             
                            ))}
                        </div>
                    </RowTooltip>
                )}

                {/* Table Content */}
                <div className="table-content" style={{ top: (tableViews !== "Saved" && tableViews !== "Starred" && tableViews !== "Shared" && tableViews !== "Hidden" && clickedIssue) ? `calc(${rowsHeightMap[clickedIssue["Issue ID"]] || rowsHeight}px + 41px)` : "41px" }}>
                    <div className="table-rows">
                        {rows.slice(startItem - 1, endItem)
                            // Only apply filter when in "All" view
                            .filter(row => tableViews === "All" ? row["Issue ID"] !== clickedIssue?.["Issue ID"] : true)
                            .map((row) => (
                                <RowTooltip 
                                    key={row["Issue ID"]}
                                    content={row.issue_summary || row.simplified_text || "No summary available"}
                                >
                                    <div 
                                        key={`${row["Issue ID"]}-${tableViews}`}
                                        className={`table-row ${row["Issue ID"] === rows[currentRowIndex]?.["Issue ID"] ? 'current-row' : ''}`}
                                        style={{
                                            minHeight: `${rowsHeightMap[row["Issue ID"]] || rowsHeight}px`,
                                            maxHeight: `${rowsHeightMap[row["Issue ID"]] || rowsHeight}px`,
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleCellClick({ field: row.field, row }, rows.findIndex(r => r["Issue ID"] === row["Issue ID"]));
                                        }}
                                    >
                                        {/* the sticky two column other rows */}
                                        <div className={`sticky-columns ${row["Issue ID"] === rows[currentRowIndex]?.["Issue ID"] ? 'current-row' : ''}`} style={{ pointerEvents: isRowResizing ? 'none' : 'auto' }}>
                                            {fixedColumns.map((column) => (
                                                <div
                                                    key={column.field}
                                                    className="stickyColumn-cell"
                                                    style={{ width: `${column.width}px`, backgroundColor: selectedRows.has(row["Issue ID"]) ? '#D1E9FF' : 'inherit'}}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleRowSelect(row["Issue ID"], e);
                                                    // const rowIndex = rows.findIndex(r => r["Issue ID"] === row["Issue ID"]);
                                                   //  handleCellClick({ field: column.field, row }, rowIndex);
                                                    }}
                                                    onMouseEnter={() => handleRowHover(row)}
                                                    onMouseLeave={() => handleRowLeave()}
                                                >
                                                    {column.renderCell ? column.renderCell(row) : row[column.field]}
                                                    {(column.field === "Checkbox" && selectedRows.has(row["Issue ID"])) && (
                                                        <div
                                                            className="row-resize-handle"
                                                            onMouseDown={startRowResize}
                                                            style={{
                                                                width: '100vw',
                                                                height: '4px',
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                cursor: 'row-resize',
                                                                position: 'absolute',
                                                                left: 0,
                                                                bottom: 0,
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    height: '4px',
                                                                    backgroundColor: "transparent",
                                                                    // backgroundColor: 'black',
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>

                                        {/* the general area */}
                                        {scrollableColumns.map((column) => (
                                            <div
                                                key={column.field}
                                                className="table-cell"
                                                style={{ width: `${column.width}px`, minWidth: `${column.minWidth}px`, backgroundColor: selectedRows.has(row["Issue ID"]) ? '#F5FAFF' : 'inherit' }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    const rowIndex = rows.findIndex(r => r["Issue ID"] === row["Issue ID"]);
                                                    handleCellClick({ field: column.field, row }, rowIndex);
                                                }}
                                                onMouseEnter={(e) => handleRowHover(row, e)}
                                                onMouseLeave={() => handleRowLeave()}
                                                onMouseMove={handleMouseMove}
                                            >
                                                {column.renderCell
                                                    ? column.renderCell(row)
                                                    : row[column.field]
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </RowTooltip>
                            ))}
                    </div>
                </div>
            </div>

            {/* Footer area: current page, page navigation area */}
            <div className="table-footer">
                <div className='rowsSelection'>
                    Rows per page:
                    <select
                        value={IssuePageSize.pageSize}
                        onChange={(e) => setIssuePageSize({ ...IssuePageSize, pageSize: Number(e.target.value) })}
                    >
                        {[25, 35, 50].map((size) => (
                            <option key={size} value={size}>
                                {size}
                            </option>
                        ))}
                    </select>

                </div>

                <div className="pagination-info">
                    {`${startItem} - ${endItem} of ${rows.length}`}
                </div>

                <div className="pagination-buttons">
                    <SwitchButton
                        Icon={ChevronLeft}
                        onClick={handlePrevPage}
                        shape="circle"
                        disable={startItem <= 1}
                    />
                    <SwitchButton
                        Icon={ChevronRight}
                        onClick={handleNextPage}
                        shape="circle"
                        disable={endItem >= rows.length}
                    />
                </div>
            </div>

        </div>
    );
};

export default CustomizeTable;