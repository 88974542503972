import React, { useCallback, useEffect, useMemo, useState, useRef} from "react";
import { useAppContext } from "../../../AppContext";
import {DemoKeySpecFormat} from "./DemoKeySpecFormat"
import { debounce } from 'lodash';
import { DefaultComp, TabButton } from "../../../Components";
import { Search } from "../../../Components/icons/General";
import { ChevronLeft, ChevronRight } from "../../../Components/icons/Arrows";
import './DemoKeySpec.css'
import './keySpecificationLayout.css'

// debounce the menu moved distance and position ==> keep the websit pruct speed
const createDebouncedSetScrollPosition = (setScrollPosition) =>
  debounce((position) => setScrollPosition(position), 100);

const DemoKeySpecArea = () => {
  const { specData, clickedIssue, isSearchLoading, isOpenSpecification,
  specMenu, setSpecMenu, speciIssueId, tableViews, isKeySpecLoading,
  standMenu, setStandMenu, specificationArray, additionalArray,

  setIsSpeciDragging, isSpeciDragging, setSpeciStartX, setScrollSpeciLeft, speciStartX, scrollSpeciLeft, setScrollSpeciPosition, scrollSpeciPosition,

  setIsAddtionDragging, setAddtionStartX, setScrollAddtionLeft, addtionScrollPosition, setAddtionScrollPosition, scrollAddtionLeft, addtionStartX, isAddtionDragging
} = useAppContext();
 
  // console.log(specData);

  // 1. the function to change internal specifciations
  const handleSwicthSpec = useCallback((e) => {
    setSpecMenu(e.target.value);
  }, [setSpecMenu]);

  // 2. the subMen select of industry standards
  const handleStandSwicthMenu = useCallback((e) => {
    setStandMenu(e.target.value);
  }, [setStandMenu]);

  // the search animation effect dots numbers
  const dots = Array.from({ length: 16 });


  /****** 1. These below implemented the could mouse dragging the menu button page display of the module right side area (Specifications) *******/
  /* Declare four state used for the Specifications area dragging and rember position */
  const scrollSpeciRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  //  when the mouse clicks buttonPage area, it means start dragging
  const handleMouseDown = (e) => {
    setIsSpeciDragging(true);
    setSpeciStartX(e.pageX - scrollSpeciRef.current.offsetLeft);
    setScrollSpeciLeft(scrollSpeciRef.current.scrollLeft); // corrected property
  };

  // when the user mouse leaves buttonPage area, it will stop dragging to avoid errors
  const handleMouseLeave = () => {
    setIsSpeciDragging(false);
  };

  // when the user stops clicking, it means dragging has ended
  const handleMouseUp = () => {
    setIsSpeciDragging(false);
  };

  // function to calculate the mouse dragging distance
  const handleMouseMove = (e) => {
    if (!isSpeciDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollSpeciRef.current.offsetLeft;
    const walk = (x - speciStartX) * 2; // corrected variable name
    scrollSpeciRef.current.scrollLeft = scrollSpeciLeft - walk; // corrected property
  };

  /* the left/right button could support user move left or right when specification meun can not display enough */
  const debouncedSetScrollPosition = useMemo(
    () => createDebouncedSetScrollPosition(setScrollSpeciPosition),
    [setScrollSpeciPosition]
  );

  // function to check whether the left/right button will display or not ==> have arrived at the final?
  const checkScroll = useCallback(() => {
    if (scrollSpeciRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollSpeciRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth - 1); // save mini value of distance
      debouncedSetScrollPosition(scrollLeft);
    }
  }, [debouncedSetScrollPosition]);

  // save the distance that user have moved it to display, if user change to other page
  useEffect(() => {
    if (scrollSpeciRef.current) {
      scrollSpeciRef.current.scrollLeft = scrollSpeciPosition;
    }
  }, [scrollSpeciPosition]);

  useEffect(() => {
    checkScroll();
    window.addEventListener('resize', checkScroll);

    const currentRef = scrollSpeciRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', checkScroll);
    }

    return () => {
      window.removeEventListener('resize', checkScroll);
      if (currentRef) {
        currentRef.removeEventListener('scroll', checkScroll);
      }
    };
    // eslint-disable-next-line
  }, [checkScroll, scrollSpeciRef.current]);

  // function to remeber the moved distance
  const handleScroll = useCallback((direction) => {
    if (scrollSpeciRef.current) {
      const scrollAmount = 200;
      scrollSpeciRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  }, []);
  /****** 1. These above implemented the could mouse dragging the menu button page display of the module right side area (Specifications) *******/


  /****** 2. These below implemented the could mouse dragging the menu button page display of the module right side area (Addtional Data area) *******/
    /* Declare four state used for the Additional Data area dragging and rember position */
    const scrollAddtionMenuRef = useRef(null);
    const [canaddtionScrollLeft, setaddtionCanScrollLeft] = useState(false);
    const [canaddtionScrollRight, setaddtionCanScrollRight] = useState(false);

    // when the mouse in the addtional data menu area, could start dragging
    const handleAddtionMouseDown = (e) => {
        setIsAddtionDragging(true);
        setAddtionStartX(e.pageX - scrollAddtionMenuRef.current.offsetLeft);
        setScrollAddtionLeft(scrollAddtionMenuRef.current.scrollLeft);
    };

    // When the user mouse leaves the buttonPage area, stop dragging to avoid errors
    const handleAddtionMouseLeave = () => {
        setIsAddtionDragging(false);
    };

    // When the user stops clicking, the drag ends
    const handleAddtionMouseUp = () => {
        setIsAddtionDragging(false);
    };

    // Function to calculate the mouse drag distance
    const handleAddtionMouseMove = (e) => {
        if (!isAddtionDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollAddtionMenuRef.current.offsetLeft;
        const walk = (x - addtionStartX) * 2;
        scrollAddtionMenuRef.current.scrollLeft = scrollAddtionLeft - walk;
    };

    /* The left/right buttons allow the user to move left or right when the Additional Data menu does not display enough content. */
    const debouncedSetAddtionScrollPosition = useMemo(
        () => createDebouncedSetScrollPosition(setAddtionScrollPosition),
        [setAddtionScrollPosition]
    );

    // function to check whether the left/right button will display or not ==> have arrived at the final?
    const checkAddtionScroll = useCallback(() => {
        if (scrollAddtionMenuRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollAddtionMenuRef.current;
            setaddtionCanScrollLeft(scrollLeft > 0);
            setaddtionCanScrollRight(scrollLeft < scrollWidth - clientWidth - 1);
            debouncedSetAddtionScrollPosition(scrollLeft);
        }
    }, [debouncedSetAddtionScrollPosition]);

    // Save the distance the user moves and display, if the user switches to other pages
    useEffect(() => {
        if (scrollAddtionMenuRef.current) {
            scrollAddtionMenuRef.current.scrollLeft = addtionScrollPosition;
        }
    }, [addtionScrollPosition]);

    useEffect(() => {
        checkAddtionScroll();
        window.addEventListener('resize', checkAddtionScroll);

        const currentRef = scrollAddtionMenuRef.current;
        if (currentRef) {
            currentRef.addEventListener('scroll', checkAddtionScroll);
        }

        return () => {
            window.removeEventListener('resize', checkAddtionScroll);
            if (currentRef) {
                currentRef.removeEventListener('scroll', checkAddtionScroll);
            }
        };
        // eslint-disable-next-line
    }, [checkAddtionScroll, scrollAddtionMenuRef.current]);

    // Remember the function of the distance moved and the current position
    const handleAddtionScroll = useCallback((direction) => {
        if (scrollAddtionMenuRef.current) {
            const scrollAmount = 200;
            scrollAddtionMenuRef.current.scrollBy({
                left: direction === 'left' ? -scrollAmount : scrollAmount,
                behavior: 'smooth'
            });
        }
    }, []);
    /****** 2. These above implemented the could mouse dragging the menu button page display of the module right side area (Addtional Data area) *******/

  // the main render part
  return (
    <div
      className="demoAreaBox"
      style={{
        width: isOpenSpecification ? '32%' : '0%',
        opacity: isOpenSpecification ? 1 : 0,
        border: isOpenSpecification ? '1px solid var(--color-gray-200)' : 'none',
        marginLeft: isOpenSpecification ? '18px' : '0px',
        transition: 'width 0.5s ease, opacity 0.5s ease',
      }}
    >
      {(isKeySpecLoading || isSearchLoading) ? <div className="loadingArea">
        <div className="fade-in">
          <div className="dots-loader">
            {dots.map((_, index) => (
              <div
                key={index}
                className="dot"
                style={{
                  transform: `rotate(${index * 22.5}deg) translateY(-20px)`,
                  animationDelay: `${index * 0.125}s`
                }}
              />
            ))}
          </div>
        </div>
      </div> :
        <div className="demoSpecArea">
          {((!clickedIssue || !specData) && tableViews !== "ShareViews") &&
            <DefaultComp
              title="Start by Searching"
              context="Search by Issue number and keywords to populate this section"
              Icon={Search}
            />
          }

          {(tableViews === "ShareViews" && !specData) &&
            <DefaultComp
              title="Start by Click"
              context="Please click the shared issues to populate this section"
              Icon={Search}
            />
          }


          {(clickedIssue || tableViews === "ShareViews") && specData && (
            <div className="keySpecArea">
              <div className="keySpecTitle">
                Internal Specifications

                 <div className="IssueIDTitle">
                 Issue ID: {speciIssueId}

                 </div>
              </div>

              <div className="keySpecMenu">
                <div className="keySpecMenuList"
                  ref={scrollSpeciRef}
                  onMouseDown={handleMouseDown}
                  onMouseLeave={handleMouseLeave}
                  onMouseUp={handleMouseUp}
                  onMouseMove={handleMouseMove}
                >
                  {specificationArray.map((spec) => (
                    <TabButton
                      key={spec}
                      label={spec}
                      value={spec}
                      height="16px"
                      variant="green"
                      isActive={specMenu === spec}
                      onClick={(e) => handleSwicthSpec(e)}
                    />
                  ))}
                </div>
                {scrollSpeciRef.current && scrollSpeciRef.current.scrollWidth > scrollSpeciRef.current.clientWidth && <div className="pageNavigation">
                  <button
                    className="scrollSpeciButton left"
                    onClick={() => handleScroll('left')}
                    disabled={!(scrollSpeciRef.current && scrollSpeciRef.current.scrollWidth > scrollSpeciRef.current.clientWidth && canScrollLeft)}
                  >
                    <ChevronLeft size={16} color="#667085" />
                  </button>

                  <button
                    className="scrollSpeciButton right"
                    onClick={() => handleScroll('right')}
                    disabled={!(scrollSpeciRef.current && scrollSpeciRef.current.scrollWidth > scrollSpeciRef.current.clientWidth && canScrollRight)}
                  >
                    <ChevronRight size={16} color="#667085" />
                  </button>
                </div>
                }
              </div>

              {/* add the loading animation of key specifcation data loading */}
              <DemoKeySpecFormat
                issueID={speciIssueId}
                menuTitle="Specifications"
                subMenuTitle={specMenu}
              />
            </div>
          )}

          {(clickedIssue || tableViews === "ShareViews") && specData && (
            <div className="standArea">
              <div className="borderLine"></div>

              <div className="standTitle">
                Industry Standards
              </div>

              <div className="standMenuArea">
                <div className="standMenuList"
                   ref={scrollAddtionMenuRef}
                   onMouseDown={handleAddtionMouseDown}
                   onMouseLeave={handleAddtionMouseLeave}
                   onMouseUp={handleAddtionMouseUp}
                   onMouseMove={handleAddtionMouseMove}
                >
                  {additionalArray.map((menu) => (
                    <TabButton
                      key={menu}
                      label={menu}
                      value={menu}
                      variant="green"
                      height="16px"
                      isActive={standMenu === menu}
                      onClick={(e) => handleStandSwicthMenu(e)}
                    />
                  ))}
                </div>
                {scrollAddtionMenuRef.current && scrollAddtionMenuRef.current.scrollWidth > scrollAddtionMenuRef.current.clientWidth && <div className="pageNavigation">
                  <button
                    className="scrollSpeciButton left"
                    onClick={() => handleAddtionScroll('left')}
                    disabled={!(scrollAddtionMenuRef.current && scrollAddtionMenuRef.current.scrollWidth > scrollAddtionMenuRef.current.clientWidth && canaddtionScrollLeft)}
                  >
                    <ChevronLeft size={16} color="#667085" />
                  </button>

                  <button
                    className="scrollSpeciButton right"
                    onClick={() => handleAddtionScroll('right')}
                    disabled={!(scrollAddtionMenuRef.current && scrollAddtionMenuRef.current.scrollWidth > scrollAddtionMenuRef.current.clientWidth && canaddtionScrollRight)}
                  >
                    <ChevronRight size={16} color="#667085" />
                  </button>
                </div>}
              </div>

              <DemoKeySpecFormat
                issueID={speciIssueId}
                menuTitle="Standards"
                subMenuTitle={standMenu}

              />
            </div>
          )}
        </div>}


    </div>
  );
};

export default DemoKeySpecArea;