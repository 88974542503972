import React, { useMemo, useCallback, useEffect, useState, useRef } from "react";
import { ContextualMenu } from "@fluentui/react";
import { useAppContext } from "../../../AppContext";
import { Checkbox, Textarea} from "../../../Components";
import { generateDynamicStyles } from "../dynamicStyles";
import { Filters, Close } from "../../../Components/icons/General";
import { Badge } from "../../../Components/index";
import toast from 'react-hot-toast';
import { useLocation } from "react-router-dom";
import { 
  useHideIssuesMutation,
  useUnhideIssuesMutation,
  useSaveIssuesMutation,
  useDeleteSavedIssuesMutation,
  useOrganizationUsersQuery,
  useShareIssuesMutation,
  useUpdateIssueMutation,
  useToggleFavoriteMutation,
  useSearchKeyMutation,
} from '../../../api/queries';
import CustomizeTable from "./CustomizeTable/CustomizeTable";
import './CustomizeTable/CustomizeTable.css';
import 'tippy.js/dist/tippy.css';
import { parseWithHighlight } from '../../../Components/tooltip/transformHighlight';
import { useTooltip } from '../../../Components/tooltip';
import IssueBlock from "../Issue Block/IssueBlock";

// the issue area format: click column header to sorting, clicked to open the similar issue details area, and
const IssueDataGrid = ({ tableViews }) => {
  // get the data from the AppContext API
  const {
    clickedIssue,
    inputIssue,
    setClickedIssue,
    clickedSimilarIssue,
    setClickedSimilarIssue,
    IssuePageSize,
    setIssuePageSize,
    discoverColumns,
    setSpecData,
    selectedAreas,
    setSelectedAreas,
    highlightArray,
    sortColumn,
    setSortColumn,
    issueDisplayArray,
    setDiscoverColumns,
    areaArray,
    selectStatus,
    allStatus,
    setSelectStatus,
    setIssueDisplayArray,
    checkedRows,
    setCheckedRows,
    setSpeciIssueId,
    currentRowIndex, 
    setCurrentRowIndex,
    setSelectRequestor,
    allRequestor,
    selectRequestor,
    setIssueRelativeArray,
    sharedIssueArray,
    isSharedView, 
    rowsHeight,
    setRowsHeight,
    rowsHeightMap, 
    setRowsHeightMap,
    isOpenSpecification,
    speciIssueId,
    setIsKeySpecLoading,

    userHiddenArray,
    userShareArray,
    userSaveArray, 
    blockShareStates, setShareBlockStates,
    blockSaveStates, setSaveBlockStates,
    blockHiddenStates, setHiddenBlockStates,
  } = useAppContext();

  const [contextualMenuProps, setContextualMenuProps] = useState(null); // the each column filter dropdown menu
  const [showEmailSelect, setShowEmailSelect] = useState(false); // the people's email of share feature dropdown menu
  const [selectedIssueIds, setSelectedIssueIds] = useState([]); // the issue ids that user selected for save hide share and star

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // the 4 difference kinds tags: query(issue id or issue key), status, system, and requestor
  const queryParam = searchParams.get("query") || ""; // the issue id and issue key only could be single
  const systemParams = searchParams.getAll("system") || []; // system
  const statusParams = searchParams.getAll("status") || []; // status
  const requestorParams = searchParams.getAll("requestor") || []; // requestor
  const keywordsParams = searchParams.getAll("keywords") || []; // the user input tags


  // Define handleAreaChange first
  const handleAreaChange = useCallback((area) => {
    setSelectedAreas((prev) => {
      const newSelectedAreas = prev.includes(area) 
        ? prev.filter((a) => a !== area) 
        : [...prev, area];
      return newSelectedAreas;
    });
  }, [setSelectedAreas]);

  const updateIssueMutation = useUpdateIssueMutation();

  // used for the tittle, description and failure mode column open Tab window, but not open pop window at same time
  const checkOpenTab = (e) => {
    if(e.target.target === "_blank"){
      e.stopPropagation();
    }
  }

  // the state used for edit the system cells
  const [editingRowId, setEditingRowId] = useState(null); // current row Id
  const [editedValue, setEditedValue] = useState(""); // the current system user eidted value
  const [isActiverHover, setIsActiveHover] = useState(false); // judge whether shows the edit button or not

  // the edit function
  const handleEditClick = (rowId, currentValue) => {
    setEditingRowId(rowId); // get the current row issue ID
    setEditedValue(currentValue); // the current value or edited value
  };

  // the function call the backend API to save user input in the edit feild
  const handleSaveSystem = (rowId) => {
    // the update the data logic
    updateIssueMutation.mutateAsync({
      issueId: rowId,
      field: "System",
      value: editedValue || "",
    })
      .then(() => {
        toast.success("Update request received successfully!");
        setEditingRowId(null); // save and back to default status
      })
      .catch(() => {
        toast.error("Update request failed. Please try again.");
        setEditingRowId(null); // save and back to default status
      });
  };

  const handleCancel = () => {
    setEditingRowId(null); // cancel the edit
  };

  //********************************* Keep this merged version inside the component
  const modifyColumns = useCallback((columns) => {
    return columns.map((column) => {
      if (column.field === "Title" || column.field === "Description" || column.field === "Failure Mode" || column.field === "Overview") {
        const keyStr = column.field;
        return {
          ...column,
          renderCell: (row) => (
            <div 
              onClick={(e) => checkOpenTab(e)}
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxHeight: "100%",
              }}
            >
              {parseWithHighlight(row[keyStr])}
            </div>
          ),
        };
      } else if (column.field === "Cases") {
        return {
          ...column,
          align: "left",
          headerAlign: "left",
          renderCell: (row) => (
            <span
              style={{
                fontWeight: "var(--font-weight-semibold)",
                marginLeft: "5px",
              }}
            >
             {row[column.field]}
            </span>
          ),
        };
      } else if (column.field === "Issue ID") {
        return {
          ...column,
          renderCell: (row) => {
            // const duplicates = row.duplicates || "";
            // const is_parent = row.is_parent || false;
            
            // const duplicateBage = [];
            
            // if (duplicates !== "" && is_parent === true) {
            //   duplicateBage.push(
            //     <Badge key="duplicates-summary-badge" type="default">
            //       Duplicate parent
            //     </Badge>
            //   );
            // } else if (duplicates !== "" && is_parent === false) {
            //   duplicateBage.push(
            //     <Badge key="duplicates-badge" type="default">
            //       Duplicate
            //     </Badge>
            //   );
            // }

            const timeData = row["Opening date"];
            const status = row["Status"];
            let variant = "Other";
            
            if (status === "Closed") variant = "Closed";
            else if (status === "Open" || status === "Current") variant = "Open";

            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "8px",
                }}
              >
                <span><strong>Created: </strong> {timeData}</span>
                <Badge type="status" variant={variant}>
                  {status === "Current" ? "Open" : status ? status : "Other"}
                </Badge>

                <span><strong>ID:</strong> {row[column.field]}</span>
              </div>
            );
          },
        };
      } else if (column.field === "System") {
        return {
          ...column,
          renderCell: (row) => {
            const rowId = row["Issue ID"];
            const areaList = Array.isArray(row.AreaList)
              ? row.AreaList
              : (row[column.field]?.split(/\s*,\s*/).map((item) => item.trim()) || []);

            if (editingRowId === rowId) {
              // the edit UI: the text area 
              return (
                <div style={{ width: "100%", position: "relative"}}>
                  <Textarea
                    value={editedValue}
                    width={"87%"}
                    height={'70px'}
                    onChange={setEditedValue}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault(); // Prevent line breaks on Enter
                        handleSaveSystem(rowId); // Call the save handler
                      } else if (e.key === "Escape") {
                        handleCancel(); // Call the cancel handler
                      } else if (e.key === " ") {
                        e.stopPropagation(); // STOP The scoll action  
                      }
                    }}
                  />
                  {/* the closed icon outside the edit status */}
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCancel();
                    }}
                    style={{
                      position: "absolute",
                      display: "flex",
                      top: "12px",
                      right: "10px",
                      padding: "2px 2px",
                      background: "#F0F1F1",
                      border: "none",
                      cursor: "pointer",
                      borderRadius: "50px"
                    }}
                  >
                    <Close color="#667085" size={14}/>
                  </button>
                </div>
              );
            }

            return (
              <div
                className="system-cell"
                onMouseEnter={() => setIsActiveHover(rowId)}
                onMouseLeave={() => setIsActiveHover(null)}
              >
                {areaList.map((area, index) => (
                  <Badge
                    key={`${rowId}-${area}-${index}`}
                    type="default"
                    variant={selectedAreas.includes(area) ? "selected" : "areaDefault"}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAreaChange(area);
                    }}
                  >
                    {area}
                  </Badge>
                ))}
                {isActiverHover === rowId && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditClick(rowId, areaList.join(", "))    
                    }}
                    className="system-edit-button"
                  >
                    Edit
                  </button>
                )}
              </div>
            );
          },
        };
      }
      return column;
    });
     // eslint-disable-next-line
  }, [selectedAreas, editedValue, editingRowId, handleAreaChange, handleSaveSystem, handleEditClick]);
  const [isFieldHover, setIsFieldHover] = useState([]);

  // function for user could increase the width of every column
  // ==> fixed the error about issue-summary (flex default): when user Resizing the column issue-summary, removed the flex: 1 attribute
  const handleColumnResize = useCallback(
    (params) => {
      const newWidth = params.width;
      setDiscoverColumns((prevColumns) =>
        prevColumns.map((col) =>
          col.field === params.colDef.field
            ? {
                ...col,
                width: newWidth,
                flex: col.field === "Issue Summary" ? 0 : col.flex,
              }
            : col,
        ),
      );
    },
    [setDiscoverColumns],
  );
  
  // It will reset the "Issue Summary" column to flex mode when the window is resized.
  useEffect(() => {
    const handleResize = () => {
      setDiscoverColumns((prevColumns) =>
        prevColumns.map((col) =>
          col.field === "Issue Summary"
            ? { ...col, flex: 1, width: undefined }
            : col,
        ),
      );
    };
  
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [setDiscoverColumns]);

  // the sort function based on user selected
  const sortedIssues = useMemo(() => {
    if (!isSharedView && (!issueDisplayArray || issueDisplayArray.length === 0)) 
      return [];
    if (isSharedView && (!sharedIssueArray || sharedIssueArray.length === 0)) 
      return [];
  
    // based on the general page or share view page ==> change it
    let sourceArray = isSharedView ? sharedIssueArray : issueDisplayArray;
  
    if (sortColumn.length === 0) 
      return sourceArray;
  
    // Pre-process the dates before sorting
    const processedSourceArray = sourceArray.map(item => {
      if (item.Date) {
        // Parse the date once and store it in a new property
        const parseDate = (dateString) => {
          const [month, year] = dateString.split('/');
          const monthIndex = new Date(`${month}/1`).getMonth();
          return new Date(year, monthIndex);
        };
        item.parsedDate = parseDate(item.Date);
      }
      return item;
    });
  
    return [...processedSourceArray].sort((a, b) => {
      for (const { field, sort } of sortColumn) {
        const aValue = a[field], bValue = b[field];
  
        if (field === "Opening date") {
          const aDate = a.parsedDate;
          const bDate = b.parsedDate;
          if (aDate < bDate) return sort === "asc" ? -1 : 1;
          if (aDate > bDate) return sort === "asc" ? 1 : -1;
        } else {
          if (aValue < bValue) return sort === "asc" ? -1 : 1;
          if (aValue > bValue) return sort === "asc" ? 1 : -1;
        }
      }
      return 0;
    });
  }, [isSharedView, sharedIssueArray, issueDisplayArray, sortColumn]);
  
  

  // When calling ModifyColumns, pass handleBadgeRightClick
  const displayColumns = modifyColumns(discoverColumns).filter((column) => column.hide !== true).sort((a, b) => a.index - b.index);

  // send the page size into local storage, after user go to other page, it still could save user selected it
  useEffect(() => {
    const savedPageSize = localStorage.getItem("pageSize");
    if (savedPageSize) {
      setIssuePageSize(Number(savedPageSize));
    }
  }, [setIssuePageSize]);

  // the function used selected to add into star issue array list
  const toggleFavoriteMutation = useToggleFavoriteMutation();

  // the column star of issue data table clcik function with backend API ==> now was hiden it <==
  const handleStarIssueChange = useCallback(
    async (issueId) => {
      let currentIssue =
        clickedIssue && clickedIssue["Issue ID"] === issueId
          ? clickedIssue
          : issueDisplayArray.find((issue) => issue["Issue ID"] === issueId);

      if (!currentIssue) {
        console.error("Issue not found");
        return;
      }
      
      const newFavoriteStatus = !currentIssue.is_favorite;

      // Update the UI immediately
      if (clickedIssue && clickedIssue["Issue ID"] === issueId) {
        setClickedIssue((prevIssue) => ({
          ...prevIssue,
          is_favorite: newFavoriteStatus,
        }));
      }
      setIssueDisplayArray((prevArray) =>
        prevArray.map((issue) =>
          issue["Issue ID"] === issueId
            ? { ...issue, is_favorite: newFavoriteStatus }
            : issue,
        ),
      );

      try {
        await toggleFavoriteMutation.mutateAsync({
          issueId,
          sourceId: inputIssue,
          isFavorite: !newFavoriteStatus,
        });
      } catch (error) {
        console.error("Error toggling favorite status:", error);
        // Revert the change if the API call fails
        if (clickedIssue && clickedIssue["Issue ID"] === issueId) {
          setClickedIssue((prevIssue) => ({
            ...prevIssue,
            is_favorite: !newFavoriteStatus,
          }));
        }
        setIssueDisplayArray((prevArray) =>
          prevArray.map((issue) =>
            issue["Issue ID"] === issueId
              ? { ...issue, is_favorite: !newFavoriteStatus }
              : issue,
          ),
        );
      }
    },
    [
      clickedIssue,
      inputIssue,
      issueDisplayArray,
      setClickedIssue,
      setIssueDisplayArray,
      toggleFavoriteMutation,
    ],
  );

  // remove the animation and propagation when user click checkbox or star, not open issue details pop window
  const handleCellClick = (params, rowIndex) => {
    handleRowClick(params, rowIndex);
  };

  const { mutateAsync } = useSearchKeyMutation();

  // click function: when  user clcik the row of issue data table, open the relative issue details pop window
  const handleRowClick = useCallback(
    async (params, rowIndex) => {
      if (
        clickedSimilarIssue &&
        clickedSimilarIssue["Issue ID"] === params.row["Issue ID"]
      ) {
        setClickedSimilarIssue(null);
        setCurrentRowIndex(0);
      } else {
        const issueId = String(params.row["Issue ID"]); // change the issue ID to be string and send to backend
        setClickedSimilarIssue(params.row);
        setCurrentRowIndex(rowIndex);

        if (issueId !== speciIssueId) {
          setSpeciIssueId(issueId);
          setIsKeySpecLoading(true);

          try {
            const data = await mutateAsync({ query: issueId }); // call the API backend function
            // console.log("Search specs result:", data);
            setSpecData(data);
          } catch (error) {
            console.error("Failed to fetch key specs:", error);
          } finally {
            setIsKeySpecLoading(false); // finally end the loadding anmation
          }
        }
      }
    },  
    [clickedSimilarIssue, mutateAsync, setSpecData]  
  );
  
  

  
  //save all the user clicked area item of dropdown list
  const handleStatusChange = useCallback(
    (e, status) => {
      e.preventDefault();

      setSelectStatus((prev) =>
        prev.includes(status)
          ? prev.filter((a) => a !== status)
          : [...prev, status],
      );
    },
    [setSelectStatus],
  );

  // Save all the user selected all the Requestor of dropdown menu
  const handleRequestorChange = useCallback(
    (requestor) => {
      setSelectRequestor((prev) =>
        prev.includes(requestor) ? prev.filter((p) => p !== requestor) : [...prev, requestor],
      );
    }, [setSelectRequestor]
  );


  // the sort function: user could select and unselect item, back to the relative issue array
  const handleSortFunc = useCallback(
    (field, sortDirection) => {
      // Check if the current sort is already selected
      const isSelected = sortColumn.some(
        (sortItem) => sortItem.field === field && sortItem.sort === sortDirection
      );
  
      if (isSelected) {
        // Clear sorting if already selected
        setSortColumn([]);
      } else {
        // Set the new sorting option
        setSortColumn([{ field, sort: sortDirection }]);
      }
    },
    [sortColumn, setSortColumn]
  );

  const getMenuItems = (field) => {
    let sortMenuItem = null;
    let sortDateItem = null;
    let filterMenuItem = null;

    if(field === "Issue ID"){
      // 1. the issue id sorting feature
      sortMenuItem = {
        key: "Issue ID",
        text: "Issue ID",
        subMenuProps: {
          items: [
            {
              key: "issueIDSortAsc",
              text: "Sort Ascending",
              style: {
                paddingLeft: "4px", marginTop: "6px", marginBottom: "4px" 
              },
              onRender: () => (
                <div style={{ paddingLeft: "4px", marginTop: "3px", marginBottom: "3px" }}>
                  <Checkbox
                    id="Sort Ascending"
                    label="Sort Ascending"
                    size="sm"
                    type="radio"
                    checked={sortColumn.some(
                      (sortItem) =>
                        sortItem.field === "Issue ID" && sortItem.sort === "asc",
                    )}
                    onChange={() => {
                      handleSortFunc("Issue ID", "asc");
                      setContextualMenuProps(null);
                    }}
                  />
                </div>
              ),
            },
            {
              key: "issueIDSortDesc",
              text: "Sort Descending",
              onRender: () => (
                <div style={{ paddingLeft: "4px", marginTop: "3px", marginBottom: "3px" }}>
                  <Checkbox
                    id="Sort Descending"
                    label="Sort Descending"
                    type="radio"
                    checked={sortColumn.some(
                      (sortItem) =>
                        sortItem.field === "Issue ID" && sortItem.sort === "desc",
                    )}
                    onChange={() => {
                      handleSortFunc("Issue ID", "desc");
                      setContextualMenuProps(null);
                    }}
                  />
                </div>
              ),
            },
          ],
        },
      }
      // 2. the date sorting feature
      sortDateItem = {
        key: "Opening date",
        text: "Opening date",
        subMenuProps: {
          items: [
            {
              key: "dateSortAsc",
              text: "Sort Ascending",
              style: {
                paddingLeft: "4px", marginTop: "6px", marginBottom: "4px"
              },
              onRender: () => (
                <div style={{ paddingLeft: "4px", marginTop: "3px", marginBottom: "3px" }}>
                  <Checkbox
                    id="Sort Ascending"
                    label="Sort Ascending"
                    size="sm"
                    type="radio"
                    checked={sortColumn.some(
                      (sortItem) =>
                        sortItem.field === "Opening date" && sortItem.sort === "asc",
                    )}
                    onChange={() => {
                      handleSortFunc("Opening date", "asc");
                      setContextualMenuProps(null);
                    }}
                  />
                </div>
              ),
            },
            {
              key: "dateSortDesc",
              text: "Sort Descending",
              onRender: () => (
                <div style={{ paddingLeft: "4px", marginTop: "3px", marginBottom: "3px" }}>
                  <Checkbox
                    id="Sort Descending"
                    label="Sort Descending"
                    type="radio"
                    checked={sortColumn.some(
                      (sortItem) =>
                        sortItem.field === "Opening date" && sortItem.sort === "desc",
                    )}
                    onChange={() => {
                      handleSortFunc("Opening date", "desc");
                      setContextualMenuProps(null);
                    }}
                  />
                </div>
              ),
            },
          ],
        },
      }
      // 3. the status filter feature
      filterMenuItem = {
        key: "Status",
        text: "Status",
        subMenuProps: {
          styles: {
            root: {
              maxHeight: "300px",
              overflowY: "auto",
            },
          },
          items: [
            {
              key: "topSpace",
              text: "",
              onRender: () => <div style={{ paddingTop: "4px" }}></div>,
              disabled: true,
            },
            ...allStatus.map((status) => ({
              key: status,
              text: status,
              onRender: () => (
                <div style={{ paddingLeft: "4px", marginTop: "3px", marginBottom: "6px"}}>
                  <Checkbox
                    id={status}
                    size="sm"
                    checked={selectStatus.includes(status)}
                    onChange={(e) => {
                      handleStatusChange(e, status);
                      setContextualMenuProps(null);
                    }}
                    renderLabel={() => {
                      let variant = "Other";
                      if (status === "Closed") variant = "Closed";
                      else if (status === "Open" || status === "Current") variant = "Open";
              
                      return (
                        <Badge type="status" variant={variant}>
                          {status === "Current" ? "Open" : status}
                        </Badge>
                      );
                    }}
                  />
                </div>
              ),
            })),
          ],
        },
      };
    } else if (field !== "Issue Summary" && field !== "Failure Mode" && field !== "Resolution" && field !== "Status" && field !== "Description") {
      sortMenuItem = {
        key: "sort",
        text: "Sort",
        subMenuProps: {
          items: [
            {
              key: "sortAsc",
              text: "Sort Ascending",
              style: {
                paddingLeft: "4px", marginTop: "6px", marginBottom: "4px" 
              },
              onRender: () => (
                <div style={{ paddingLeft: "4px", marginTop: "3px", marginBottom: "3px" }}>
                  <Checkbox
                    id="Sort Ascending"
                    label="Sort Ascending"
                    size="sm"
                    type="radio"
                    checked={sortColumn.some(
                      (sortItem) =>
                        sortItem.field === field && sortItem.sort === "asc",
                    )}
                    onChange={() => {
                      handleSortFunc(field, "asc");
                      setContextualMenuProps(null);
                    }}
                  />
                </div>
              ),
            },
            {
              key: "sortDesc",
              text: "Sort Descending",
              onRender: () => (
                <div style={{ paddingLeft: "4px", marginTop: "3px", marginBottom: "3px" }}>
                  <Checkbox
                    id="Sort Descending"
                    label="Sort Descending"
                    type="radio"
                    checked={sortColumn.some(
                      (sortItem) =>
                        sortItem.field === field && sortItem.sort === "desc",
                    )}
                    onChange={() => {
                      handleSortFunc(field, "desc");
                      setContextualMenuProps(null);
                    }}
                  />
                </div>
              ),
            },
          ],
        },
      }
    }

    // Conditional filter drop menu item based on field
    if (field === "System") {
      filterMenuItem = {
        key: "filter",
        text: "Filter",
        subMenuProps: {
          styles: {
            root: {
              maxHeight: "300px",
              overflowY: "auto",
            },
          },
          items: [
            {
              key: "topSpace",
              text: "",
              onRender: () => <div style={{ paddingTop: "4px" }}></div>,
              disabled: true,
            },
            ...areaArray.map((area) => ({
              key: area,
              text: area,
              onRender: () => (
                <div style={{ paddingLeft: "4px", marginTop: "3px", marginBottom: "3px" }}>
                  <Checkbox
                    id={area}
                    label={area}
                    size="sm"
                    checked={selectedAreas.includes(area)}
                    onChange={() => {
                      handleAreaChange(area);
                      setContextualMenuProps(null);
                    }}
                  />
                </div>
              ),
            })),
          ],
        },
      };
    } else if(field === "Requestor"){
      filterMenuItem = {
        key: "filter",
        text: "Filter",
        subMenuProps: {
          styles: {
            root: {
              maxHeight: "300px",
              overflowY: "auto",
            },
          },
          items: [
            {
              key: "topSpace",
              text: "",
              onRender: () => <div style={{ paddingTop: "4px" }}></div>,
              disabled: true,
            },
            ...allRequestor.map((requestor) => ({
              key: requestor,
              text: requestor,
              onRender: () => (
                <div style={{ paddingLeft: "4px", marginTop: "3px", marginBottom: "3px" }}>
                  <Checkbox
                    id={requestor}
                    label={requestor}
                    size="sm"
                    checked={selectRequestor.includes(requestor)}
                    onChange={() => {
                      handleRequestorChange(requestor);
                      setContextualMenuProps(null);
                    }}
                  />
                </div>
              ),
            })),
          ],
        },
      }
    }

    // Create final menu items array by inserting filter item after sort if it exists
    let menuItems = [];
    if(filterMenuItem){
      menuItems.unshift(filterMenuItem);
    }

    if(sortDateItem){
      menuItems.unshift(sortDateItem);
    }

    if(sortMenuItem){
      menuItems.unshift(sortMenuItem)
    }

    return menuItems;
  };

  const handleHeaderClick = (event, field) => {    
    // Conditional drop menu item based on field
    const menuItems = getMenuItems(field);

    setContextualMenuProps({
      items: menuItems,
      target: event.currentTarget,
      onDismiss: () => setContextualMenuProps(null),
      directionalHint: 6,
      directionalHintFixed: true,
      gapSpace: 1,
      alignTargetEdge: true,
    });
  };

  // the rows will display in the issue table: incluidng current issue (first row) and the relative issues
  const rows = (tableViews === "Saved" || tableViews === "Hidden" || tableViews === "Shared" || tableViews === "Starred")
  ? sortedIssues
  : [
      ...(clickedIssue ? [clickedIssue] : []),
      ...sortedIssues.filter(
          (item) => item["Issue ID"] !== clickedIssue?.["Issue ID"],
      ),
  ];

  //the header area checkbox checkbox ==> select all or cancel all
  const handleHeaderCheckboxChange = () => {
    setCheckedRows((prev) => {
      // if all selected, cancel all the rows
      if (prev.size === rows.length) {
        return new Set();
      }
      // if not select all the rows
      return new Set(rows.map((row) => row["Issue ID"]));
    });
  };

  // Handle checkbox change of issue table boday: get all user selected rows checkbox
  const handleCheckboxChange = (issueId) => {
    setCheckedRows((prev) => {
      const newChecked = new Set(prev);
      if (newChecked.has(issueId)) {
        newChecked.delete(issueId);
      } else {
        newChecked.add(issueId);
      }
      return newChecked;
    });
  };

  // function: check mouse enter header area or not ==> shows the filter icon (issue table dropdown menu)
  const handleMouseEnter = (headerName) => {
    setIsFieldHover((prev) => [...prev, headerName]);
  };
  
  // function: check mouse leave the header area or not ==> hide the filter icon (drop down menu)
  const handleMouseLeave = (headerName) => {
    setIsFieldHover((prev) => prev.filter((name) => name !== headerName));
  };

  // Change evey column header area ==> add the menu icon inside
  const displayColumnswithCustomHeader = displayColumns.map((col) => ({
    ...col,
    sortable: false, // Disable sorting
    renderHeader: () => {
      const isHovered = isFieldHover.includes(col.headerName); // Use col directly

      return (
        <div
          style={{
            display: "flex",
            width: '100%', // Ensure full width of the header
            alignItems: "center",
            justifyContent: "space-between"
          }}
          onMouseEnter={() => handleMouseEnter(col.headerName)} // Directly use col
          onMouseLeave={() => handleMouseLeave(col.headerName)} // Directly use col
        >
          <span>{col.headerName}</span> {/* Directly use col.headerName */}
          {getMenuItems(col.field).length > 0 && (
            <Filters
              size={16}
              color={(isHovered) ? "var(--color-dark-gray-900)" : "var(--color-dark-gray-50)"}
            onClick={(e) => handleHeaderClick(e, col.field)} // Directly use col.field
            style={{
              cursor: 'pointer',
              }}
            />
          )}
        </div>
      );
    },
  }));


  /************************  the View button: share save star hide feature with connect API backend function     *************************/ 
  // 1. the Save Button connection API function
  const { mutateAsync: save } = useSaveIssuesMutation();

  const handleSave = async () => {
    const selectedIssueIds = Array.from(checkedRows);
    const sourceId = clickedIssue?.["Issue ID"] || null;
    
    try {
      await save({ issueIds: selectedIssueIds, sourceId });
      setCheckedRows(new Set());
    } catch (error) {
      console.error('Error saving issues:', error);
    }
  };

  // 2. the Share Button connection API function
  const shareIssuesMutation = useShareIssuesMutation();

  // Function to handle "Share" button click 
  const handleShare = async (selectedEmails) => {
    if (selectedEmails.length === 0) {
      setSelectedIssueIds(Array.from(checkedRows));
      setShowEmailSelect(true);
      return;
    }

    const issueIds = Array.from(checkedRows);

    try {
      await shareIssuesMutation.mutateAsync({
        emails: selectedEmails,
        issueIds: issueIds,
        query: {
          query: queryParam, 
          statusArr: statusParams, 
          systemArr: systemParams,
          requestorArr: requestorParams,
          keywordsArr: keywordsParams,
        },
      });

      toast.success('Issues shared successfully');
      setShowEmailSelect(false);
      setSelectedIssueIds([]);
      setCheckedRows(new Set());
    } catch (error) {
      console.error("Error sharing issues:", error);
      toast.error(error.message || 'Failed to share issues');
    }
  };
  
  // 3.1 Function to handle "Hide" button click
  const { mutateAsync: hide } = useHideIssuesMutation();

  const handleHide = async () => {
    const selectedIssueIds = Array.from(checkedRows);
    const sourceId = clickedIssue?.["Issue ID"] || null;
    
    try {
      await hide({ issueIds: selectedIssueIds, sourceId });
      
      // Clear the checked rows
      setCheckedRows(new Set());
      
      // Remove hidden issues from both display array and relative array
      const filterOutHidden = array => 
        array.filter(issue => !selectedIssueIds.includes(issue["Issue ID"]));
      
      setIssueDisplayArray(filterOutHidden);
      setIssueRelativeArray(filterOutHidden);

      // If the clicked issue was hidden, clear it
      if (clickedIssue && selectedIssueIds.includes(clickedIssue["Issue ID"])) {
        setClickedIssue(null);
      }

      // If the clicked similar issue was hidden, clear it
      if (clickedSimilarIssue && selectedIssueIds.includes(clickedSimilarIssue["Issue ID"])) {
        setClickedSimilarIssue(null);
      }
      
    } catch (error) {
      console.error('Error hiding issue(s):', error);
      toast.error('Failed to hide issue(s)');
    }
  };

  // 3.2: the unhide button feature
  const { mutateAsync: unhide } = useUnhideIssuesMutation();

  const handleUnhide = async () => {
    const selectedIssueIds = Array.from(checkedRows);
    const sourceId = clickedIssue?.["Issue ID"];
    
    try {
      await unhide({ 
        issueIds: selectedIssueIds,
        sourceId: sourceId
      });
      setCheckedRows(new Set());
    } catch (error) {
      console.error('Error unhiding issues:', error);
    }
  };

  // 4. get all the team member could share from our data base
  const { data: teamMembers = [], isLoading: isTeamMemberLoading } = useOrganizationUsersQuery();
  
  /************************  the View button: share save star hide feature with connect API backend function     *************************/
  
  // Set clickedSimilarIssue to null to close the opened issue
  const handleCloseIssue = useCallback(() => {
    setClickedSimilarIssue(null);
  }, [setClickedSimilarIssue]);

   // the keyboard function used for issue data table
  const handleKeyDown = useCallback((event) => {
    const { key, target } = event;

    const isInputField =
      target.tagName === "INPUT" ||
      target.tagName === "TEXTAREA" ||
      target.isContentEditable;

    if (isInputField) {
      return; // jump the below functio ==> user was inputing the text in search field
    }
  
    switch (key) {
      case 'ArrowDown':
        event.preventDefault();
        setCurrentRowIndex((prev) => 
          Math.min(prev + 1, rows.length - 1)
        );  // Increment index on ArrowDown
        break;
        
      case 'ArrowUp':
        event.preventDefault();
        setCurrentRowIndex((prev) => 
          Math.max(prev - 1, 0)
        );  // Decrement index on ArrowUp
        break;

      case 'PageUp':
        event.preventDefault();
        setCurrentRowIndex((prev) => Math.max(prev - 5, 0));
        break;
      
      case 'PageDown':
        event.preventDefault();
        setCurrentRowIndex((prev) => Math.min(prev + 5, rows.length - 1));
        break;

      case 'Home':
        event.preventDefault();
        setCurrentRowIndex(0);
        break;
      
      case 'End':
        event.preventDefault();
        setCurrentRowIndex(rows.length - 1);
        break;
        
      case ' ': // space
        event.preventDefault();
        const currentRow = rows[currentRowIndex];
        if (currentRow) {
          handleCheckboxChange(currentRow["Issue ID"]);
        }
        break;
        
      case 'Enter': // if use Enter, it will conflict with search field input issue id to search
        event.preventDefault();
        const selectedRow = rows[currentRowIndex];
        if (selectedRow) {
          handleRowClick({ row: selectedRow }, currentRowIndex);
        }
        break;
        
      case 's':
      case 'S':
        if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
          const currentRow = rows[currentRowIndex];
          if (currentRow) {
            handleStarIssueChange(currentRow["Issue ID"]);
          }
        }
        break;
  
      case 'a':
      case 'A':
        if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
          // Check if all checkboxes are selected
          const allIssueIds = rows.map(row => row["Issue ID"]);
          const allSelected = allIssueIds.every(issueId => checkedRows.has(issueId));
          
          if (allSelected) {
            // If all are selected, deselect them
            setCheckedRows(new Set());
          } else {
            // Otherwise, select all
            setCheckedRows(new Set(allIssueIds));
          }
        }
        break;

      // Explicitly clear all selections with Ctrl + D
      case 'd':
        case 'D':
          if (event.ctrlKey || event.metaKey) {
            event.preventDefault();
            setCheckedRows(new Set());
          }
          break;
  
      case 'Escape':
        event.preventDefault();
        handleCloseIssue(); 
        break;
      default:
        break;
    }
     // eslint-disable-next-line
  }, [currentRowIndex, rows, handleCheckboxChange, handleRowClick, handleStarIssueChange, handleCloseIssue]);
 
  // the keyboard listner event
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const { mutateAsync: deleteSaved } = useDeleteSavedIssuesMutation();

  const handleDelete = async () => {
    const selectedIssueIds = Array.from(checkedRows);
    const sourceId = clickedIssue?.["Issue ID"];
    
    try {
      await deleteSaved({ 
        issueIds: selectedIssueIds, 
        sourceId: sourceId 
      });
      setCheckedRows(new Set());
    } catch (error) {
      console.error('Error deleting saved issues:', error);
    }
  };

  const handleFavorite = async () => {
    const selectedIssueIds = Array.from(checkedRows);
    const sourceId = clickedIssue?.["Issue ID"];
    
    try {
      // Process each issue sequentially
      await Promise.all(selectedIssueIds.map(async (issueId) => {
        await toggleFavoriteMutation.mutateAsync({
          issueId,
          sourceId,
          isFavorite: false // false because we want to favorite it (API expects opposite)
        });
      }));

      // Update UI state for all affected issues
      setIssueDisplayArray((prevArray) =>
        prevArray.map((issue) =>
          selectedIssueIds.includes(issue["Issue ID"])
            ? { ...issue, is_favorite: true }
            : issue
        )
      );

      // Update clicked issue if it's in the selection
      if (clickedIssue && selectedIssueIds.includes(clickedIssue["Issue ID"])) {
        setClickedIssue((prevIssue) => ({
          ...prevIssue,
          is_favorite: true
        }));
      }

      setCheckedRows(new Set()); // Clear selection
      toast.success('Issues favorited successfully');
    } catch (error) {
      console.error('Error favoriting issues:', error);
      toast.error('Failed to favorite issues');
    }
  };

  const handleUnfavorite = async () => {
    const selectedIssueIds = Array.from(checkedRows);
    const sourceId = clickedIssue?.["Issue ID"];
    
    try {
      // Process each issue sequentially
      await Promise.all(selectedIssueIds.map(async (issueId) => {
        await toggleFavoriteMutation.mutateAsync({
          issueId,
          sourceId,
          isFavorite: true // true because we want to unfavorite it (API expects opposite)
        });
      }));

      // Update UI state for all affected issues
      setIssueDisplayArray((prevArray) =>
        prevArray.map((issue) =>
          selectedIssueIds.includes(issue["Issue ID"])
            ? { ...issue, is_favorite: false }
            : issue
        )
      );

      // Update clicked issue if it's in the selection
      if (clickedIssue && selectedIssueIds.includes(clickedIssue["Issue ID"])) {
        setClickedIssue((prevIssue) => ({
          ...prevIssue,
          is_favorite: false
        }));
      }

      setCheckedRows(new Set()); // Clear selection
      toast.success('Issues unfavorited successfully');
    } catch (error) {
      console.error('Error unfavoriting issues:', error);
      toast.error('Failed to unfavorite issues');
    }
  };
  /************************  the handle rize height: user could click and dragging to change the rows height*************************/


  /************************ Tab button: Share, Save and Hidden Logic ==> based on the database, change the display *************************/
  // if user shared array happened change, it will redefine our share
  useEffect(() => {
    setShareBlockStates(
      userShareArray.map(() => ({
        checkedRows: new Set(),
        currentRowIndex: -1,
        clickedIssue: null,
        hoveredRow: null,
        rowsHeightMap: {},
        issuePageSize: { page: 1, pageSize: 25 }
      }))
    );
}, [userShareArray]);


  // if user saved array happened change, it will redefine our share
  useEffect(() => {
    setSaveBlockStates(
      userSaveArray.map(() => ({
        checkedRows: new Set(),
        currentRowIndex: -1,
        clickedIssue: null,
        hoveredRow: null,
        rowsHeightMap: {},
        issuePageSize: { page: 1, pageSize: 25 }
      }))
    );
  }, [userSaveArray]);

  // if user hidden array happened change, it will redefine our share
  useEffect(() => {
    setHiddenBlockStates(
      userHiddenArray.map(() => ({
        checkedRows: new Set(),
        currentRowIndex: -1,
        clickedIssue: null,
        hoveredRow: null,
        rowsHeightMap: {},
        issuePageSize: { page: 1, pageSize: 25 }
      }))
    );
  }, [userHiddenArray]);

  // Replace tooltip related code with hook
  const { 
    showTooltip, 
    tooltipPosition, 
    hoveredItem: hoveredRow,
    handleItemHover: handleRowHover,
    handleItemLeave: handleRowLeave,
    handleMouseMove
  } = useTooltip(200); // 200ms delay

  // the main render area
  return (
    <>
      {/* Injecting dynamic styles */}
      <style>
        {generateDynamicStyles(
          highlightArray["Failure Mode"],
          highlightArray["Components"],
          highlightArray["Failure Condition"],
          highlightArray["After Effects"],
          highlightArray["Specification"],
        )}
      </style> 

      {(tableViews === 'Saved' || tableViews === 'Hidden' || tableViews === 'Shared' || tableViews === 'Starred') ?
        <IssueBlock
          displayColumnswithCustomHeader={displayColumnswithCustomHeader}
          handleHeaderCheckboxChange={handleHeaderCheckboxChange}
          handleCheckboxChange={handleCheckboxChange}
          handleColumnResize={handleColumnResize}
          handleCellClick={handleCellClick}
          handleRowClick={handleRowClick}
          setIssuePageSize={setIssuePageSize}
          isSharedView={isSharedView}
          tableViews={tableViews}
          selectedAreas={selectedAreas}
          handleAreaChange={handleAreaChange}
          handleSaveSystem={handleSaveSystem}
          checkOpenTab={checkOpenTab}
          isOpenSpecification={isOpenSpecification}
          rowsHeight={rowsHeight}
          setRowsHeight={setRowsHeight}
          handleRowHover={handleRowHover}
          handleRowLeave={handleRowLeave}
          handleMouseMove={handleMouseMove}
          setCheckedRows={setCheckedRows}

          blockArray={
            tableViews === "Shared"
              ? userShareArray
              : tableViews === "Saved"
                ? userSaveArray
                : userHiddenArray
          }
          dataBlock={
            tableViews === "Shared"
              ? blockShareStates
              : tableViews === "Saved"
                ? blockSaveStates
                : blockHiddenStates
          }
          setDataBlock={
            tableViews === "Shared"
              ? setShareBlockStates
              : tableViews === "Saved"
                ? setSaveBlockStates
                : setHiddenBlockStates
          }
        />
        :
        <>
          {/* Cutomize Issue Table */}
          <CustomizeTable
            clickedIssue={clickedIssue}
            rows={rows}
            displayColumnswithCustomHeader={displayColumnswithCustomHeader}
            checkedRows={checkedRows}
            handleHeaderCheckboxChange={handleHeaderCheckboxChange}
            handleCheckboxChange={handleCheckboxChange}
            //handleStarIssueChange={handleStarIssueChange}
            currentRowIndex={currentRowIndex}
            handleColumnResize={handleColumnResize}
            handleCellClick={handleCellClick}
            handleRowClick={handleRowClick}
            IssuePageSize={IssuePageSize}
            setIssuePageSize={setIssuePageSize}
            isSharedView={isSharedView}
            tableViews={tableViews}
            selectedAreas={selectedAreas}
            handleAreaChange={handleAreaChange}
            handleSaveSystem={handleSaveSystem}
            checkOpenTab={checkOpenTab}
            isOpenSpecification={isOpenSpecification}
            rowsHeight={rowsHeight}
            setRowsHeight={setRowsHeight}
            rowsHeightMap={rowsHeightMap}
            setRowsHeightMap={setRowsHeightMap}
            handleRowHover={handleRowHover}
            handleRowLeave={handleRowLeave}
            handleMouseMove={handleMouseMove}
            hoveredRow={hoveredRow}

            handleDelete={handleDelete}
            handleSave={handleSave}
            handleShare={handleShare}
            handleUnhide={handleUnhide}
            handleHide={handleHide}
            handleUnfavorite={handleUnfavorite}
            handleFavorite={handleFavorite}
            teamMembers={teamMembers}
            isTeamMemberLoading={isTeamMemberLoading}
          />
        </>
      }


      {contextualMenuProps && <ContextualMenu {...contextualMenuProps} style={{fontFamily: "Inter"}} />}
    </>
  );
};

export default IssueDataGrid;
