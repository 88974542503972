// Module 2 (Discover) page: looks up the issue to see if it has occurred before and gets a few ideas of how to solve and where the considerations are.
import React, { useEffect } from "react";
import "./discover.css";
import IssueArea from "./Issue Table/IssueArea";
import DemoKeySpecArea from "./Key Specifications/DemoKeySpecArea";
import { useSharedSpecsQuery, useSearchMutation} from "../../api/queries";
import toast from 'react-hot-toast';
import {useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from "../../AppContext";
import { mapIssueFields } from '../../config/issueMapping';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// 1. share the key specification data card
// the value array including:
// 1. specificationsMenu: Issue Insights or Key Specs
// 2. selectIssueDetails: current issue (issue ID) and the Related Issues
// 3. subKeySpecsMenu: Design Docs, Standards and the Scholar Search
// 4. the subsystem tittle
const Discover = () => {
  const { "*": token } = useParams() || null;
  //console.log(token); // check get the token

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryParam = searchParams.get("query") || "";

  const navigate = useNavigate(); // jump page
  const sharedSpeciContentMutation = useSharedSpecsQuery(token);
  const searchMutation = useSearchMutation();
  const {setBasicValue, setSpecificationsMenu, setSubKeySpecsMenu, setSelectIssueDetails, setOpenItem, setIsOpenSpecification,
    setClickedIssue, setIssueRelativeArray, setStagesArray, setFilterOptions, setClickedSimilarIssue, setSelectedMenuItem, setHasSearched,
    setIsSearchError, setModalMessage, setIsSearchLoading, setShowsStarIssue, setTableViews, setSpecData, clickedIssue,
  } = useAppContext();


  // use the backend function to check current URL token was correct or not
  useEffect(() => {
    setSelectedMenuItem("discover");

    const createInitialTerms = (currTagsArr) => {
      let terms = [];
      // console.log(currTagsArr);
      if (currTagsArr.query) {
        terms.push({
          id: Date.now().toString(),
          value: currTagsArr.query,
          isIssueId: !isNaN(Number(queryParam)),
          fieldKey: 'query', // Check if queryParam is a number
        });
      }
      
      // Check for systemParams, statusParams, and requestorParams, keywordsParams
      const allParams = [
        ...currTagsArr.systemArr.map(param => ({
          id: `filter-system-${param.toLowerCase().replace(/\s+/g, '-')}`,
          value: param,
          isIssueId: false,
          fieldKey: 'system',
        })),
        ...currTagsArr.statusArr.map(param => ({
          id: `filter-status-${param.toLowerCase().replace(/\s+/g, '-')}`,
          value: param,
          isIssueId: false,
          fieldKey: 'status',
        })),
        ...currTagsArr.requestorArr.map(param => ({
          id: `filter-requestor-${param.toLowerCase().replace(/\s+/g, '-')}`,
          value: param,
          isIssueId: false,
          fieldKey: 'requestor',
        })),
        ...currTagsArr.keywordsArr.map(param => ({
          id: `filter-keywords-${param.toLowerCase().replace(/\s+/g, '-')}`,
          value: param,
          isIssueId: false,
          fieldKey: 'keywords',
        })),
      ];
      return [...terms, ...allParams];
    };


    if (token && !queryParam) {
      sharedSpeciContentMutation.mutate(undefined, {
        onSuccess: (data) => {
          //console.log(data.currTagsArr);
          setBasicValue(createInitialTerms(data.currTagsArr)); // setup all the user selected tags
          setSpecificationsMenu(data.values[0]); 
          setSubKeySpecsMenu(data.values[1]);
          setSelectIssueDetails(data.values[2]);
          setOpenItem([data.values[3]]);
          setIsOpenSpecification(true);
          setHasSearched(true); // if views token shared, it means searched some issue already
          localStorage.setItem("hasSearched", true); // if views token shared, it means searched some issue already
          fetchData(data.currTagsArr.query, data.currTagsArr.statusArr, data.currTagsArr.systemArr, data.currTagsArr.requestorArr, data.currTagsArr.keywordsArr); // call the search API function and get current shared issue id data

          // call the search API function
          async function fetchData(query, statusArr, systemArr, requestorArr, keywordsArr) {
            if (data.currTagsArr) {
              setIsSearchLoading(true);
              setShowsStarIssue(false);
              setTableViews("All");

              try {
                // used the data to call the search API function ==> get all the result, key speci, and relative issue list
                const result = await searchMutation.mutateAsync({
                  query,  // this is the issue ID
                  statusArr,
                  systemArr,
                  requestorArr,
                  keywordsArr
                });
                
                if (result.original_issue) {
                  const updatedIssueItem = mapIssueFields(result.original_issue, true);
                  setClickedIssue(updatedIssueItem); // set the original_issue part as currect issue (clicked issue)
                  setClickedSimilarIssue(updatedIssueItem); // set th shared current issue to open deatils pop windows

                  // used our map route to make the attrubite as data column to display
                  const mappedRelatedIssues = Array.isArray(result.related_issues)
                    ? result.related_issues.map((issue) => mapIssueFields(issue))
                    : [];
                  setIssueRelativeArray(mappedRelatedIssues); // setup the issue data rows 

                  // get all stage item used for stage Tab menu
                  const stagesArr = [
                    "All",
                    ...new Set(mappedRelatedIssues.map((issue) => issue.Phase)),
                  ]
                    .filter(Boolean)
                    .sort();
                  setStagesArray(stagesArr);
                  setSpecData(result.specs || []);

                  // get all the filter select option used for search filed fiter menu
                  setFilterOptions(result.filter_options);
                } else {
                  setModalMessage(
                    "Sorry, cannot find this input number, please try to input another Issue Number or Part Number",
                  );
                  setIsSearchError(true);
                  setClickedIssue(null);
                  setIssueRelativeArray([]);
                  setStagesArray(["All"]);
                }
              } catch (error) {
                console.log("Search error:", error);
                setModalMessage(
                  "An error occurred during the search. Please try again.",
                );
                setIsSearchError(true);
                setClickedIssue(null);
                setIssueRelativeArray([]);
                setSpecData([]);
                setStagesArray(["All"]);
              } finally {
                setIsSearchLoading(false);
              }
            }
          }
        },
        onError: () => {
          toast.error('Invalid token');
          navigate('/404Error'); // if token was error jump to our 404 Error page
        }
      });
    }

    // if(queryParam && !token){
    //   const basicVal = [{
    //     id: queryParam,
    //     value: queryParam,
    //     isIssueId: !isNaN(Number(queryParam)),
    //   }];
    //   setBasicValue(basicVal);
    //   setClickedSimilarIssue(null);
    //   setSubKeySpecsMenu("design_docs");
    // }
  }, // eslint-disable-next-line 
  [token]);

  // if the data was still loading, run the loading animation
  if (sharedSpeciContentMutation.isLoading) {
    return (
      <div className="fade-in">
        <div className="dots-loader">
          {Array.from({ length: 16 }).map((_, index) => (
            <div
              key={index}
              className="dot"
              style={{
                transform: `rotate(${index * 22.5}deg) translateY(-20px)`,
                animationDelay: `${index * 0.125}s`
              }}
            />
          ))}
        </div>
        <p style={{ marginLeft: "8px" }}>Astra was Loading shared issues...</p>
      </div>
    );
  }

  // the main return area
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="discover">
        {/* Discover Issue Area: search bar, cutomize issue table */}
        <IssueArea searchParams={searchParams} />

        {/* Discover Specification area: key specification and standards area (new and old)*/}
        <DemoKeySpecArea />
      </div>
    </DndProvider>
  );
};

export default Discover;