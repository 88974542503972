import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import "./TrendsFilter.css"
import AchieveSearch from "../Achieve Search/AchieveSearch.js";
import {
    DropdownMenu,
    DropdownMenuTrigger,
    DropdownMenuContent,
    DropdownMenuItem,
} from "../../ui//DropdownMenu/DropdownMenu.jsx";

const TrendsFilter = ({
    data,  // the data from parents level
    selectedProduct,
    setSelectedProduct,
    selectedCost,
    setSelectedCost,
}) => {
    const [costDropDownArray, setCostDropDownArray] = useState([]);

    // Listen to selectedProduct changes and update the cost option
    useEffect(() => {
        if (selectedProduct && selectedProduct.value in data) {
            setCostDropDownArray(data[selectedProduct.value].cost);
        } else {
            setCostDropDownArray([]);
        }
    }, [selectedProduct, data]);

    // function: used to cost dropdown menu selected
    const handleSelectCost = (costItem) => {
        setSelectedCost(costItem);
    };

    return <div className="TrendsFilter">
        {/* The trends seaerch product and cost dropdown menu */}
        <div className="SearchArea">
            {/* AchieveSearch component */}
            <AchieveSearch 
                width="250px"
                height="34px"
                options={data}
                selectedProduct={selectedProduct}  // control user selected
                onSearch={setSelectedProduct} // Listen user selected
            />

            <div className="viewByArea">View by</div>

            {/* Cost dropdown menu */}
            <DropdownMenu >
                <DropdownMenuTrigger height="34px" disabled={selectedProduct ? false : true}>
                    <span className="dropdown-menu-trigger-text">
                        {selectedCost} 
                    </span>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    {costDropDownArray.map((item) => (
                        <DropdownMenuItem key={item} onSelect={() => handleSelectCost(item)}>
                            {item}
                        </DropdownMenuItem>
                    ))}
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    </div>
};

TrendsFilter.propTypes = {
    data: PropTypes.object.isRequired,  
    selectedProduct: PropTypes.object,  
    setSelectedProduct: PropTypes.func, 
    selectedCost: PropTypes.string,  
    setSelectedCost: PropTypes.func, 
};

export default TrendsFilter;