// Reusebale Library: the button component (New)
import React from "react";
import PropTypes from "prop-types";
import "./Button.css";
import classNames from "classnames";

const Button = ({
  children,
  size = "medium",
  variant = "primary",
  iconOnly = false,
  disabled = false,
  width, // Add width prop
  onClick,
  style,
}) => {
  const className = classNames(
    "button",
    `button--${size}`,
    `button--${variant}`,
    { "button--disabled": disabled },
    { "button--icon-only": iconOnly }, // add the icon button layout
  );

  return (
    <button
      className={className}
      style={{ width: width, ...style }} // Merge inline styles
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["xsmall", "small", "medium", "large"]),
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "secondary-color",
    "tertiary-gray",
    "tertiary-color",
    "link-gray",
    "link-color",
    "danger-primary",
    "danger-secondary",
    "danger-tertiary",
    "danger-link",
  ]),
  iconOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  width: PropTypes.string, // Add width prop type
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default Button;
