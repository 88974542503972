import React, { useState } from "react";
import './Trends.css';
import { ChevronRight } from "../../../Components/icons/Arrows";
import { TrendsFilter, FormatTable, Badge, DetailsWindow, IssueTrendsBar, FormatCompon, BarChart } from "../../../Components";
import TrendsData from "../../../datasets/achieveData/TrendsData.json"
import { useAppContext } from "../../../AppContext";
import { ChevronDownIcon } from "../../../Components/icons";
import {  DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuLabelItem } from "../../../Components/ui/DropdownMenu/DropdownMenu";

const Dashboard = () => {
    // get the state from Appcontext API
    const { selectedProduct, setSelectedProduct, selectedCost, setSelectedCost, openDetailsWindow, setOpenDetailsWindow,
        clickProduct, setClickProduct, setClickFailureMode, clickFailureMode,
    } = useAppContext();
    const [selectedAction, setSelectedAction] = useState(null);
    const [selectModel, setSelectModel] = useState("Select model");

    const handleOccurrenceClick = (row) => {
        setSelectedAction((prev) => (prev === row ? null : row));
    };

    // close function
    const handleClose = () => {
        setOpenDetailsWindow("");
        setSelectModel("Select model");
    }

    // Trends filter examplde data
    const sampleData = {
        "Propulsion": {
            label: "System",
            cost: ["Cause of delays", "Cost", "Failure mode", "Failure rate", "Resolution time"]
        },
        "Avionics": {
            label: "System",
            cost: ["Cause of delays", "Cost", "Failure mode"]
        },
        "Fuel pump": {
            label: "Component",
            cost: ["Cause of delays", "Failure mode"]
        },
        "Braking": {
            label: "Assembly",
            cost: ["Failure mode", "Failure rate", "Resolution time"]
        }
    };

    // the get color function in the product and defualt level
    const getColorVersion = (label) => {
        if (!label) return "category-gray";
        switch (label) {
            case "System":
                return "category-green";
            case "Design":
                return "category-green";
            case "Maintenance":
                return "category-red";
            case "Component":
                return "category-yellow";
            case "Assembly":
                return "category-purple";
            case "Supplier quality":
                return "category-blue";
            default:
                return "category-gray";
        }
    };

    const handleBackProduct = () => {
        setOpenDetailsWindow("product");
        setClickFailureMode(null);
    }

    // the badge color in the fauiler mode page level
    const getBadgeColor = (label) => {
        switch (label) {
          case "Stayed closed":
            return "PrioritiesLow";
          case "Temporarily resolved":
            return "PrioritiesMedium";
          default:
            return "PrioritiesHigh";
        }
      };

    // main render area
    return <div className="trendsArea">
        <div className="pageRoute">
            <button className={`routeButton DisableClicked`}> Achieve </button>
            <ChevronRight size={16} color="#D0D5DD" />
            <button className={`routeButton isClicked`}> Trends </button>
        </div>

        <TrendsFilter
            data={sampleData}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            selectedCost={selectedCost}
            setSelectedCost={setSelectedCost}
        />

        <div className="trendsDataFormat">
            <FormatTable
                width="100%"
                height="calc(100vh - 180px)"
                data={TrendsData}
                columns={[
                    {
                        key: "Product name",
                        label: "Product Name",
                        width: "35%",
                        render: (value, row) => (
                            <div className="product-cell">
                                {value}
                                {row.label && (
                                    <Badge type="category" variant={getColorVersion(row.label)}>
                                        {row.label}
                                    </Badge>
                                )}
                            </div>
                        ),
                    },
                    { key: "Mentions", label: "Mentions", width: "18%" },
                    { key: "Business Impact", label: "Business Impact", width: "18%" },
                    { key: "Supplier", label: "Supplier", width: "18%" },
                    { key: "Occurrences", label: "Occurrences", width: "11%" },
                ]}
                rowHeight="50px"
                setOpenDetailsWindow={setOpenDetailsWindow}
                setClickProduct={setClickProduct}
                currentLevel={openDetailsWindow}
            />

            {clickProduct && (
                <DetailsWindow
                    open={openDetailsWindow === "product" || openDetailsWindow === "Failure Mode"}
                    onClose={handleClose}
                    width="92vw"
                    height="100vh"
                >
                    {openDetailsWindow === "product" && (
                        <div className="pageRoute">
                            <button className={`routeButton DisableClicked`}> Propultion </button>
                            <ChevronRight size={16} color="#D0D5DD" />
                            <button className={`routeButton isClicked`}>
                                {clickProduct["Product name"]}: Failure modes
                            </button>
                        </div>
                    )}

                    {openDetailsWindow === "product" && (
                        <>
                            <span className="detailWindowTitle">{clickProduct["Product name"]}</span>
                            <span className="detailWindowText">{clickProduct["Summary"] || ""}</span>

                            {clickProduct["Product name"] === "Engine" && (
                                <div className="failureModeChartArea">
                                    <DropdownMenu>
                                        <DropdownMenuTrigger height="32px">
                                            <span className="dropdown-menu-trigger-text">{selectModel}</span>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent>
                                            {clickProduct?.["Cost_per_year"]
                                                ? Object.keys(clickProduct["Cost_per_year"]).map((item) => (
                                                    <DropdownMenuLabelItem
                                                        key={item}
                                                        checked={selectModel === item}
                                                        onSelect={() => setSelectModel(item)}
                                                    >
                                                        {item}
                                                    </DropdownMenuLabelItem>
                                                ))
                                                : <div>No data available</div>
                                            }
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                </div>
                            )}

                            {clickProduct["Product name"] === "Engine" && (
                                <BarChart
                                    width="calc(100% - 48px)"
                                    height="400px"
                                    data={selectModel !== "Select model" ? clickProduct["Cost_per_year"][selectModel] : clickProduct["Cost_per_year"][Object.keys(clickProduct["Cost_per_year"])[0]]}
                                />
                            )}

                            <div className="TrendsArea">
                                <span className="detailWindowTitle">Issue trends: </span>
                                <IssueTrendsBar
                                    width="100%"
                                    height="14px"
                                    data={[
                                        { name: "Re-opened after", occurrences: 10, color: "#FDA29B" },
                                        { name: "Solved unf", occurrences: 10, color: "#FEDF89" },
                                        { name: "Stayed closed", occurrences: 80, color: "#75E0A7" },
                                    ]}
                                />
                            </div>


                            {clickProduct["Product name"] === "Engine" && (
                                <FormatTable
                                    width="100%"
                                    height="640px"
                                    data={clickProduct["Failure Modes Trends"]}
                                    columns={[
                                        { key: "Failure Mode", label: "Failure Mode", width: "27%" },
                                        {
                                            key: "Failure Type",
                                            label: "Failure Type",
                                            width: "12%",
                                            render: (value) => (
                                                <div className="product-cell">
                                                    <Badge type="category" variant={getColorVersion(value)}>
                                                        {value}
                                                    </Badge>
                                                </div>
                                            ),
                                        },
                                        { key: "Resolutions", label: "Resolutions", width: "10%" },
                                        { key: "Mitigation Steps", label: "Mitigation Steps", width: "10%" },
                                        { key: "Avg. Downtime", label: "Avg. Downtime", width: "10%" },
                                        {
                                            key: "Open Issues",
                                            label: "Open Issues",
                                            width: "10%",
                                            render: (value) => (
                                                <div className="product-cell">
                                                    <Badge type="status" variant="Current">
                                                        {value} issues
                                                    </Badge>
                                                </div>
                                            ),
                                        },
                                        { key: "Last Occurence", label: "Last Occurence", width: "13%" },
                                        { key: "Occurrences", label: "Occurrences", width: "8%" },
                                    ]}
                                    rowHeight="50px"
                                    setOpenDetailsWindow={setOpenDetailsWindow}
                                    setClickProduct={setClickProduct}
                                    setClickFailureMode={setClickFailureMode}
                                    currentLevel={openDetailsWindow}
                                />
                            )}
                        </>
                    )}

                    {openDetailsWindow === "Failure Mode" && (
                        <div className="pageRoute">
                            <button className={`routeButton DisableClicked`}> Propultion </button>
                            <ChevronRight size={16} color="#D0D5DD" />
                            <button className={`routeButton`} onClick={handleBackProduct}>
                                {clickProduct["Product name"]}: Failure modes
                            </button>
                            <ChevronRight size={16} color="#D0D5DD" />
                            <button className={`routeButton isClicked`}>
                                {clickFailureMode["Failure Mode"]}
                            </button>
                        </div>
                    )}

                    {openDetailsWindow === "Failure Mode" && (
                        <>
                            <span className="detailWindowTitle">{clickFailureMode["Failure Mode"]}</span>

                            <div className="TrendsArea">
                                <span className="detailWindowTitle">Issue trends: </span>
                                <IssueTrendsBar
                                    width="100%"
                                    height="14px"
                                    data={[
                                        { name: "Re-opened after", occurrences: 10, color: "#FDA29B" },
                                        { name: "Solved unf", occurrences: 30, color: "#FEDF89" },
                                        { name: "Stayed closed", occurrences: 60, color: "#75E0A7" },
                                    ]}
                                />
                            </div>

                            {clickFailureMode["Actions takens"] && (
                                <FormatCompon
                                    width="100%"
                                    data={clickFailureMode["Actions takens"]}
                                    columns={[
                                        {
                                            key: "Action taken",
                                            label: "Action taken",
                                            width: "50%",
                                            render: (value, row) => (
                                                <div className="actionsTakens-cell">
                                                    <span className="nameText">{value}</span>
                                                    {row.Occurrences && (
                                                        <button className="occureencesButton" onClick={() => handleOccurrenceClick(row["Action taken"])}>
                                                            {Object.keys(row.Occurrences).length} occurrences
                                                            <ChevronDownIcon size={20} className={`occureencesButton-icon ${row["Action taken"] === selectedAction ? 'open' : ''}`}/>
                                                        </button>
                                                    )}
                                                </div>
                                            ),
                                        },
                                        {
                                            key: "Business Impact",
                                            label: "Business impact",
                                            width: "15%",
                                            render: (value) => (
                                                <span>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value)}</span>
                                            ),
                                        },
                                        {
                                            key: "Effectiveness",
                                            label: "Effectiveness",
                                            width: "15%",
                                            render: (value) => (
                                                <div className="Effectiveness-cell">
                                                    <Badge type="priorities" variant={getBadgeColor(value)}>{value}</Badge>
                                                </div>
                                            ),
                                        },
                                        {
                                            key: "Exp. Required",
                                            label: "Exp. Required",
                                            width: "10%",
                                        },
                                        {
                                            key: 'Avg. Res Day',
                                            label: "Avg. Res Day",
                                            width: "10%",
                                            render: (value) => (
                                                <div className="Effectiveness-cell">
                                                    {value} days
                                                </div>
                                            ),
                                        }
                                    ]}
                                    rowHeight="50px"
                                    selectedAction={selectedAction}  // Pass the expandedRowIndex to FormatCompon
                                    flex={1}
                                />
                            )}
                        </>
                    )}
                </DetailsWindow>
            )}
        </div>
    </div>
}

export default Dashboard