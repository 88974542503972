// Apiphany Customize AccordionItem
import React, { useRef } from "react";
import PropTypes from "prop-types";
import "./AccordionItems.css";
import { ChevronDownIcon } from "../../icons";

const AccordionItems = ({
    title,
    defaultWidth = "565px",
    children = [],
    openItem,
    setOpenItem,
}) => {
    const titleRef = useRef(null);
    
    // just based on the OpenItem array, judge current accordionitems was open or not
    const isOpen = (Array.isArray(openItem) && openItem.length > 0) ? openItem.includes(title) : false;

    // the function to open and closed it
    const toggleAccordion = () => {
        setOpenItem((prevOpenItem) => 
            prevOpenItem.includes(title) 
                ? prevOpenItem.filter((item) => item !== title) 
                : [...prevOpenItem, title]
        );
    };

    return (
        <div className="accordion-item">
            <div className={`accordion-header ${isOpen ? "open" : ""}`} onClick={toggleAccordion} style={{ width: defaultWidth }}>
                <div className="accordion-leftHeader">
                    <span className={`accordion-title ${isOpen ? "" : "NotOpen"}`} ref={titleRef}>
                        {title}
                    </span>
                    <div className="badge">{children.length || 1}</div>
                </div>

                <div className={`arrow-icon ${isOpen ? "open" : ""}`}>
                    <ChevronDownIcon color="var(--color-gray-500)"/>
                </div>
            </div>
             
            {isOpen && <div className={`accordion-content ${isOpen ? "show" : "hide"}`}>
                {children}
            </div>}
        </div>
    );
};

AccordionItems.propTypes = {
    title: PropTypes.string.isRequired,
    defaultWidth: PropTypes.string,
    children: PropTypes.node.isRequired,
    openItem: PropTypes.array.isRequired,
    setOpenItem: PropTypes.func.isRequired,
};

export default AccordionItems;

