/*
  this is the search area of module 2 (Search) page: including search bar, dropdownlist, and search button
*/
import React, { useEffect, useCallback, useState } from "react";
import { useAppContext } from "../../../AppContext";
import { useSearchMutation, useAllIssueID } from "../../../api/queries";
import "./searchLayout.css";
import { useLocation, useNavigate } from "react-router-dom";
import { TabButton } from "../../../Components/index";
import SearchField from "../../../Components/ui/SearchField/SearchField";
import { mapIssueFields } from '../../../config/issueMapping';

const SearchIssue = () => {
  // get the data from the context api store
  const {
    //clickedIssue,
    inputIssue,
    setInputIssue,
    setIssueMenu,
    setSpecData,
    setIsSearchError,
    setStagesArray,
    setModalMessage,
    //issueArray,
    //setIssueArray,
    setIsSearchLoading,
    setSelectedAreas,
    setSelectStatus,
    setDiscoverColumns,
    setCheckedRows,
    setClickedSimilarIssue,
    stagesArray,
    issueMenu,
    setSpeciIssueId,
    setShowDropDown,
    setIssuePageSize,
    setShowsStarIssue,
    setClickedIssue,
    setIssueRelativeArray,
    hasSearched,
    setHasSearched,
    basicValue,
    setBasicValue,
    setCurrentRowIndex,
    setTableViews,
    filterOptions, 
    setFilterOptions,
    setSubKeySpecsMenu, 
    tagsTerm, 
    setTagsTerm,
  } = useAppContext();

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // the 4 difference kinds tags: query(issue id or issue key), status, system, and requestor
  const queryParam = searchParams.get("query") || ""; // the issue id and issue key only could be single
  const systemParams = searchParams.getAll("system") || []; // system
  const statusParams = searchParams.getAll("status") || []; // status
  const requestorParams = searchParams.getAll("requestor") || []; // requestor
  const keywordsParams = searchParams.getAll("keywords") || []; // the user input tags

  const searchMutation = useSearchMutation();
  const [allIssueID, setAllIssueId] = useState([]);

  const dataIssueIDs = useAllIssueID();
  
  //based on the backend data get all issue IDs
  useEffect(() => {
    if(dataIssueIDs.data !== undefined && dataIssueIDs.data != null){
      setAllIssueId(dataIssueIDs.data);
    }
  }, [dataIssueIDs])

  //console.log(allIssueIDs.data.data);

  /**************************************  1. the filter stages menu part: ************************************* */
  // the filter menu of search field item select function also used for call the backend function
  const handleSearchItemClick = useCallback(
    async (query, statusArr, systemArr, requestorArr, keywordsArr) => {
      // console.log(query);
      setIsSearchLoading(true);
      setShowsStarIssue(false);
      setCheckedRows(new Set()); // reset the selected
      setTableViews("All"); // if user start a new search, will back to default page ("All")
      setIssuePageSize((prevState) => ({
        ...prevState,
        page: 1, 
      }));// if user start a new search, will back to default status page 0

      try {
        const result = await searchMutation.mutateAsync({
          query,  // this is the issue ID
          statusArr,
          systemArr,
          requestorArr,
          keywordsArr
        });
        // console.log(result); // ==> used to check what data we get from the database 

        if (result.original_issue) {
          const updatedIssueItem = mapIssueFields(result.original_issue, true);
          setClickedIssue(updatedIssueItem);
          setSpeciIssueId(result.original_issue["Issue id"].toString()) // used for display the data of key specification area (right)
          
          // get the all the columns ==> used for issue table
          const updatedKeys = Object.keys(updatedIssueItem).filter(
            (item) =>
              ![
                "Date",
                "Opening date",
                "Last updated",
                "Status",
                "Stages",
                "Area Short Name",
                "duplicates",
                "All_Comments",
                "AreaList",
                "Issue Details Tag",
                "Fit Form or Function categorization",
              ].includes(item)
          );
          
          // get all the columns from data and added in the array
          setDiscoverColumns((prevColumns) => {
            const existingFields = new Set(prevColumns.map((col) => col.field));
            const newColumns = updatedKeys
              .filter((key) => !existingFields.has(key))
              .map((key, index) => ({
                field: key,
                headerName: key,
                width: 200,
                minWidth: 150,
                index: prevColumns.length + index, // keep the column item sequences
                hide: true, // default was hide
                default: false, // not default 
              }));
          
            return [...prevColumns, ...newColumns]; // keep our old sequences
          });

          
          setInputIssue(result.original_issue["Issue id"]); // used for search bar display 

          // the relative issue list 
          const mappedRelatedIssues = Array.isArray(result.related_issues)
            ? result.related_issues.map((issue) => mapIssueFields(issue))
            : [];
          setIsSearchError(false);
          setIssueRelativeArray(mappedRelatedIssues);

          // get all the phases of current data
          const stagesArr = [
            "All",
            ...new Set(mappedRelatedIssues.map((issue) => issue.Phase)),
          ]
            .filter(Boolean)
            .sort();
          setStagesArray(stagesArr);
          setSpecData(result.specs || []);
          setSubKeySpecsMenu("design_docs");

          // Extract unique filter options from the results
          setFilterOptions(result.filter_options);
        } else {
          setModalMessage(
            "Sorry, cannot find this input number, please try to input another Issue Number or Part Number",
          );
          setIsSearchError(true);
          setClickedIssue(null);
          setIssueRelativeArray([]);
          setStagesArray(["All"]);
        }
      } catch (error) {
        console.log("Search error:", error);
        setModalMessage(
          "An error occurred during the search. Please try again.",
        );
        setIsSearchError(true);
        setClickedIssue(null);
        setIssueRelativeArray([]);
        setStagesArray(["All"]);
      } finally {
        setIsSearchLoading(false);
      }
    },
    // eslint-disable-next-line
    [searchMutation, setSpecData],
  );

  useEffect(() => {
    // ==> check the queryParam in every time  
    // console.log(queryParam);  
    const performSearch = async () => {
      if (queryParam && queryParam !== inputIssue ) {
        setInputIssue(queryParam);
        await handleSearchItemClick(queryParam, statusParams, systemParams, requestorParams, keywordsParams);
      }
    };

    performSearch();

    setBasicValue(createInitialTerms); // analsy the URL and get all the tags used for move up and move previous button of website chrome
    // eslint-disable-next-line
  }, [queryParam, tagsTerm, location]);

  // use aysnc to mock the search process and ==> call the Apiphany Search API in the future  <=====
  // const mockSearch = (id) => {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       resolve([`Similar issue array result for ${id}`]);
  //     }, 1200); // mock the search
  //   });
  // };
  // use aysnc to mock the search process and ==> call the Apiphany Search API in the future  <=====

  /**************************************  2. the filter stages menu part: ************************************* */
  const menuDefaultArray = [
    "All",
    "Planning",
    "R&D",
    "Product Engineering",
    "Manufacturing",
    "Field Testing",
    "Maintenance"
  ];

  //used for the switch menu page (All, Planning, R&D, Produce Engineering, Manufacturing, and Field & Warranty)
  const changePart = useCallback(
    (e) => {
      setIssueMenu(e.target.value);
    },
    [setIssueMenu],
  );

  // based on user search, jump into the search result query with call api
  const handleSubmit = (e, searchTerms) => {
    e.preventDefault();

    if (hasSearched === false) {
      setHasSearched(true);
      localStorage.setItem("hasSearched", true);
    }

    if (searchTerms) {
      setTagsTerm(searchTerms);
      setCurrentRowIndex(0);
      const searchQuery = searchTerms
        .map((item) => {
          // Check if array format is needed or not
          const isArrayField = ["system", "status", "requestor", "keywords"].includes(item.fieldKey);
          return isArrayField
            ? `${item.fieldKey}=${encodeURIComponent(item.value)}`
            : `query=${encodeURIComponent(item.value)}`;
        })
        .join("&");

      // jump to the New URL
      navigate(`/discover?${searchQuery}`);
    }
  };

  // the keyboard press enter to search for frist issue search
  const handleKeyBoradSubmit = (queryString) => {
    if (hasSearched === false) {
      setHasSearched(true);
      localStorage.setItem("hasSearched", true);
    }
    setCurrentRowIndex(0);
    navigate(`/discover?${queryString}`);
  };

  // the search function: through user input search the all the relative issue id data
  const handleSearch = (e, searchTerms, isEnterPressed) => {
    // console.log(searchTerms);
    if (searchTerms) {
      setTagsTerm(searchTerms);
      const queryString = searchTerms
        .filter((term) => term.isIssueId === true)
        .map((term) => (term.isIssueId ? term.value : term.value))
        .join(" ");

      const searchQuery = searchTerms
        .map((item) => {
           // Check if array format is needed or not
          const isArrayField = ["system", "status", "requestor", "keywords"].includes(item.fieldKey);
          return isArrayField
            ? `${item.fieldKey}=${encodeURIComponent(item.value)}`
            : `query=${encodeURIComponent(item.value)}`;
        })
        .join("&");

      // console.log(queryString);
      // console.log(searchQuery);
      setShowDropDown(queryString.trim() !== "");

      if (isEnterPressed) {
        handleKeyBoradSubmit(searchQuery);
      }

      if (queryString.trim() === "") {
        setClickedIssue(null);
        setClickedSimilarIssue(null);
      }

      window.analytics.track('Issue Query', {
        query: searchQuery
      });

      setCurrentRowIndex(0);
    }
  };

  // based the URL, get systemParams, statusParams, and requestorParams, keywordsParams ==> used for the search field keywordsParams
  const createInitialTerms = () => {
    let terms = [];
    
    if (queryParam) {
      terms.push({
        id: Date.now().toString(),
        value: queryParam,
        isIssueId: !isNaN(Number(queryParam)),
        fieldKey: 'query', // Check if queryParam is a number
      });
    }
    
    // Check for systemParams, statusParams, and requestorParams, keywordsParams
    const allParams = [
      ...systemParams.map(param => ({
        id: `filter-system-${param.toLowerCase().replace(/\s+/g, '-')}`,
        value: param,
        isIssueId: false,
        fieldKey: 'system',
      })),
      ...statusParams.map(param => ({
        id: `filter-status-${param.toLowerCase().replace(/\s+/g, '-')}`,
        value: param,
        isIssueId: false,
        fieldKey: 'status',
      })),
      ...requestorParams.map(param => ({
        id: `filter-requestor-${param.toLowerCase().replace(/\s+/g, '-')}`,
        value: param,
        isIssueId: false,
        fieldKey: 'requestor',
      })),
      ...keywordsParams.map(param => ({
        id: `filter-keywords-${param.toLowerCase().replace(/\s+/g, '-')}`,
        value: param,
        isIssueId: false,
        fieldKey: 'keywords',
      })),
    ];
    return [...terms, ...allParams];
  };

  // the main render area
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        background: "white",
      }}
    >

      <div className="searchBox">
        <p style={{ userSelect: "none", whiteSpace: "nowrap" }}>Discover Occurrences</p>
        <div
          style={{
            display: "flex",
            outline: "0",
            flex: "1",
            alignItems: "center",
            paddingRight: "0",
            position: "relative",
            gap: "8px",
          }}
        >
          <div style={{ flex: 1 }}>
            <SearchField
              placeholder="Add tags separated by commas to begin your search"
              onSearch={handleSearch}
              initialTerms={createInitialTerms()}
              filterOptions={filterOptions}
              setSelectedAreas={setSelectedAreas}
              setSelectStatus={setSelectStatus}
              basicValue={basicValue}
              setBasicValue={setBasicValue}
              dropDownMenuData={allIssueID}
              handleSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>

      {/* the issue menu page */}
      <div className="tabs-container">
        <div
          style={{ display: "flex", justifyContent: "flex-start", gap: "4px" }}
        >
          {stagesArray.length > 0 &&
            menuDefaultArray.map((stages) => {
              const isStageAllowed = stagesArray.includes(stages);
              return (
                <TabButton
                  key={stages}
                  label={stages}
                  value={stages}
                  variant="black"
                  isActive={issueMenu === stages ? true : false}
                  onClick={(e) => isStageAllowed && changePart(e)}
                  isDisable={!isStageAllowed}
                />
              );
            })}

          {stagesArray.length === 0 &&
            menuDefaultArray.map((stages) => {
              return (
                <TabButton
                  key={stages}
                  label={stages}
                  value={stages}
                  isActive={issueMenu === stages ? true : false}
                  onClick={(e) => changePart(e)}
                  isDisable={false}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default SearchIssue;
