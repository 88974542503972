/*
    this is the user account information page 
*/
import React, { useEffect, useState, useRef } from "react";
import "./userProfile.css";
import userImage from "../../image/user.png";
import { useAppContext } from "../../AppContext";
import { InputFields, Button } from "../../Components";
import useAuthStore from "../../stores/useAuthStore";
import { useUpdatePasswordMutation } from "../../api/queries";
import toast from 'react-hot-toast';

const UserPage = ({
  setIsLoggedIn,
  setLoginUser,
  loginUser,
  onPasswordUpdate,
}) => {
  //const { userDetailInfor, setSelectedMenuItem } = useAppContext();
  const { setSelectedMenuItem } = useAppContext();
  const { user } = useAuthStore();

  // format password
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  // the password requirnment
  const [passwordValidation, setPasswordValidation] = useState({
    hasMinLength: false,
    hasUpperCase: false,
    hasNumberAndSpecial: false,
    passwordsMatch: false,
  });

  // the state to check whether backend update password scucess or not
  const [updateStatus, setUpdateStatus] = useState({
    loading: false,
    error: null,
    success: false,
  });

  // the ref used for keyboard enter
  const currentPasswordRef = useRef(null);
  const newPasswordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  // used "Enter" jump to next input field
  const handleKeyDown = (e, field) => {
    if (e.key === "Enter") {
      if (field === "currentPassword") {
        newPasswordRef.current.focus();
      } else if (field === "newPassword") {
        confirmPasswordRef.current.focus();
      } else if (field === "confirmPassword") {
        e.target.blur(); // jump to outside of active input
        handleUpdatePassword(); // Trigger the password update function
      }
    }
  };


  const updatePasswordMutation = useUpdatePasswordMutation();

  useEffect(() => {
    setSelectedMenuItem("user-account");
  }, [setSelectedMenuItem]);

  // check whether the new password requriment test
  const checkPasswordRequirements = (password) => {
    return {
      hasMinLength: password.length >= 8,
      hasUpperCase: /[A-Z]/.test(password),
      hasNumberAndSpecial:
        /[0-9]/.test(password) && /[!@#$%^&*(),.?":{}|<>]/.test(password),
      passwordsMatch: password === formData.confirmPassword,
    };
  };

  // function to update the new password
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "newPassword") {
      setPasswordValidation(checkPasswordRequirements(value));
    } else if (name === "confirmPassword") {
      setPasswordValidation((prev) => ({
        ...prev,
        passwordsMatch: value === formData.newPassword,
      }));
    }
  };

  // check whether all the password requirment was correct
  const isPasswordValid = () => {
    return Object.values(passwordValidation).every((value) => value === true);
  };

  // the async function ==> now used for mock the data changed from backend database
  const handleUpdatePassword = async () => {
    try {
      setUpdateStatus({ loading: true, error: null, success: false });

      const result = await updatePasswordMutation.mutateAsync({
        currentPassword: formData.currentPassword,
        newPassword: formData.newPassword,
        confirmPassword: formData.confirmPassword
      });

      // Check if the update was successful
      if (result.status === 'success') {
        setUpdateStatus({ loading: false, error: null, success: true });

        toast.success( "Password updated successfully"); // success noticefication
        
        // Clear form data on success
        setFormData({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });

        // Update password in parent component if needed
        if (onPasswordUpdate) {
          onPasswordUpdate(formData.newPassword);
        }
      } else {
        // Handle any other status
        setUpdateStatus({ 
          loading: false, 
          error: result.message || "Failed to update password", 
          success: false 
        });
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 
                          error.response?.data?.errors?.password?.[0] ||
                          "Failed to update password. Please try again.";
      setUpdateStatus({ loading: false, error: errorMessage, success: false });
      console.log(updateStatus.error);
    }
  };

  // the cancel button function
  const handleCancel = () => {
    setFormData({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setPasswordValidation({
      hasMinLength: false,
      hasUpperCase: false,
      hasNumberAndSpecial: false,
      passwordsMatch: false,
    });
  };

  return (
    <div className="profile-area">
      <div className="profile-container">
        <h2 className="section-title">Personal info</h2>
        <p className="section-description">
          Update your photo and personal details here.
        </p>
        <div className="avatarImage">
          <img
            src={userImage}
            alt="User"
            style={{
              borderColor: "#066755",
              borderStyle: "solid",
              borderWidth: "2px",
            }}
            className="user-image"
            draggable="false"
          />
        </div>

        <div className="form-grid">
          <InputFields
            height="24px"
            hint=""
            label="Email"
            type="disabled"
            isRequired={false}
            value={user?.email}
            isPassword={false}
          />

          <InputFields
            height="24px"
            hint=""
            label="First Name"
            type="disabled"
            isRequired={false}
            value={user?.firstName}
            isPassword={false}
          />

          <InputFields
            height="24px"
            hint=""
            label="Last Name"
            type="disabled"
            isRequired={false}
            value={user?.lastName}
            isPassword={false}
          />

          <InputFields
            height="24px"
            hint=""
            label="Phone number"
            type="disabled"
            isRequired={false}
            isPhoneNumber={true}
            value={user?.userPhoneNumber}
          />

          <InputFields
            height="24px"
            hint=""
            label="Organization"
            type="disabled"
            isRequired={false}
            value={user?.organization_name}
          />

          <InputFields
            height="24px"
            hint=""
            label="Role"
            type="disabled"
            isRequired={false}
            value={user?.Role}
          />
        </div>

        <div className="password-section">
          <h2 className="password-title">Password</h2>
          <p className="password-description">
            Please enter your current password to change your password.
          </p>

          <div className="password-form">
            <InputFields
              height="24px"
              hint=""
              label="Current password"
              name="currentPassword"
              placeholder="Please Enter current password"
              type={
                (updateStatus.error && updateStatus.error === "The provided password does not match your current password.")
                  ? "errorWithBlack"
                  : "generalWithBlack"
              }
              hasIcon={
                (updateStatus.error && updateStatus.error === "The provided password does not match your current password.")
                  ? true
                  : false
              }
              errorMessage={
                (updateStatus.error && updateStatus.error === "The provided password does not match your current password.")
                  ? "The provided password does not match your current password"
                  : ""
              }
              isPassword={true}
              isRequired={true}
              value={formData.currentPassword}
              onChange={handlePasswordChange}
              onKeyDown={(e) => handleKeyDown(e, "currentPassword")}
              inputRef={currentPasswordRef}
            />

            <div>
              <InputFields
                height="24px"
                hint=""
                label="New password"
                name="newPassword"
                placeholder="Please Enter New password"
                type={isPasswordValid ? "generalWithBlack" : "errorWithBlack"}
                hasIcon={isPasswordValid ? false : true}
                isPassword={true}
                isRequired={true}
                value={formData.newPassword}
                onChange={handlePasswordChange}
                onKeyDown={(e) => handleKeyDown(e, "newPassword")}
                inputRef={newPasswordRef}
              />
              <div className="password-requirements">
                <div
                  className={`password-requirement ${formData.newPassword ? (passwordValidation.hasMinLength ? "valid" : "notValid") : ""}`}
                >
                  Password must have at least 8 characters
                </div>
                <div
                  className={`password-requirement ${formData.newPassword ? (passwordValidation.hasUpperCase ? "valid" : "notValid") : ""}`}
                >
                  Password must have at least one upper character (A-Z)
                </div>
                <div
                  className={`password-requirement ${formData.newPassword ? (passwordValidation.hasNumberAndSpecial ? "valid" : "notValid") : ""}`}
                >
                  Password must have at least one number (0-9) and one special
                  character
                </div>
              </div>
            </div>

            <InputFields
              height="18px"
              hint={
                (updateStatus.error && updateStatus.error === "The password confirmation does not match.")
                  ? "The password confirmation does not match"
                  : "Passwords must match"
              }
              label="Confirm new password"
              name="confirmPassword"
              placeholder="Please confirm your new password"
              type={
                formData.confirmPassword &&
                passwordValidation.passwordsMatch === false
                  ? "errorWithBlack"
                  : "generalWithBlack"
              }
              hasIcon={
                formData.confirmPassword &&
                passwordValidation.passwordsMatch === false
                  ? true
                  : false
              }
              isPassword={true}
              isRequired={true}
              value={formData.confirmPassword}
              onChange={handlePasswordChange}
              onKeyDown={(e) => handleKeyDown(e, "confirmPassword")}
              inputRef={confirmPasswordRef}
            />

            {/* Display the update password was correct or not */}
            {updateStatus.success && (
              <div className="success-message">Password updated successfully</div>
            )}

            <div className="form-actions">
              <Button variant="secondary" onClick={handleCancel}>
                {" "}
                Cancel{" "}
              </Button>
              <Button variant="primary" onClick={handleUpdatePassword}>
                {" "}
                Update password{" "}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPage;
