// Module 3: Chief engineer, leader, etc use module 3 to evaluate how the program is tracking towards the next milestone
import React from "react";
import "./achieve.css";
import useAuthStore from "../../stores/useAuthStore";
import AchieveComeSoon from "../ComeSoon/AchieveComeSoon";
import AchieveDetails from "./AchieveDetails";
 
// AchieveComeSoon: the coming soon page display
// AchieveDetails: the Achieve module Details page display, including all the chart and logic
const Achieve = () => {

  const { user } = useAuthStore();

  return (
    <div>
      {user?.organizations?.[0]?.name?.toUpperCase() === "NASA" ? (
        <AchieveDetails />
      ) : (
        <AchieveComeSoon />
      )}
    </div>
  );
};

export default Achieve;
