// the overview card component used for display the overviw of user current searched issue id
import React from "react";
import PropTypes from "prop-types";
import "./OverviewCard.css";
import { useAppContext } from "../../../AppContext";

const OverviewCard = ({
    title,
    context,
    width = "520px",
    bottomText = "Astra can make mistakes—please review the information carefully.",
}) => {
    const { clickedIssue, setClickedSimilarIssue, issueDisplayArray } = useAppContext();

    // the click function to open relative issue id deatils pop window
    const handleIssueClick = (issueId) => {
        const parsedIssueId = parseInt(issueId, 10); // Convert issueId to a number for strict comparison
    
        // Find the issue in the issueDisplayArray
        const issueData = issueDisplayArray.find(issue => issue["Issue ID"] === parsedIssueId);
    
        // Check if the clicked issue matches the provided issueId
        const isClickedIssue = clickedIssue && clickedIssue["Issue ID"] === parsedIssueId;
    
        // Set the issue data or log an error
        if (issueData || isClickedIssue) {
            setClickedSimilarIssue(issueData || clickedIssue);
        } else {
            console.error(`Issue #${issueId} not found in issueDisplayArray or does not match clickedIssue.`);
        }
    };
    
    
    // the render function: make the relative issue id blue and blod
    const renderTextWithIssueLinks = (text) => {
        if (!text) return null;
        const issuePattern = /#(\d{4,})/g;
        const parts = text.split(issuePattern);
        return parts.map((part, index) => {
            if (index % 2 === 1) {
                return (
                    <span
                        key={index}
                        className="issueLink"
                        onClick={() => handleIssueClick(part)}
                    >
                        #{part}
                    </span>
                );
            }
            return <span key={index} dangerouslySetInnerHTML={{ __html: part }} />;
        });
    };

    return (
        <div
            className="OverviewCard"
            style={{ width: width }}
        >
            <div className="OverviewContext">
                <div className="overviewText"> {renderTextWithIssueLinks(context)}</div>
            </div>

            <span className="overViewBottomText">
                {bottomText}
            </span>
        </div>
    );
};

OverviewCard.propTypes = {
    title: PropTypes.string,
    context: PropTypes.string.isRequired,
    width: PropTypes.string,
    bottomText: PropTypes.string,
};

export default OverviewCard;