/* 
    this is the left area of Module 2 (Search) part: issue menu, issue item and search bar
*/
import React, { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { useAppContext } from "../../../AppContext";
import IssueDetails from "./IssueDetails";
import IssueDataGrid from "./IssueDataGrid";
import "./IssueArea.css";
import SearchIssue from "../Search Area/SearchIssue"
import { ArrowUp, ArrowDown } from "../../../Components/icons/Arrows";
import { Search, Keyboard, Info} from "../../../Components/icons/General";
import { useNavigate } from "react-router-dom";
import { ToggleSwitch, Badge, Button, NotificationComp, TabButton, OverviewCard } from "../../../Components";
import {
    DropdownMenu,
    DropdownMenuTrigger,
    DropdownMenuContent,
    DropdownMenuSeparator,
    DropdownMenuCheckboxItem,
} from "../../../Components/ui/DropdownMenu/DropdownMenu";
import SearchResultIcon from '../../../image/rocket.png';
import { useSavedIssuesQuery, useHiddenIssuesQuery, useSharedIssuesQuery, useStarredIssuesQuery } from '../../../api/queries';
import { useQueryClient } from 'react-query';
import { useDrag, useDrop } from 'react-dnd';

const EMPTY_ARRAY = [];

const IssueArea = ({searchParams}) => {
    const {
        clickedIssue,
        setIssueDisplayArray,
        //issueDisplayArray,
        issueMenu,
        setAreaArray,
        clickedSimilarIssue,
        selectStatus,
        //areaArray,
        selectedAreas,
        showsStarIssue,
        modalMessage,
        setDiscoverColumns,
        discoverColumns,
        setAllStatus,
        isSearchLoading,
        setStagesArray,
        issueRelativeArray,
        hasSearched,
        setHasSearched,
        highlightArray,
        setHighlightArray,
        //selectAllHighlight,
        setSelectAllHighlight,
        isOpenSpecification,
        setIsOpenSpecification,
        isSearchError, 
        setAllRequestor,
        selectRequestor, 
        setBasicValue, 
        tableViews, 
        setTableViews,
        setCheckedRows,
        isSharedView, 

        userShareArray, setUserSharedArray,
        userSaveArray, setUserSavedArray,
        userHiddenArray, setUserHiddenArray,
    } = useAppContext();

    const [firstInputIssue, setFirstInputIssue] = useState(""); // the first input search used for notification component search bar
    const [errorInputIssue, setErrorInputIssue] = useState(""); // the error input search used for notification component search bar
    const navigate = useNavigate(); // used for jump 

    // the keyboard feature dropdown menu
    const [isKeyBoardOpen, setIsKeyBoardOpen] = useState(false);
    const keyBoardMenuRef = useRef(null); // the ref used for auto closed of dropdown menu
    const { data: savedIssues, isLoading: isSavedIssuesLoading } = useSavedIssuesQuery();
    const { data: hiddenIssues, isLoading: isHiddenIssuesLoading } = useHiddenIssuesQuery();
    const { data: sharedIssues, isLoading: isLoadingShared } = useSharedIssuesQuery();
    const { data: starredIssues, isLoading: isStarredIssuesLoading } = useStarredIssuesQuery();
    const queryClient = useQueryClient();

    // Memoize savedIssues to prevent unnecessary re-renders
    const savedIssuesMemo = useMemo(() => savedIssues || EMPTY_ARRAY, [savedIssues]);
    const hiddenIssuesMemo = useMemo(() => hiddenIssues || EMPTY_ARRAY, [hiddenIssues]);
    const sharedIssuesMemo = useMemo(() => sharedIssues || EMPTY_ARRAY, [sharedIssues]);
    const starredIssuesMemo = useMemo(() => starredIssues || EMPTY_ARRAY, [starredIssues]);

    // the overview of current issue id open or closed
    const [showOverview, setShowOverview] = useState(false);

    // the state used for customize table search function 
    const [inputSearchColumn, setInputSearchColumn] = useState("");

    

    useEffect(() => {
        let filteredIssues = [];
        
        // First get the base set of issues depending on the view
        if (tableViews === "Saved") {
            if(!isSavedIssuesLoading && savedIssuesMemo && savedIssuesMemo !== userSaveArray){
                setUserSavedArray(savedIssuesMemo)
            }
            // filteredIssues = !isSavedIssuesLoading && savedIssuesMemo ? savedIssuesMemo : [];
        } else if (tableViews === "Hidden") {
            if(!isHiddenIssuesLoading && hiddenIssuesMemo && hiddenIssuesMemo !== userHiddenArray){
                setUserHiddenArray(hiddenIssuesMemo)
            }
            // filteredIssues = !isHiddenIssuesLoading && hiddenIssuesMemo ? hiddenIssuesMemo : [];
        } else if (tableViews === "Shared") {
            if(!isLoadingShared && sharedIssuesMemo && sharedIssuesMemo !== userShareArray){
                setUserSharedArray(sharedIssuesMemo)
            }
        } else if (tableViews === "Starred") {
            filteredIssues = !isStarredIssuesLoading && starredIssuesMemo ? starredIssuesMemo : [];
        } else {
            // Create a Set of starred issue IDs
            const starredIssueIds = new Set(
                !isStarredIssuesLoading && starredIssuesMemo 
                    ? starredIssuesMemo.map(issue => issue["Issue ID"]) 
                    : []
            );
            
            // Map over issueRelativeArray and set is_favorite based on presence in starredIssueIds
            filteredIssues = issueRelativeArray.map(issue => ({
                ...issue,
                is_favorite: starredIssueIds.has(issue["Issue ID"])
            }));
        }

        // Then apply all filters, including stage filter, regardless of view
        // Filter by issueMenu (phases)
        if (issueMenu !== "All") {
            filteredIssues = filteredIssues.filter(
                (item) => item.Phase === issueMenu
            );
        }

        // Apply other existing filters
        if (selectedAreas.length !== 0) {
            filteredIssues = filteredIssues.filter((issue) => {
                const currentAreaList = issue.AreaList || issue.Area.split(', ').map(a => a.trim());
                return selectedAreas.some((area) => currentAreaList.includes(area)); // ==> changed the column system filter from AND to OR
            });
        }

        if (selectStatus.length !== 0) {
            filteredIssues = filteredIssues.filter((issue) =>
                selectStatus.includes(issue.Status)
            );
        }

        if (selectRequestor.length !== 0) {
            filteredIssues = filteredIssues.filter((issue) =>
                selectRequestor.includes(issue.Requestor)
            );
        }

        if (showsStarIssue === true) {
            filteredIssues = filteredIssues.filter((issue) => issue.is_favorite);
        }
        setIssueDisplayArray(filteredIssues);
    }, [
        issueMenu,
        selectedAreas,
        selectStatus,
        selectRequestor,
        showsStarIssue,
        issueRelativeArray,
        setIssueDisplayArray,
        tableViews,
        savedIssuesMemo,
        isSavedIssuesLoading,
        hiddenIssues,
        isHiddenIssuesLoading,
        hiddenIssuesMemo,
        sharedIssuesMemo,
        isLoadingShared,
        starredIssuesMemo,
        isStarredIssuesLoading,
        userShareArray,
    ]);

    /**************************************  1. the highlight drop list about words hightlight: ************************************* */
    // select and unselect one highlight item
    const toggleHighlight = (e, key) => {
        e.preventDefault();
        setHighlightArray((prevState) => {
            const newState = {
                ...prevState,
                [key]: !prevState[key],
            };
            // update the main tittle checkbox status based on user selection
            setSelectAllHighlight(Object.values(newState).every(Boolean));
            return newState;
        });
    };

    // the checkBox in the left of highlight, could select all the highlight item
    const handleSelectAll = (e) => {
        e.preventDefault();
        const newSelectAll = true;
        // update the main tittle checkBox
        setSelectAllHighlight(newSelectAll);

        // update all the status of 4 highlight item
        setHighlightArray((prevState) => {
            const newState = {};
            Object.keys(prevState).forEach((key) => {
                newState[key] = newSelectAll;
            });
            return newState;
        });
    };

    //  the checkBox in the left of highlight, click to unSelect all the highlight item
    const handleUnSelectAll = (e) => {
        e.preventDefault();
        setSelectAllHighlight(false);
        // update all the status of 4 highlight item
        setHighlightArray((prevState) => {
            const newState = {};
            Object.keys(prevState).forEach((key) => {
                newState[key] = false;
            });
            return newState;
        });
    };

    // Function: check how many selection item of highlight dropmenu was selected, and the checkbox "Add All" display checked or interminate
    const getSelectionHiglight = (selectedArr) => {
        const checkedCount = Object.values(selectedArr).filter((value) => value === true).length;
        return {
            checkedHighlight: checkedCount > 0 && checkedCount <= Object.keys(selectedArr).length,
            indeterminateHighlight: checkedCount > 0 && checkedCount < Object.keys(selectedArr).length,
        };
    };
    const { checkedHighlight, indeterminateHighlight } = getSelectionHiglight(highlightArray); 

    //Based on the issueRelativeArray(user input issue similar array) change to get the stages and area
    useEffect(() => {
        if (issueRelativeArray.length > 0) {
            //get all the area option of dropdown list
            const areaArr = [
                ...new Set(
                    issueRelativeArray.flatMap((issue) => issue.AreaList).sort(),
                ),
            ];
            setAreaArray(areaArr);

            //get all the status option of dropdown list
            const statusArr = [
                ...new Set(issueRelativeArray.map((issue) => issue.Status).sort()),
            ];
            setAllStatus(statusArr);

            //get all the statges option of issue format menu
            const stagesArr = [
                "All",
                ...new Set(issueRelativeArray.map((item) => item.Phase).sort()),
            ];
            setStagesArray(stagesArr);

            //get all the reporter name of issue format table
            const reporterName = [
                ...new Set(issueRelativeArray.map((issue) => issue.Requestor).filter((item) => item !== "").sort()),
            ]
            setAllRequestor(reporterName);
        }
    }, [issueRelativeArray, setStagesArray, setAreaArray, setAllStatus, setAllRequestor]);



    /**************************************  5. first search input onchange: ************************************* */
    const handleInputIssue = useCallback(
        (e) => {
            if (e && e.target) {
                const value = e.target.value;
                setFirstInputIssue(value);
            }
        },
        [setFirstInputIssue],
    );

    // three function used for the first search notification component
    // based on user input first search, jump and search similar issue ==> button
    const handleFirstSearch = (e) => {
        e.preventDefault();
        if (hasSearched === false) {
            setHasSearched(true);
            localStorage.setItem("hasSearched", hasSearched);
        }
        navigate(`/discover?query=${encodeURIComponent(firstInputIssue)}`);
        setBasicValue((prevTerms) => [...prevTerms,
            {
                id: firstInputIssue,
                value: firstInputIssue,
                isIssueId: !isNaN(Number(firstInputIssue)),
                fieldKey: 'query',
            },
        ]);
    }

    // based on user input first search, jump and search similar issue ==> keyboard
    const handleFirstKeyBoardSearch = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (hasSearched === false) {
                setHasSearched(true);
                localStorage.setItem("hasSearched", hasSearched);
            }
            navigate(`/discover?query=${encodeURIComponent(firstInputIssue)}`);
            setBasicValue((prevTerms) => [...prevTerms,
                {
                    id: firstInputIssue,
                    value: firstInputIssue,
                    isIssueId: !isNaN(Number(firstInputIssue)),
                    fieldKey: 'query',
                },
            ]);
        }
    }

    // check and judge whether user is first search or not
    useEffect(() => {
        const hasSearchedLocal = localStorage.getItem("hasSearched"); //user have searched once
        if (hasSearchedLocal) {
            setHasSearched(true);
        }
    }, [setHasSearched]);


    // three function used for the error Search notification component
    const handleErrorInputIssue = useCallback(
        (e) => {
            if (e && e.target) {
                const value = e.target.value;
                setErrorInputIssue(value);
            }
        },
        [setErrorInputIssue],
    );

    const handleErrorSearch = (e) => {
        e.preventDefault();
        setBasicValue([]);
        navigate(`/discover?query=${encodeURIComponent(errorInputIssue)}`);
        setBasicValue((prevTerms) => [...prevTerms,
            {
                id: errorInputIssue,
                value: errorInputIssue,
                isIssueId: !isNaN(Number(errorInputIssue)),
                fieldKey: 'query',
            },
        ]);
    }

    // based on user input first search, jump and search similar issue ==> keyboard
    const handleErrorKeyBoardSearch = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setBasicValue([]);
            navigate(`/discover?query=${encodeURIComponent(errorInputIssue)}`);
            setBasicValue((prevTerms) => [...prevTerms,
                {
                    id: errorInputIssue,
                    value: errorInputIssue,
                    isIssueId: !isNaN(Number(errorInputIssue)),
                    fieldKey: 'query',
                },
            ]);
        }
    }

    // the search animation effect dots numbers
    const dots = Array.from({ length: 16 });

    /**************************************  2. Key specification and the Considerations tab button function: ************************************* */
    // open the right area information: key specification and the Considerations
    const handleOpenSpecification = useCallback(() => {
        setIsOpenSpecification(prevState => {
            const newState = !prevState;
            localStorage.setItem("savedOpenKeySpec", newState);
            return newState;
        });
    }, [setIsOpenSpecification]);


    /**************************************  3. The keyboard dropdown menu shows all the keybaord feature: ************************************* */
    // the function: open and closed dropdown menu
    const handleOpenKeyboardMenu = () => {
        setIsKeyBoardOpen(!isKeyBoardOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (keyBoardMenuRef.current && !keyBoardMenuRef.current.contains(event.target)) {
                setIsKeyBoardOpen(false);
            }
        };

        document.addEventListener("pointerdown", handleClickOutside);
        return () => document.removeEventListener("pointerdown", handleClickOutside);
    }, []);


    /**************************************  4. The Customize Table Function Part: ************************************* */
    // Handle column selection change, save the user selected the column to display
    // handle column function
    const handleColumnChange = (e, column) => {
        e.preventDefault();
        setDiscoverColumns((prev) => {
            const newColumns = prev.map((col) => {
                if (col.field === column) {
                    return { ...col, hide: !col.hide };
                }
                return col;
            });
            localStorage.setItem("discoverColumns", JSON.stringify(newColumns)); // saved it into localStorge
            return newColumns;
        });
    };

    // add all the column 
    const handleSelectAllColumn = (e) => {
        e.preventDefault();
        setDiscoverColumns((prev) => {
            const newColumns = prev.map((col) => ({ ...col, hide: false })).sort((a, b) => a.index - b.index);
            localStorage.setItem("discoverColumns", JSON.stringify(newColumns));  // saved it into localStorge
            return newColumns;
        });
    };

    // unselected all the column
    const handleUnSelectAllColumn = (e) => {
        e.preventDefault();
        setDiscoverColumns((prev) => {
            const newColumns = prev.map((col) => ({ ...col, hide: true }));
            localStorage.setItem("discoverColumns", JSON.stringify(newColumns));  // saved it into localStorge
            return newColumns;
        });
    };

    // listerner the user selected change, than saved it into our localstroge 
    useEffect(() => {
        localStorage.setItem("discoverColumns", JSON.stringify(discoverColumns));
    }, [discoverColumns]);


    // Customzie tabl dropdown menu: check how many columns selected ==> whether it equals the all the columns
    const filteredColumns = discoverColumns.filter((column) =>
        inputSearchColumn
            ? column.field.toLowerCase().includes(inputSearchColumn.toLowerCase())
            : true
    );

   // used for customize table dropdown menu "Add All" Checkbox display, it will count how many columns added it
    const getSelectionState = (filteredColumns) => {
        const selectedCount = filteredColumns.filter((column) => !column.hide).length;
        const totalCount = filteredColumns.length;
        return {
            checked: selectedCount === totalCount,
            indeterminate: selectedCount > 0 && selectedCount < totalCount,
        };
    };

    const { checked, indeterminate } = getSelectionState(filteredColumns);


    /**************************************  4. The Customize Table dragging item to be column part : ************************************* */
    // the react-dnd function: select whcih item was user selected to dragging into the issue table column
    const DraggableColumnItem = ({ column, onAddColumn, index, moveItem }) => {
        const [{ isDragging }, drag] = useDrag({
            type: 'COLUMN',
            item: { type: 'COLUMN', column: column },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const dropResult = monitor.getDropResult();
                if (dropResult && dropResult.targetField) {
                    onAddColumn(item.column, dropResult.targetField);
                } else {
                    onAddColumn(item.column, null);
                }
            },
        });
    
        const [, menuDrag, menuPreview] = useDrag({
            type: 'COLUMN_REORDER',
            item: { type: 'COLUMN_REORDER', index, column },
            collect: (monitor) => ({
                isDraggingInMenu: monitor.isDragging(),
            }),
        });
    
        const [{ isOver }, drop] = useDrop({
            accept: 'COLUMN_REORDER',
            collect: (monitor) => ({
                isOver: monitor.isOver(),
            }),
            drop: (draggedItem) => {
                if (draggedItem.index !== index) {
                    moveItem(draggedItem.index, index);
                }
            },
        });
    
        const combinedRef = (el) => {
            menuDrag(el);
            drop(el);
            menuPreview(el);
        };
    
        const contentRef = (el) => {
            drag(el);
        };
    
        return (
            // <div
            //     ref={combinedRef}
            //     style={{
            //         opacity: isDragging ? 0.5 : 1,
            //         borderTop: isOver ? "1px solid #667085" : "1px solid transparent",
            //         display: 'flex',
            //         alignItems: 'center',
            //         cursor: 'move',
            //     }}
            // >
            //     <div className="drag-handle" style={{ marginRight: '6px', cursor: 'move' }}>
            //         ≡
            //     </div>
            //     <div
            //         ref={contentRef}
            //         className="drag-handle"
            //         style={{
            //             flex: 1,
            //             cursor: 'move',
            //         }}
            //     >
            //         {column}
            //     </div>
            // </div>

            <div
                ref={combinedRef}
                style={{
                    opacity: isDragging ? 0.5 : 1,
                    borderTop: isOver ? "2px solid #667085" : "2px solid transparent",
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'move',
                    width: "100%",
                    height: "28px",
                }}
            >
                {column}
            </div>
        );
    };
    
    // used for add the column into our issue table
    const handleAddColumn = (columnName, targetField) => {
        const isColumnExists = discoverColumns.some(
            (col) => (col.field === columnName || col.headerName === columnName) && !col.hide
        );
    
        if (!isColumnExists) {
            setDiscoverColumns((prevColumns) => {
                // find the target column index value
                const targetIndex = prevColumns.findIndex((col) => col.field === targetField);
                const columnIndex = prevColumns.findIndex((col) => col.field === columnName);
    
                if (columnIndex !== -1) {
                    // update the current column index ==> used to display in the user drop place
                    const updatedColumns = prevColumns.map((col) => {
                        if (col.field === columnName) {
                            return { ...col, hide: false }; 
                        }
                        return col;
                    });
    
                    // Update the index property of the column: the index of the current column becomes targetIndex, 
                    // and the index of subsequent columns increases by 1
                    const updatedWithIndex = updatedColumns.map((col, idx) => {
                        if (idx === columnIndex) {
                            return { ...col, index: targetIndex + 1}; //Update the index property of the column:
                        } else if (col.index >= targetIndex) {
                            return { ...col, index: col.index + 1 }; // the index of subsequent columns increases by 1
                        }
                        return col;
                    });
    
                    return updatedWithIndex;
                }
                return prevColumns; // If the column does not exist, the original data is returned.
            });
        } else {
            console.warn(`Column ${columnName} already exists, skipping.`);
        }
    };
    
    
    // Functions for moving columns and change sequences
    const moveItem = (fromIndex, toIndex) => {
        if(fromIndex === toIndex){
            return;
        }
        
        setDiscoverColumns(prevColumns => {
            // Copy the original column data
            const result = [...prevColumns];
            
            // Delete the column at the specified position
            const [removed] = result.splice(fromIndex, 1);
            
            // Insert at a new position
            result.splice(toIndex, 0, removed);
            
            //Update the index property of each column to ensure that the index order is continuous
            result.forEach((column, index) => {
                column.index = index; // Update the index according to the new order
            });
            
            return result;
        });
    };
    
    // the table button: All Starred Saved Shared and Hidden
    const tableChange = useCallback(
        (e) => {
            setCheckedRows(new Set())
            const selectedTab = e.target.value;
            setTableViews(selectedTab);

            if (selectedTab === 'Saved') {
                queryClient.invalidateQueries('savedIssues');
            } else if (selectedTab === 'Hidden') {
                queryClient.invalidateQueries('hiddenIssues');
            } else if (selectedTab === 'Shared') {
                queryClient.invalidateQueries('sharedIssues');
            }
        },
        // eslint-disable-next-line
        [setTableViews, queryClient]
    );

    // main render Area with HTML Tag
    return (
        <div className="issue-area"
            style={{
                width: isOpenSpecification ? 'calc(68vw - 25px)' : '100%',
                transition: 'width 0.5s ease',
            }}
        >
            {!isSharedView && <SearchIssue/>}

            {!isSharedView && (
                <div className="controlArea" style={{position: "relative"}}>
                    {
                        issueRelativeArray.length > 0 ? <div className="searchResultMessage">
                            <img src={SearchResultIcon}
                                alt="SearchResultIcon"
                                style={{ height: "20px", width: "20px"}}
                            />
                            {issueRelativeArray.length} similar issues found

                            {(clickedIssue && clickedIssue["Overview"]) && <button className={`overviewButton ${showOverview ? "isactive" : ""}`} onClick={() => setShowOverview(!showOverview)}>
                                <Info size={24} color="#667085" />
                            </button>}
                        </div> : <p></p>
                    }

                    <div style={{ display: "flex" }}>
                        <DropdownMenu>
                            <DropdownMenuTrigger height="32px">
                                <span className="dropdown-menu-trigger-text" style={{fontSize: "12px"}}>Highlights</span>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                                <DropdownMenuCheckboxItem
                                    checked={checkedHighlight}
                                    indeterminate={indeterminateHighlight}
                                    onClick={handleSelectAll}
                                >
                                    Select all
                                </DropdownMenuCheckboxItem>
                                <DropdownMenuSeparator />
                                <DropdownMenuCheckboxItem checked={highlightArray["Failure Mode"]} onClick={(e) => toggleHighlight(e, "Failure Mode")}>
                                    <Badge type="highlight" variant="failure-mode">
                                        Failure Mode
                                    </Badge>
                                </DropdownMenuCheckboxItem>
                                <DropdownMenuCheckboxItem checked={highlightArray["Components"]} onClick={(e) => toggleHighlight(e, "Components")}>
                                    <Badge type="highlight" variant="components">
                                        Components
                                    </Badge>
                                </DropdownMenuCheckboxItem>
                                <DropdownMenuCheckboxItem checked={highlightArray["Failure Condition"]} onClick={(e) => toggleHighlight(e, "Failure Condition")}>
                                    <Badge type="highlight" variant="failure-condition">
                                        Failure Condition
                                    </Badge>
                                </DropdownMenuCheckboxItem>
                                <DropdownMenuCheckboxItem checked={highlightArray["After Effects"]} onClick={(e) => toggleHighlight(e, "After Effects")} >
                                    <Badge type="highlight" variant="after-effects">
                                        After Effects
                                    </Badge>
                                </DropdownMenuCheckboxItem>
                                <DropdownMenuCheckboxItem checked={highlightArray["Specification"]} onClick={(e) => toggleHighlight(e, "Specification")} >
                                    <Badge type="highlight" variant="specifications">
                                        Specification
                                    </Badge>
                                </DropdownMenuCheckboxItem>
                                <DropdownMenuSeparator />

                                <div style={{ height: "32px", marginLeft: "2px" }}>
                                    <Button variant="danger-link" size="small" onClick={handleUnSelectAll}>
                                        Clear all
                                    </Button>
                                </div>
                            </DropdownMenuContent>
                        </DropdownMenu>

                        <div className="ToggleButton" onClick={handleOpenSpecification} style={{ cursor: 'pointer' }}>
                            <span className="titleToggle">Key Specifications</span>
                            <ToggleSwitch 
                                isOpen={isOpenSpecification} 
                                onToggle={(e) => {
                                    e.stopPropagation();
                                    handleOpenSpecification();
                                }} 
                                width="36px" 
                                height="18px" 
                            />
                        </div>
                    </div>

                    { showOverview && <OverviewCard context={clickedIssue["Overview"]}/>}
                </div>
            )}

             

            {!isSharedView && (
                <div className="issueItemArea">
                    <div className="ViewButtonArea">
                        <TabButton label={"All"} value={"All"} variant={"white"} isActive={tableViews === "All" ? true : false}  height="22px" onClick={(e) => tableChange(e)} />
                        {/* <TabButton label={"Starred"} value={"Starred"} variant={"white"} isActive={tableViews === "Starred" ? true : false}  height="22px" onClick={(e) => tableChange(e)} /> */}
                        <TabButton label={"Saved"} value={"Saved"} variant={"white"} isActive={tableViews === "Saved" ? true : false}  height="22px" onClick={(e) => tableChange(e)} />
                        <TabButton label={"Shared"} value={"Shared"} variant={"white"} isActive={tableViews === "Shared" ? true : false}  height="22px" onClick={(e) => tableChange(e)} />
                        <TabButton label={"Hidden"} value={"Hidden"} variant={"white"} isActive={tableViews === "Hidden" ? true : false}  height="22px" onClick={(e) => tableChange(e)} />
                    </div>

                    <div className="rightButtonArea">
                        {/* Used the slider to control every colums */}

                        {/* the keyboard shows feature */}
                        <div className="keyBoardButton" ref={keyBoardMenuRef} onClick={handleOpenKeyboardMenu}>
                            <Keyboard />
                        </div>
                        <div className={`keyboardDropdownMenu ${isKeyBoardOpen ? "show" : ""}`}>
                            <div className="featureItem"> Move up  <div className="KeyBoardIcon"> <ArrowUp size={12} color="#667085" /> </div> </div>
                            <div className="featureItem"> Move Down  <div className="KeyBoardIcon"> <ArrowDown size={12} color="#667085" /> </div> </div>
                            <div className="featureItem"> Mark selected row checkbox <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> space bar </div> </div>
                            <div className="featureItem"> Selected all the rows checkbox <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> Ctrl+A </div> </div>
                            <div className="featureItem"> UnSelected all the rows checkbox <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> Ctrl+D </div> </div>
                            <div className="featureItem"> Open selected row details window  <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> Enter </div> </div>
                            <div className="featureItem"> Mark current row as favorite <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> Ctrl+S </div> </div>
                            <div className="featureItem"> Move Five Rows up  <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> PageUp </div> </div>
                            <div className="featureItem"> Move Five Rows Down  <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> PageDown </div> </div>
                            <div className="featureItem"> Move to First Issue  <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> Home </div> </div>
                            <div className="featureItem"> Move to Last Issue  <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> End </div> </div>
                        </div>

                        {/* the customize table button: the dropdown menu to add the columns of issue table */}
                        <DropdownMenu>
                            <DropdownMenuTrigger height="32px">
                                <span className="dropdown-menu-trigger-text" style={{ fontSize: "12px" }}>Customize Table</span>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                                <div className="columnSearchArea">
                                    <Search size={16} color="#667085" />
                                    <input
                                        type="text"
                                        placeholder="Search columns"
                                        onChange={(e) => setInputSearchColumn(e.target.value)}
                                        value={inputSearchColumn}
                                        onClick={(e) => e.stopPropagation()} // stop the click event
                                        onKeyDown={(e) => e.stopPropagation()} // stop the keyboard event
                                        style={{ border: "none", outline: "none", width: "100%", fontSize: "12px"}}
                                    />
                                </div>

                                <DropdownMenuSeparator />
                                <DropdownMenuCheckboxItem
                                    checked={checked}
                                    onSelect={handleSelectAllColumn}
                                    indeterminate={indeterminate}
                                >
                                    Add all
                                </DropdownMenuCheckboxItem>
                                <DropdownMenuSeparator />

                                <div className="cutomizeDropDownMenu">
                                    {(() => {
                                        return discoverColumns
                                            .sort((a, b) => a.index - b.index) // sort in the sequnece (index)
                                            .map((column) => ({
                                                column,
                                                selectedIndex: discoverColumns.findIndex(item => item.field === column.field),
                                            }))
                                            .filter(({ column }) =>
                                                inputSearchColumn
                                                    ? column.headerName.toLowerCase().includes(inputSearchColumn.toLowerCase()) // 修正 filter 逻辑
                                                    : true
                                            )
                                            .map(({ column }) => (
                                                <DropdownMenuCheckboxItem
                                                    key={column.field}
                                                    checked={!column.hide}
                                                    onClick={(e) => handleColumnChange(e, column.field)}
                                                >
                                                    <DraggableColumnItem
                                                        column={column.headerName}
                                                        index={column.index}
                                                        moveItem={moveItem}
                                                        onAddColumn={handleAddColumn}
                                                    >
                                                        {column.headerName}
                                                    </DraggableColumnItem>
                                                </DropdownMenuCheckboxItem>
                                            ));
                                    })()}
                                </div>
                                <DropdownMenuSeparator />
                                <div style={{ height: "32px", marginLeft: "2px" }}>
                                    <Button variant="danger-link" size="small" onClick={handleUnSelectAllColumn}>
                                        Clear all
                                    </Button>
                                </div>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                </div>
            )}

            {/* The Issue Data Table: Display all the issue datas */}
            <div className="issue-areaFormat">
                {(isSearchLoading ||
                    (tableViews === "Saved" && isSavedIssuesLoading) ||
                    (tableViews === "Hidden" && isHiddenIssuesLoading) ||
                    (tableViews === "Shared" && isLoadingShared) ||
                    (tableViews === "Starred" && isStarredIssuesLoading)) ? (
                    <div className="fade-in">
                        <div className="dots-loader">
                            {dots.map((_, index) => (
                                <div
                                    key={index}
                                    className="dot"
                                    style={{
                                        transform: `rotate(${index * 22.5}deg) translateY(-20px)`,
                                        animationDelay: `${index * 0.125}s`
                                    }}
                                />
                            ))}
                        </div>
                        <p style={{ marginLeft: "8px" }}>
                            {isSearchLoading ? "Astra is Searching for Relative Issue..." :
                                (tableViews === "Saved" && isSavedIssuesLoading) ? "Loading saved issues..." :
                                (tableViews === "Hidden" && isHiddenIssuesLoading) ? "Loading hidden issues..." :
                                (tableViews === "Starred" && isStarredIssuesLoading) ? "Loading starred issues..." :
                                "Loading shared issues..."}
                        </p>
                    </div>
                    ) : isSharedView ? ( 
                        <IssueDataGrid tableViews={tableViews} />
                    ) : (
                        tableViews === 'Saved' || tableViews === 'Hidden' || 
                        tableViews === 'Shared' || tableViews === 'Starred' ? (
                            
                            <IssueDataGrid tableViews={tableViews} />

                        ) : hasSearched === false ? (
                            <div className="search-input-area">
                                <NotificationComp
                                    errorTitle="Search for your first issue here"
                                    onChange={handleInputIssue}
                                    onKeyDown={handleFirstKeyBoardSearch}
                                    onClick={(e) => handleFirstSearch(e)}
                                    Icon={Search}
                                />
                            </div>
                        ) : isSearchError ? (
                            <div className="search-input-area">
                                <NotificationComp
                                    errorTitle="There was a problem processing your request..."
                                    errorText={modalMessage}
                                    hasSearchBar={true}
                                    contactTitle="Contact us"
                                    contactText="We're here to help."
                                    contactEmail="Support@apiphany.ai"
                                    Icon={Search}
                                    onChange={handleErrorInputIssue}
                                    onClick={(e) => handleErrorSearch(e)}
                                    onKeyDown={handleErrorKeyBoardSearch}
                                />
                            </div>
                        ) : ([...searchParams.keys()].length === 0 && issueRelativeArray.length === 0) ?(
                            <div className="search-input-area">
                                <NotificationComp
                                    errorTitle="Enter issue numbers or keywords in the 'Discover Occurrences' field to start your search adventure!"
                                    hasSearchBar={false}
                                    Icon={ArrowUp}
                                />
                            </div>
                        ) : ([...searchParams.keys()].length > 0 && issueRelativeArray.length === 0) ? (
                            <div className="search-input-area">
                                <NotificationComp
                                    errorTitle="There was a problem processing your request..."
                                    errorText="Astra could not find any relative issues below your selected tags"
                                    hasSearchBar={false}
                                    Icon={Search}
                                    contactTitle="Contact us"
                                    contactText="We're here to help."
                                    contactEmail="Support@apiphany.ai"
                                />
                            </div>
                        ) : (
                            <IssueDataGrid tableViews={tableViews} />
                        )
                    )}
            </div>

            {/* open the relative issue details pop window */}
            {clickedSimilarIssue !== null && <IssueDetails />}


        </div>
    );
};

export default IssueArea;
