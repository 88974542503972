// Format Table: used for our Achieve mdoule Trends page shows data
import React, { useState } from "react";
import './FormatTable.css';
import PropTypes from "prop-types";
import { ChevronLeft, ChevronRight } from "../../icons/Arrows";
import SwitchButton from "../../keySpecs/SwitchImageButton/SwitchButton";
import Button from "../../ui/Button/Button";

const FormatTable = ({
    width,
    height,
    data,
    columns,
    rowHeight,
    setOpenDetailsWindow, // control open which level popwindow or closed
    setClickProduct, // the product that user clicked it
    setClickFailureMode,
    currentLevel,
}) => {
    const [currPage, setCurrPage] = useState(1);
    const [rowPage, setRowPage] = useState(25); // control how many rows in one page
    const maxResult = Object.keys(data).length;
    const [hoveredRow, setHoveredRow] = useState(null);

    const startItem = (currPage - 1) * rowPage;
    const endItem = Math.min(currPage * rowPage, maxResult);

    const handleClickProduct = (rowIndex) => {
        if (currentLevel !== "product" && currentLevel !== "Failure Mode") {
            setOpenDetailsWindow("product"); // open the product details window
            setClickProduct(Object.values(data)[rowIndex]); // set product data
        } else if(currentLevel === "product")  {
            setOpenDetailsWindow("Failure Mode"); // open the Failure Mode details window
            setClickFailureMode(Object.values(data)[rowIndex]); // set failure mode data
        }
    };

    // switch to next page
    const handleNextPage = () => {
        setCurrPage(currPage => currPage + 1);
    }

    // swicth to pervious page
    const handlePrevPage = () => {
        setCurrPage(currPage => currPage - 1);
    }

    return (
        <div className="achieveFormat-table" style={{ minWidth: width, maxWidth: width, minHeight: height, maxHeight: height }}>
            {/* Table Header */}
            <div className="achieveTable-header">
                {columns.map((col) => (
                    <div
                        key={col.key}
                        className="formatHeader-cell"
                        style={{ minWidth: col["width"], maxWidth: col["width"] }}
                    >
                        {col.label}
                    </div>
                ))}
            </div>

            {/* Table Body */}
            <div className="achieveTable-body">
                {Object.values(data).slice(startItem, endItem).map((row, rowIndex) => (
                    <div
                        key={rowIndex}
                        className={`achieveTable-row ${rowIndex % 2 === 0 ? "even" : "odd"}`}
                        style={{ height: rowHeight, position: "relative" }}
                        onMouseEnter={() => setHoveredRow(rowIndex)}
                        onMouseLeave={() => setHoveredRow(null)}
                    >
                        {columns.map((col) => (
                            <div
                                key={col.key}
                                className="formatTable-cell"
                                style={{ minWidth: col["width"], maxWidth: col["width"] }}
                            >
                               <span>{col.render ? col.render(row[col.key], row) : String(row[col.key])}</span> 
                            </div>
                        ))}

                        {/* the right side deatils button */}
                        {hoveredRow === rowIndex && (
                            <Button
                                variant="secondary"
                                size="xsmall"
                                onClick={() => handleClickProduct(rowIndex)}
                                style={{position: "absolute", top: "50%", right: "10px",  transform: "translateY(-50%)",}}
                            >
                                Inspect
                            </Button>
                        )}
                    </div>
                ))}
            </div>

            {/* Table Footer */}
            <div className="achieveTable-footer">
                <div className="achieveFooter-left">
                    {startItem} - {endItem} of {maxResult} Ressult
                </div>
                <div className="achievFooter-right">
                    <SwitchButton
                        Icon={ChevronLeft}
                        onClick={handlePrevPage}
                        shape="circle"
                        disable={startItem <= 1}
                    />
                    <SwitchButton
                        Icon={ChevronRight}
                        onClick={handleNextPage}
                        shape="circle"
                        disable={endItem >= maxResult}
                    />
                </div>
            </div>
        </div>
    );
};

FormatTable.propTypes = {
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            width: PropTypes.string.isRequired,
        })
    ).isRequired,
    rowHeight: PropTypes.string.isRequired,
    setOpenDetailsWindow: PropTypes.func,
    setClickFailureMode: PropTypes.func,
    setClickProduct: PropTypes.func,
    currentLevel: PropTypes.string.isRequired,
};

export default FormatTable;
