/* this search filed using for Ahcieve search */
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import "./AchieveSearch.css";
import { Search } from "../../icons/General";
import { Badge } from "../../ui/Badge";

const AchieveSearch = ({
  width = "350px",
  height = "34px",
  options = {}, // { key: { label: "value" } }
  selectedProduct, 
  onSearch,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [labelBadge, setLabelBadge] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(Object.entries(options));
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const searchRef = useRef(null);

  const textRef = useRef(null); // Used to get the text width
  const inputRef = useRef(null); // Used to modify the width of the input box

  // Listen to inputValue changes and dynamically adjust the input box width
  useEffect(() => {
    if (textRef.current && inputRef.current) {
      const textWidth = textRef.current.offsetWidth;
      inputRef.current.style.width = labelBadge ? `${textWidth}px` : "180px";
    }
  }, [inputValue, labelBadge]);
  
  // Handle input box changes
  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value === "" || value !== selectedProduct?.value) {
      setLabelBadge("");
    }
    setInputValue(value);

    // Filter matching items
    const matches = Object.entries(options).filter(([key]) =>
      key.toLowerCase().startsWith(value.toLowerCase())
    );
    setFilteredOptions(matches);
    setDropdownVisible(matches.length > 0);
  };

  // Process the selected drop-down item
  const handleSelectItem = (value, label) => {
    setInputValue(value);
    setLabelBadge(label);
    onSearch({ value, label });
    setDropdownVisible(false);

    // the function, passed user selected product to parents level
    if (onSearch) onSearch({ value, label });
  };

  // Trigger search callback
  const getColorVersion = (label) => {
    switch (label) {
      case "System":
        return "category-green";
      case "Component":
        return "category-yellow";
      case "Assembly":
        return "category-purple";
      default:
        return "category-gray";
    }
  };

  return (
    <>
      {/* Search Bar with Icon, word text, and label */}
      <div className={`search-box ${inputValue ? "isSearched" : ""}`} style={{ height: height, minWidth: width }} ref={searchRef}>
        <Search size={16} color="#667085" />
        
        {/* the hide text used to count the text width*/}
        <span ref={textRef} className="hidden-text">
          {inputValue || " "}
        </span>

        {/* The input area */}
        <input
          type="text"
          placeholder="Search product"
          ref={inputRef}
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => setDropdownVisible(filteredOptions.length > 0 && !labelBadge)}
          onBlur={() => setTimeout(() => setDropdownVisible(false), 200)}
        />

        {/* the selected badge */}
        {inputValue !== "" && labelBadge !== "" && (
          <Badge type="category" variant={getColorVersion(labelBadge)}>
            {labelBadge}
          </Badge>
        )}

        {/* the search field dropdown menu */}
        {dropdownVisible && (
          <div className="achieveSearchDropdown">
            {filteredOptions.map(([key, value]) => (
              <div key={key} className="achieveSearchDropdown-item" onClick={() => handleSelectItem(key, value.label)}>
                {key} <Badge type="category" variant={getColorVersion(value.label)}>{value.label}</Badge>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

AchieveSearch.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  options: PropTypes.objectOf(PropTypes.object), // { "Value 1":{label: "Label 1"} }
  selectedProduct: PropTypes.object,
  onSearch: PropTypes.func,
};

export default AchieveSearch;
