import { useMutation, useQuery, useQueryClient } from "react-query";
import {api, loginApi} from "./config";
import useAuthStore from "../stores/useAuthStore";
import { mapIssueFields } from '../config/issueMapping';
import toast from 'react-hot-toast';

export const fetchUser = async (token) => {
  const response = await api.get('/users/me', {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const postUser = async ({ token, userData }) => {
  const response = await api.post('/users', userData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};


// the all the issue Id ==> call API
export const useAllIssueID = () => {
  return useQuery('allIssueIds', async () => {
    const token = useAuthStore.getState().token;
    const response = await api.get('/issues/id', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });  
    return response.data;
  });
};

// the search relative issue api and get the key specification area data
export const useSearchMutation = () => {
  return useMutation(
    async ({query, statusArr, systemArr, requestorArr, keywordsArr}) => {
    if (!query) {
      throw new Error('Issue ID is required');
    }

    try {
      const token = useAuthStore.getState().token;
      
      // Combine all arrays into keywords, filtering out empty/null values
      const keywords = [
        ...(statusArr || []),
        ...(systemArr || []),
        ...(requestorArr || []),
        ...(keywordsArr || [])
      ].filter(Boolean);

      // Format query string as: "<issue_id> [keyword1] [keyword2] ..."
      const queryString = `${query}${keywords.length ? ' ' + keywords.join(' ') : ''}`;
      const response = await api.get(
        `/search?query=${encodeURIComponent(queryString)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    }
    catch (error) {
      if (error.response?.data?.detail) {
        throw new Error(error.response.data.detail);
      }
      throw error;
    }
  });
};

// through use Issue ID to get the key specifications
export const useSearchKeyMutation = () => {
  return useMutation(async ({ query }) => {
    if (!query) {
      throw new Error("Issue ID is required");
    }

    const token = useAuthStore.getState().token; // get the aviod token

    try {
      const response = await api.get(`/search/specs/${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data; // return the data
    } catch (error) {
      console.error("Failed to fetch search specs:", error);
      throw new Error("Failed to fetch search specs");
    }
  });
};

// the favorite function to save user selected favorite
export const useToggleFavoriteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ issueId, sourceId, isFavorite }) => {
      const token = useAuthStore.getState().token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      if (isFavorite) {
        const response = await api.delete("/favorites", {
          ...config,
          data: { issue_id: issueId, source_id: sourceId },
        });
        return response.data;
      } else {
        const response = await api.post(
          "/favorites",
          { issue_id: issueId, source_id: sourceId },
          config,
        );
        return response.data;
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('starredIssues'); // refreash the star issues array when it sucess add or remove
      },
    }
  );
}

// the update password api function
export const useUpdatePasswordMutation = () => {
  return useMutation(async (passwordData) => {
    const token = useAuthStore.getState().token;
    const response = await api.post("/update-password",
      {
        current_password: passwordData.currentPassword,
        password: passwordData.newPassword,
        password_confirmation: passwordData.confirmPassword
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  });
};

// Hooks to update user eidted the field (column system)
export const useUpdateIssueMutation = () => {
  return useMutation(async ({ issueId, field, value }) => {
    const token = useAuthStore.getState().token;
    const response = await api.post(
      "/update-issue",
      { 
        issue_id: issueId,
        field: field,
        value: value 
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  });
};

// Hook for fetching saved issues
export const useSavedIssuesQuery = () => {
  return useQuery(
    'savedIssues',
    async () => {
      const token = useAuthStore.getState().token;
      const savedResponse = await api.get("/saves", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (!savedResponse.data || savedResponse.data.length === 0) {
        return [];
      }
     
      const modifiedData = savedResponse.data.map(item => {
        if (item.issues) {
          item.issues = item.issues.map(issue => mapIssueFields(issue._source, true));
        }
        return item;
      });

      return modifiedData;
    },
    {
      staleTime: Infinity, // Data is always fresh
      cacheTime: Infinity, // Cache data indefinitely
      refetchOnMount: true, // Refetch on component mount
      refetchOnWindowFocus: false, // Do not refetch on window focus
    }
  );
};

// Hook for saving issues
export const useSaveIssuesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ issueIds, sourceId }) => {
      const token = useAuthStore.getState().token;
      const response = await api.post(
        "/saves",
        { 
          issue_ids: issueIds,
          source_id: sourceId 
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('savedIssues');
        toast.success(
          `${data.saved_count} issues saved successfully${data.duplicate_count ? ` (${data.duplicate_count} duplicates)` : ''}`
        );
      },
      onError: () => {
        toast.error('Failed to save issues');
      },
    }
  );
};

// Hook for deleting saved issues
export const useDeleteSavedIssuesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ issueIds, sourceId }) => {
      const token = useAuthStore.getState().token;
      const response = await api.delete("/saves", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { 
          issue_ids: issueIds,
          source_id: sourceId 
        }
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('savedIssues');
        toast.success('Issues removed from saved successfully');
      },
      onError: () => {
        toast.error('Failed to remove issues from saved');
      },
    }
  );
};

// Hook for fetching hidden issues
export const useHiddenIssuesQuery = () => {
  return useQuery(
    'hiddenIssues',
    async () => {
      const token = useAuthStore.getState().token;
      const hiddenResponse = await api.get("/hides", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!hiddenResponse.data || hiddenResponse.data.length === 0) {
        return [];
      }

      const modifiedData = hiddenResponse.data.map(item => {
        if (item.issues) {
          item.issues = item.issues.map(issue => mapIssueFields(issue._source, true));
        }
        return item;
      });

      return modifiedData;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );
};

// Hook for hiding issues
export const useHideIssuesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ issueIds, sourceId }) => {
      const token = useAuthStore.getState().token;
      const response = await api.post(
        "/hides",
        { 
          issue_ids: issueIds,
          source_id: sourceId 
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('hiddenIssues');
        toast.success(
          `${data.hidden_count} issues hidden successfully${data.duplicate_count ? ` (${data.duplicate_count} duplicates)` : ''}`
        );
      },
      onError: () => {
        toast.error('Failed to hide issues');
      },
    }
  );
};

// Hook for unhiding issues
export const useUnhideIssuesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ issueIds, sourceId }) => {
      const token = useAuthStore.getState().token;
      const response = await api.delete("/hides", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { 
          issue_ids: issueIds,
          source_id: sourceId 
        }
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('hiddenIssues');
        toast.success('Issues unhidden successfully');
      },
      onError: () => {
        toast.error('Failed to unhide issues');
      },
    }
  );
};

// 2. get the token and check token is correct or not
export const useVerifyResetTokenMutation = () => {
  return useMutation(
    async (token) => {
      const response = await api.get(`/reset-password/${token}`, { token });
      return response.data;
    },
    // {
    //   onSuccess: (data) => {
    //     toast.success("Token is valid.");
    //   },
    //   onError: (error) => {
    //     toast.error(error.response?.data?.message || "Invalid or expired token.");
    //   },
    // }
  );
};

 
// 3. send user input email and token to backend: update the password
export const useResetPasswordMutation = () => {
  return useMutation(
    async ({ token, password, password_confirmation }) => {
      const response = await api.post(`/reset-password/${token}`, {
        password,
        password_confirmation,
      });
      return response.data;
    },
    // {
    //   onSuccess: () => {
    //     toast.success("Password has been reset successfully.");
    //   },
    //   onError: (error) => {
    //     toast.error(error.response?.data?.message || "Failed to reset password.");
    //   },
    // }
  );
}


// Hook for fetching organization users
export const useOrganizationUsersQuery = () => {
  return useQuery(
    'organizationUsers',
    async () => {
      const token = useAuthStore.getState().token;
      const response = await api.get("/organizations/users/emails", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      // Transform the data to match the expected format
      return response.data.map(user => ({
        email: user.email,
        name: `${user.first_name} ${user.last_name}`,
        initials: `${user.first_name[0]}${user.last_name[0]}`
      }));
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false, 
    }
  );
};

// API Function: used the token to get all the shared issues data
export const useSharedIssuesQuery = () => {
  return useQuery(
    'sharedIssues',
    async () => {
      const token = useAuthStore.getState().token;
      const response = await api.get("/shares", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.data || response.data.length === 0) {
        return [];
      }

      // Modify the 'issues' field while keeping other properties intact
      const modifiedData = response.data.map(item => {
        if (item.issues) {
          item.issues = item.issues.map(issue => mapIssueFields(issue._source, true));
        }
        return item;
      });

      // console.log(modifiedData); // Optionally, you can check the modified data here
      return modifiedData;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};


export const useStarredIssuesQuery = () => {
  return useQuery(
    'starredIssues',
    async () => {
      const token = useAuthStore.getState().token;
      const starredResponse = await api.get("/favorites", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!starredResponse.data || starredResponse.data.length === 0) {
        return [];
      }
      
      return starredResponse.data.map((issue) => {
        const issueWithFavorite = {
          ...issue._source,
          _is_favorite: true
        };
        return mapIssueFields(issueWithFavorite, true);
      });
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

// Shared Issue IDs Function:
// 1. Hook used to share user selected issues to the select team member email
export const useShareIssuesMutation = () => {
  return useMutation(async ({ emails, issueIds, query }) => {
    // Validate emails
    if (!emails || !Array.isArray(emails) || emails.length === 0) {
      throw new Error('At least one email is required');
    }

    // Validate issueIds
    if (!issueIds || !Array.isArray(issueIds) || issueIds.length === 0) {
      throw new Error('At least one issue ID is required');
    }

    // console.log(query);

    try {
      const token = useAuthStore.getState().token;
      const response = await api.post(
        "/shares",
        { 
          emails: emails,
          issue_ids: issueIds,
          query: typeof query === "object" && query !== null ? query.query : query,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      return response.data;
      
    } catch (error) {
      console.error('Share error:', error);
      throw error.response?.data?.message || error.message || 'Failed to share issues';
    }
  });
};

// 2. Hooks: used the token to get all the shared informtion (query, shared issue array, sharedBy email & name)==> share viewer module page
export const useSharedContentQuery = (token) => {
  return useMutation(
    async () => {
      try {
        const authToken = useAuthStore.getState().token;
        const response = await api.get(`/shares/${token}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        const data = response.data;
        return {
          issues: data.issues.map(issue => mapIssueFields(issue._source, true)),
          query: data.query,
          sharedBy: data.shared_by,
          timeStamp: data.created_at,
        };
      } catch (error) {
        if (error.response?.status === 404) {
          throw new Error('Invalid token');
        }
        throw error;
      }
    }
  );
};

// Key Specification Data Card Share Feature function:
// 1. the share function with create the token to email user selected 
export const useShareSpecs = () => {
  return useMutation(
    async ({ values, current_issue_id, emails, currTagsArr}) => {
      // Validate emails
      if (!emails || !Array.isArray(emails) || emails.length === 0) {
        throw new Error('At least one email is required');
      }

      //console.log(currTagsArr);

      // currentIssueId array is string
      if (current_issue_id !== null && typeof current_issue_id !== 'string') {
        throw new Error('Value must be an string');
      }

      // Validate array is required array
      if (values !== null && !Array.isArray(values)) {
        throw new Error('Value must be an array');
      }
      
      try {
        const authToken = useAuthStore.getState().token;

        const response = await api.post(
          '/share-specs',
          {
            values: values,
            current_issue_id: current_issue_id,
            emails: emails,
            currTagsArr: currTagsArr,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        return response.data; // if success with no errors, will return information with share_id
      } catch (error) {
        if (error.response?.data?.error) {
          throw new Error(error.response.data.error);
        }
        throw error;
      }
    }
  );
};

// 2. used the token to get all the information
// the value array including:
// 1. specificationsMenu: Issue Insights or Key Specs
// 2. selectIssueDetails: current issue (issue ID) and the Related Issues
// 3. subKeySpecsMenu: Design Docs, Standards and the Scholar Search
// 4. the subsystem title
export const useSharedSpecsQuery = (token) => {
  return useMutation(
    async () => {
      try {
        const authToken = useAuthStore.getState().token;
        const response = await api.get(`/sharedspecs/${token}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        const data = response.data;
        // console.log(data);
        // if sucess return all share information, value, current issue ids, and sharedBy
        return {
          values: data.values, // shared values array
          currentIssueId: data.current_issue_id, // current issue id
          sharedBy: data.shared_by, // shared by info
          currTagsArr: data.currTagsArr,
        };
      } catch (error) {
        if (error.response?.status === 404) {
          throw new Error('Invalid token');
        }
        throw error;
      }
    }
  );
};
