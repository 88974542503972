import axios from "axios";

const baseApiURL = process.env.REACT_APP_API_URL;


const api = axios.create({
  baseURL: baseApiURL,
  withCredentials: true,
});

// stop the error, and jump to the error page
// api.interceptors.response.use(
//   response => response,
//   error => {
//     if (error.response) {
//       const navigate = useNavigate();
//       if (error.response.status === 403 || error.response.status === 404) {
//         // jump to the error page
//         navigate('/404Error');
//       }
//     }
//     return Promise.reject(error);
//   }
// );

export {api};