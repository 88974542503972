// System Data Page: the page below the Achieve mdoule issue overview 
import React from "react";
import { useParams } from "react-router-dom";
import './SystemPage.css'

const SystemPage = () => {

    const { systemName } = useParams(); // get the user selected system

    return <div>
        System: {systemName}
    </div>
}

export default SystemPage;
