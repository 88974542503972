import React from "react";
import './IssueTrendsBar.css';
import PropTypes from "prop-types";

const IssueTrendsBar = ({
    width = "100%",
    height = "14px",
    data = [],
}) => {
    const totalOccurrences = data.reduce((sum, item) => sum + item.occurrences, 0);

    return (
        <div className="issue-trends" style={{ minWidth: width, maxWidth: width }}>
            {/* the single bar */}
            <div className="bar-container" style={{ height }}>
                {data.map((item, index) => {
                    const percentage = ((item.occurrences / totalOccurrences) * 100).toFixed(1);
                    return (
                        <div
                            key={index}
                            className="bar"
                            style={{
                                width: `${percentage}%`,
                                backgroundColor: item.color,
                            }}
                        ></div>
                    );
                })}
            </div>

            {/* the data legend item area */}
            <div className="legend">
                {data.map((item, index) => {
                    const percentage = ((item.occurrences / totalOccurrences) * 100).toFixed(1);
                    return (
                        <div key={index} className="legend-item">
                            <span
                                className="color-dot"
                                style={{ backgroundColor: item.color }}
                            ></span>
                            <span className="label">{item.name}</span>
                            <span className="occurrences">{item.occurrences}</span>
                            <span className="percentage">{percentage}%</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

IssueTrendsBar.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            occurrences: PropTypes.number.isRequired,
            color: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default IssueTrendsBar;