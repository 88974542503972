import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../../../stores/useAuthStore';
import {fetchUser, postUser} from '../../../api/queries'

const CallbackPage = () => {
  const { 
    isAuthenticated, 
    getAccessTokenSilently, 
    user, 
    isLoading: auth0Loading, 
    error: auth0Error 
  } = useAuth0();

  const { setAuth } = useAuthStore();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const authorizationParams = {
    organization: process.env.REACT_APP_AUTH0_ORGANIZATION_ID,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE
  }
  const nasa_org_id = 1
  
  const createUserMutation = useMutation(postUser, {
    onSuccess: async (data, { token }) => {
      const newUser = await fetchUser(token);
      queryClient.setQueryData(["user", user?.sub], newUser);
      setAuth(newUser, token);
      navigate("/discover");
    },
    onError: (error) => {
      console.error("createUserMutation encountered error:", error);
    }
  });

  const { data, error, isLoading, refetch } = useQuery(
    ["user", user?.sub],
    async () => {
      const token = await getAccessTokenSilently({
        authorizationParams: authorizationParams
      });
      const userData = await fetchUser(token);
      setAuth(userData, token);
      return userData;
    },
    {
      enabled: !auth0Loading && isAuthenticated,
      retry: false,
      onError: async (err) => {
        if (err.response?.status === 404) {
          const token = await getAccessTokenSilently({
            authorizationParams: authorizationParams
          });
          createUserMutation.mutate({ 
            token, 
            userData: { //NOTE: We need to add a sign-up page here.
              first_name: user.given_name ?? "TEMP", 
              last_name: user.family_name ?? "TEMP", 
              organization_id: nasa_org_id
            } 
          });
        }
      },
    }
  );

  useEffect(() => {
    if ((data && !isLoading) || createUserMutation.isSuccess) {
      navigate('/discover');
    }
  }, [data, isLoading, createUserMutation.isSuccess, navigate]);

  useEffect(() => {
    if (isAuthenticated && user) {
      refetch();
    }
  }, [isAuthenticated, user, refetch]);

  if (auth0Error) {
    return <div>Error: {auth0Error.message}</div>;
  }

  if (auth0Loading) {
    return <div>Loading authentication...</div>;
  }

  if (isLoading || createUserMutation.isLoading) {
    return <div>Loading...</div>;
  }

  if (error && error.response?.status !== 404) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <h1>Welcome {user.name}</h1>
      <p>Your account is being set up...</p>
    </div>
  );
};

export default CallbackPage;
