import React from "react";
import PropTypes from "prop-types";

const ChartIcon = ({ size = 24, color = "black", strokeWidth = 2, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M9.5 7L6.1 7C5.53995 7 5.25992 7 5.04601 7.10899C4.85785 7.20487 4.70487 7.35785 4.60899 7.54601C4.5 7.75992 4.5 8.03995 4.5 8.6L4.5 21M14.5 11L17.9 11C18.4601 11 18.7401 11 18.954 11.109C19.1422 11.2049 19.2951 11.3578 19.391 11.546C19.5 11.7599 19.5 12.0399 19.5 12.6L19.5 21M2 21L22 21M14.5 21L14.5 4.6C14.5 4.03995 14.5 3.75992 14.391 3.54601C14.2951 3.35785 14.1422 3.20487 13.954 3.10899C13.7401 3 13.4601 3 12.9 3L11.1 3C10.5399 3 10.2599 3 10.046 3.10899C9.85785 3.20487 9.70487 3.35785 9.60899 3.54601C9.5 3.75992 9.5 4.03995 9.5 4.6L9.5 21L14.5 21Z"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

ChartIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  strokeWidth: PropTypes.number,
};

export default ChartIcon;
