/* the page navigation button used for all the achieve moduel data chart card item list */
import React from "react";
import PropTypes from "prop-types";
import "./PageButton.css";

const PageButton = ({ 
    Icon, 
    onClick, 
    disable = false,
    shape = "square", // default shape is square 
}) => {
    const shapeClass = shape === "circle" ? "isCircle" : "isSquare";

    return (
        <div 
            className={`pageButton ${shapeClass} ${disable ? 'disabled' : ''}`} 
            onClick={!disable ? onClick : undefined} 
            role="button"
            tabIndex={disable ? -1 : 0} // Ensure accessibility with keyboard
            aria-disabled={disable}
        >
            <Icon size={12} color={"#667085"}/>
        </div>
    );
};

PageButton.propTypes = {
    Icon: PropTypes.elementType.isRequired, // An Icon component
    disable: PropTypes.bool,
    onClick: PropTypes.func.isRequired, // Function to handle button click
    shape: PropTypes.oneOf(["square", "circle"]), // Shape of the button
};

export default PageButton;
