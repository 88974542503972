import { useState, useRef, useEffect } from 'react';

export const useTooltip = (delay = 200) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [hoveredItem, setHoveredItem] = useState(null);
  const tooltipTimer = useRef(null);

  const handleItemHover = (item, e) => {
    if (tooltipTimer.current) {
      clearTimeout(tooltipTimer.current);
    }
    
    tooltipTimer.current = setTimeout(() => {
      setHoveredItem(item);
      setShowTooltip(true);
      
      if (e) {
        setTooltipPosition({ 
          x: e.clientX, 
          y: e.clientY 
        });
      }
    }, delay);
  };

  const handleItemLeave = () => {
    if (tooltipTimer.current) {
      clearTimeout(tooltipTimer.current);
    }
    setShowTooltip(false);
    setHoveredItem(null);
  };

  const handleMouseMove = (e) => {
    if (showTooltip) {
      setTooltipPosition({ 
        x: e.clientX, 
        y: e.clientY 
      });
    }
  };

  // Clean up timer on unmount
  useEffect(() => {
    return () => {
      if (tooltipTimer.current) {
        clearTimeout(tooltipTimer.current);
      }
    };
  }, []);

  return {
    showTooltip,
    tooltipPosition,
    hoveredItem,
    handleItemHover,
    handleItemLeave,
    handleMouseMove
  };
}; 