// Issue Details Data Page: the page below the Achieve mdoule issue overview 
import React from "react";
import { useParams } from "react-router-dom";
import './IssueDetailsPage.css'

const IssueDetailsPage = () => {

    const { issueId } = useParams(); // get the user selected system

    // ADD The electric search API ==> use the selected issue ID to search all the data of issue ID


    return <div>
        Issue ID: {issueId}
    </div>
}

export default IssueDetailsPage;
