import React from "react";
import PropTypes from "prop-types";
const SvgAchieveModule = ({
  size = 24,
  color = "var(--icon-color)",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fillRule="evenodd"
      id="Shape" 
      d="M11.9982 1.99805C13.2019 1.99805 14.3559 2.21067 15.4248 2.60042L14.231 3.79427C13.5195 3.60112 12.7709 3.49805 11.9982 3.49805C7.30261 3.49805 3.49609 7.30457 3.49609 12.0001C3.49609 16.6957 7.30261 20.5022 11.9982 20.5022C16.6938 20.5022 20.5003 16.6957 20.5003 12.0001C20.5003 11.2285 20.3975 10.4809 20.2049 9.77023L21.3989 8.57623C21.788 9.64434 22.0003 10.7975 22.0003 12.0001C22.0003 17.5242 17.5222 22.0022 11.9982 22.0022C6.47419 22.0022 1.99609 17.5242 1.99609 12.0001C1.99609 6.47614 6.47419 1.99805 11.9982 1.99805ZM11.9982 6.00015C12.5168 6.00015 13.02 6.06595 13.5 6.18966L13.5001 7.75689C13.0304 7.59063 12.5248 7.50015 11.9982 7.50015C9.51289 7.50015 7.49817 9.51487 7.49817 12.0002C7.49817 14.4854 9.51289 16.5002 11.9982 16.5002C14.4834 16.5002 16.4982 14.4854 16.4982 12.0002C16.4982 11.4742 16.4079 10.9693 16.2421 10.5002L17.8091 10.5002C17.9325 10.9796 17.9982 11.4822 17.9982 12.0002C17.9982 15.3139 15.3119 18.0002 11.9982 18.0002C8.68446 18.0002 5.99817 15.3139 5.99817 12.0002C5.99817 8.68644 8.68446 6.00015 11.9982 6.00015ZM13.9982 12.0002C13.9982 13.1047 13.1027 14.0002 11.9982 14.0002C10.8936 14.0002 9.99817 13.1047 9.99817 12.0002C9.99817 10.8956 10.8936 10.0002 11.9982 10.0002C12.2758 10.0002 12.5402 10.0567 12.7805 10.159L14.5001 8.43939L14.5 5.25019C14.5 5.05128 14.579 4.86051 14.7197 4.71985L17.2196 2.21978C17.4341 2.00528 17.7567 1.9411 18.0369 2.05719C18.3172 2.17327 18.4999 2.44675 18.4999 2.7501V5.50012L21.2501 5.50017C21.5535 5.50018 21.827 5.68291 21.943 5.96317C22.0591 6.24343 21.995 6.56601 21.7805 6.78051L19.2804 9.2805C19.1397 9.42115 18.949 9.50016 18.7501 9.50016L15.5607 9.50017L13.8405 11.2204C13.942 11.46 13.9982 11.7235 13.9982 12.0002ZM18.4394 8.00017L19.4395 7.00014L17.7499 7.00011C17.3357 7.0001 16.9999 6.66432 16.9999 6.25011V4.56082L16 5.5608L16.0001 7.94121C16.0104 7.95042 16.0205 7.95996 16.0303 7.96983C16.0402 7.97974 16.0498 7.98986 16.059 8.00017L18.4394 8.00017Z"
      clipRule="evenodd"
      fill={color}
    />
  </svg>
);
SvgAchieveModule.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};
export default SvgAchieveModule;
