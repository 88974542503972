/* 
    this is the left area of Module 2 (Search) part: issue menu, issue item and search bar
*/
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useAppContext } from "../../../AppContext";
import IssueDetails from "../../Module Discover/Issue Table/IssueDetails";
import IssueDataGrid from "../../Module Discover/Issue Table/IssueDataGrid";
import "./IssuePart.css";
import { ArrowUp, ArrowDown } from "../../../Components/icons/Arrows";
import { Search, Keyboard } from "../../../Components/icons/General";
import { ToggleSwitch, Badge, Button } from "../../../Components";
import {
    DropdownMenu,
    DropdownMenuTrigger,
    DropdownMenuContent,
    DropdownMenuSeparator,
    DropdownMenuCheckboxItem,
} from "../../../Components/ui/DropdownMenu/DropdownMenu";
import SearchResultIcon from '../../../image/rocket.png';
import {SharedOnlyArea, QueryHeader} from "../../../Components";
import { useDrag, useDrop } from 'react-dnd';

const IssuePart = ({ sharedBy }) => {
    const {
        clickedSimilarIssue,
        setDiscoverColumns,
        discoverColumns,
        sharedIssueArray,
        highlightArray,
        setHighlightArray,
        setSelectAllHighlight,
        isOpenSpecification,
        setIsOpenSpecification,
        sharedQuery,
        timeStamp,
    } = useAppContext();


    // the keyboard feature dropdown menu
    const [isKeyBoardOpen, setIsKeyBoardOpen] = useState(false);
    const keyBoardMenuRef = useRef(null); // the ref used for auto closed of dropdown menu

    // the state used for customize table search function 
    const [inputSearchColumn, setInputSearchColumn] = useState("");

    /**************************************  1. the highlight drop list about words hightlight: ************************************* */
    // select and unselect one highlight item
    const toggleHighlight = (e, key) => {
        e.preventDefault();
        setHighlightArray((prevState) => {
            const newState = {
                ...prevState,
                [key]: !prevState[key],
            };
            // update the main tittle checkbox status based on user selection
            setSelectAllHighlight(Object.values(newState).every(Boolean));
            return newState;
        });
    };

    // the checkBox in the left of highlight, could select all the highlight item
    const handleSelectAll = (e) => {
        e.preventDefault();
        const newSelectAll = true;
        // update the main tittle checkBox
        setSelectAllHighlight(newSelectAll);

        // update all the status of 4 highlight item
        setHighlightArray((prevState) => {
            const newState = {};
            Object.keys(prevState).forEach((key) => {
                newState[key] = newSelectAll;
            });
            return newState;
        });
    };

    //  the checkBox in the left of highlight, click to unSelect all the highlight item
    const handleUnSelectAll = (e) => {
        e.preventDefault();
        setSelectAllHighlight(false);
        // update all the status of 4 highlight item
        setHighlightArray((prevState) => {
            const newState = {};
            Object.keys(prevState).forEach((key) => {
                newState[key] = false;
            });
            return newState;
        });
    };

    // Function: check how many selection item of highlight dropmenu was selected, and the checkbox "Add All" display checked or interminate
    const getSelectionHiglight = (selectedArr) => {
        const checkedCount = Object.values(selectedArr).filter((value) => value === true).length;
        return {
            checkedHighlight: checkedCount > 0 && checkedCount <= Object.keys(selectedArr).length,
            indeterminateHighlight: checkedCount > 0 && checkedCount < Object.keys(selectedArr).length,
        };
    };
    const { checkedHighlight, indeterminateHighlight } = getSelectionHiglight(highlightArray);


    /**************************************  2. Key specification and the Considerations tab button function: ************************************* */
    // open the right area information: key specification and the Considerations
    const handleOpenSpecification = useCallback(() => {
        setIsOpenSpecification(prevState => {
            const newState = !prevState;
            localStorage.setItem("savedOpenKeySpec", newState);
            return newState;
        });
    }, []);


    /**************************************  3. The keyboard dropdown menu shows all the keybaord feature: ************************************* */
    // the function: open and closed dropdown menu
    const handleOpenKeyboardMenu = () => {
        setIsKeyBoardOpen(!isKeyBoardOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (keyBoardMenuRef.current && !keyBoardMenuRef.current.contains(event.target)) {
                setIsKeyBoardOpen(false);
            }
        };

        document.addEventListener("pointerdown", handleClickOutside);
        return () => document.removeEventListener("pointerdown", handleClickOutside);
    }, []);


    /**************************************  4. The Customize Table Function Part: ************************************* */
    // Handle column selection change, save the user selected the column to display
    const handleColumnChange = (e, column) => {
        e.preventDefault();
        setDiscoverColumns((prev) => {
            const newColumns = prev.map((col) => {
                if (col.field === column) {
                    return { ...col, hide: !col.hide };
                }
                return col;
            });
            localStorage.setItem("discoverColumns", JSON.stringify(newColumns)); // saved it into localStorge
            return newColumns;
        });
    };

    // the "Selected all " function used for add all the column to display
    const handleSelectAllColumn = (e) => {
        e.preventDefault();
        setDiscoverColumns((prev) => {
            const newColumns = prev.map((col) => ({ ...col, hide: false })).sort((a, b) => a.index - b.index);
            localStorage.setItem("discoverColumns", JSON.stringify(newColumns));  // saved it into localStorge
            return newColumns;
        });
    };

    // unSelected function for removed all the selection of drop down selection list
    const handleUnSelectAllColumn = (e) => {
        e.preventDefault();
        setDiscoverColumns((prev) => {
            const newColumns = prev.map((col) => ({ ...col, hide: true }));
            localStorage.setItem("discoverColumns", JSON.stringify(newColumns));  // saved it into localStorge
            return newColumns;
        });
    };

    // Customzie tabl dropdown menu: check how many columns selected ==> whether it equals the all the columns
    const filteredColumns = discoverColumns.filter((column) =>
        inputSearchColumn
            ? column.field.toLowerCase().includes(inputSearchColumn.toLowerCase())
            : true
    );

    // used for customize table dropdown menu "Add All" Checkbox display, it will count how many columns added it
    const getSelectionState = (filteredColumns) => {
        const selectedCount = filteredColumns.filter((column) => !column.hide).length;
        const totalCount = filteredColumns.length;
        return {
            checked: selectedCount === totalCount,
            indeterminate: selectedCount > 0 && selectedCount < totalCount,
        };
    };

    const { checked, indeterminate } = getSelectionState(filteredColumns);

    /**************************************  4. The Customize Table dragging item to be column part : ************************************* */
    // the react-dnd function: select whcih item was user selected to dragging into the issue table column
    const DraggableColumnItem = ({ column, onAddColumn, index, moveItem }) => {
        const [{ isDragging }, drag] = useDrag({
            type: 'COLUMN',
            item: { type: 'COLUMN', column: column },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const dropResult = monitor.getDropResult();
                if (dropResult && dropResult.targetField) {
                    onAddColumn(item.column, dropResult.targetField);
                } else {
                    onAddColumn(item.column, null);
                }
            },
        });
    
        const [, menuDrag, menuPreview] = useDrag({
            type: 'COLUMN_REORDER',
            item: { type: 'COLUMN_REORDER', index, column },
            collect: (monitor) => ({
                isDraggingInMenu: monitor.isDragging(),
            }),
        });
    
        const [{ isOver }, drop] = useDrop({
            accept: 'COLUMN_REORDER',
            collect: (monitor) => ({
                isOver: monitor.isOver(),
            }),
            drop: (draggedItem) => {
                if (draggedItem.index !== index) {
                    moveItem(draggedItem.index, index);
                }
            },
        });
    
        const combinedRef = (el) => {
            menuDrag(el);
            drop(el);
            menuPreview(el);
        };
    
        const contentRef = (el) => {
            drag(el);
        };
    
        return (
            // <div
            //     ref={combinedRef}
            //     style={{
            //         opacity: isDragging ? 0.5 : 1,
            //         borderTop: isOver ? "1px solid #667085" : "1px solid transparent",
            //         display: 'flex',
            //         alignItems: 'center',
            //         cursor: 'move',
            //     }}
            // >
            //     <div className="drag-handle" style={{ marginRight: '6px', cursor: 'move' }}>
            //         ≡
            //     </div>
            //     <div
            //         ref={contentRef}
            //         className="drag-handle"
            //         style={{
            //             flex: 1,
            //             cursor: 'move',
            //         }}
            //     >
            //         {column}
            //     </div>
            // </div>

            <div
                ref={combinedRef}
                style={{
                    opacity: isDragging ? 0.5 : 1,
                    borderTop: isOver ? "2px solid #667085" : "2px solid transparent",
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'move',
                    width: "100%",
                    height: "28px",
                }}
            >
                {column}
            </div>
        );
    };

    // used for add the column into our issue table
    const handleAddColumn = (columnName, targetField) => {
        const isColumnExists = discoverColumns.some(
            (col) => (col.field === columnName || col.headerName === columnName) && !col.hide
        );
    
        if (!isColumnExists) {
            setDiscoverColumns((prevColumns) => {
                // find the target column index value
                const targetIndex = prevColumns.findIndex((col) => col.field === targetField);
                const columnIndex = prevColumns.findIndex((col) => col.field === columnName);
    
                if (columnIndex !== -1) {
                    // update the current column index ==> used to display in the user drop place
                    const updatedColumns = prevColumns.map((col) => {
                        if (col.field === columnName) {
                            return { ...col, hide: false }; 
                        }
                        return col;
                    });
    
                    // Update the index property of the column: the index of the current column becomes targetIndex, 
                    // and the index of subsequent columns increases by 1
                    const updatedWithIndex = updatedColumns.map((col, idx) => {
                        if (idx === columnIndex) {
                            return { ...col, index: targetIndex + 1}; //Update the index property of the column:
                        } else if (col.index >= targetIndex) {
                            return { ...col, index: col.index + 1 }; // the index of subsequent columns increases by 1
                        }
                        return col;
                    });
    
                    return updatedWithIndex;
                }
                return prevColumns; // If the column does not exist, the original data is returned.
            });
        } else {
            console.warn(`Column ${columnName} already exists, skipping.`);
        }
    };
    
    
    // Functions for moving columns and change sequences
    const moveItem = (fromIndex, toIndex) => {
        if(fromIndex === toIndex){
            return;
        }
        
        setDiscoverColumns(prevColumns => {
            // Copy the original column data
            const result = [...prevColumns];
            
            // Delete the column at the specified position
            const [removed] = result.splice(fromIndex, 1);
            
            // Insert at a new position
            result.splice(toIndex, 0, removed);
            
            //Update the index property of each column to ensure that the index order is continuous
            result.forEach((column, index) => {
                column.index = index; // Update the index according to the new order
            });
            
            return result;
        });
    };


    // main render Area with HTML Tag
    return (
        <div className="shareArea"
            style={{
                width: isOpenSpecification ? 'calc(68vw - 25px)' : '100%',
                transition: 'width 0.5s ease',
            }}
        >
            <SharedOnlyArea
                title="Share-read only link"
                context="Anyone with the link can view the site in Apiphany.ai but won’t be able to edit it"
                contactText="Contact us"
                defaultWidth="calc(100% - 32px)"
            />
            <div className="controlAreaMenu">
                {
                    sharedIssueArray.length > 0 ? <p className="shareResultMessage"> <img src={SearchResultIcon}
                        alt="SearchResultIcon"
                        style={{ height: "20px", width: "20px", marginRight: "6px" }}
                    />
                        {sharedIssueArray.length} similar issues shared for you
                    </p> : <p></p>
                }

                <div style={{ display: "flex" }}>
                    <DropdownMenu>
                        <DropdownMenuTrigger height="32px">
                            <span className="dropdown-menu-trigger-text" style={{fontSize: "14px"}}>Highlights</span>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuCheckboxItem
                                checked={checkedHighlight}
                                indeterminate={indeterminateHighlight}
                                onClick={handleSelectAll}
                            >
                                Select all
                            </DropdownMenuCheckboxItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuCheckboxItem checked={highlightArray["Failure Mode"]} onClick={(e) => toggleHighlight(e, "Failure Mode")}>
                                <Badge type="highlight" variant="failure-mode">
                                    Failure Mode
                                </Badge>
                            </DropdownMenuCheckboxItem>
                            <DropdownMenuCheckboxItem checked={highlightArray["Components"]} onClick={(e) => toggleHighlight(e, "Components")}>
                                <Badge type="highlight" variant="components">
                                    Components
                                </Badge>
                            </DropdownMenuCheckboxItem>
                            <DropdownMenuCheckboxItem checked={highlightArray["Failure Condition"]} onClick={(e) => toggleHighlight(e, "Failure Condition")}>
                                <Badge type="highlight" variant="failure-condition">
                                    Failure Condition
                                </Badge>
                            </DropdownMenuCheckboxItem>
                            <DropdownMenuCheckboxItem checked={highlightArray["After Effects"]} onClick={(e) => toggleHighlight(e, "After Effects")} >
                                <Badge type="highlight" variant="after-effects">
                                    After Effects
                                </Badge>
                            </DropdownMenuCheckboxItem>
                            <DropdownMenuCheckboxItem checked={highlightArray["Specification"]} onClick={(e) => toggleHighlight(e, "Specification")} >
                                <Badge type="highlight" variant="specifications">
                                    Specification
                                </Badge>
                            </DropdownMenuCheckboxItem>
                            <DropdownMenuSeparator />

                            <div style={{ marginLeft: "2px" }}>
                                <Button variant="danger-link" size="medium" onClick={handleUnSelectAll}>
                                    Clear all
                                </Button>
                            </div>
                        </DropdownMenuContent>
                    </DropdownMenu>

                    <div className="ToggleButton">
                        <p>Key Specifications</p>
                        <ToggleSwitch isOpen={isOpenSpecification} onToggle={handleOpenSpecification} width="36px" height="18px" />
                    </div>
                </div>
            </div>


            <div className="sharedItemArea">
                <div className="rightButtonArea">
                    {/* the keyboard shows feature */}
                    <div className="keyBoardButton" ref={keyBoardMenuRef} onClick={handleOpenKeyboardMenu}>
                        <Keyboard />
                    </div>
                    <div className={`keyboardDropdownMenu ${isKeyBoardOpen ? "show" : ""}`}>
                        <div className="featureItem"> Move up  <div className="KeyBoardIcon"> <ArrowUp size={16} color="#667085" /> </div> </div>
                        <div className="featureItem"> Move Down  <div className="KeyBoardIcon"> <ArrowDown size={16} color="#667085" /> </div> </div>
                        <div className="featureItem"> Mark selected row checkbox <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> space bar </div> </div>
                        <div className="featureItem"> Selected all the rows checkbox <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> Ctrl+A </div> </div>
                        <div className="featureItem"> UnSelected all the rows checkbox <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> Ctrl+D </div> </div>
                        <div className="featureItem"> Open selected row details window  <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> Enter </div> </div>
                        <div className="featureItem"> Mark current row as favorite <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> Ctrl+S </div> </div>
                        <div className="featureItem"> Move Five Rows up  <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> PageUp </div> </div>
                        <div className="featureItem"> Move Five Rows Down  <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> PageDown </div> </div>
                        <div className="featureItem"> Move to First Issue  <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> Home </div> </div>
                        <div className="featureItem"> Move to Last Issue  <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> End </div> </div>
                    </div>

                     {/* the customize table button: the dropdown menu to add the columns of issue table */}
                     <DropdownMenu>
                        <DropdownMenuTrigger height="32px">
                            <span className="dropdown-menu-trigger-text" style={{fontSize: "14px"}}> Customize Table</span>
                        </DropdownMenuTrigger>

                        <DropdownMenuContent>
                            <div className="columnSearch">
                                <Search size={16} color="#667085" />
                                <input
                                    type="text"
                                    placeholder="Search columns"
                                    onChange={(e) => setInputSearchColumn(e.target.value)}
                                    value={inputSearchColumn}
                                    onClick={(e) => e.stopPropagation()} // stop the click event
                                    onKeyDown={(e) => e.stopPropagation()} // stop the keyboard event
                                    style={{ border: "none", outline: "none", width: "80%" }}
                                />
                            </div>
                            <DropdownMenuSeparator />
                            <DropdownMenuCheckboxItem
                                checked={checked}
                                onSelect={handleSelectAllColumn}
                                indeterminate={indeterminate}
                            >
                                Add all
                            </DropdownMenuCheckboxItem>
                            <DropdownMenuSeparator />
                            <div className="cutomizeDropDownMenu">
                                {(() => {
                                    return discoverColumns
                                        .sort((a, b) => a.index - b.index) // sort in the sequnece (index)
                                        .map((column) => ({
                                            column,
                                            selectedIndex: discoverColumns.findIndex(item => item.field === column.field),
                                        }))
                                        .filter(({ column }) =>
                                            inputSearchColumn
                                                ? column.headerName.toLowerCase().includes(inputSearchColumn.toLowerCase()) // 修正 filter 逻辑
                                                : true
                                        )
                                        .map(({ column }) => (
                                            <DropdownMenuCheckboxItem
                                                key={column.field}
                                                checked={!column.hide}
                                                onClick={(e) => handleColumnChange(e, column.field)}
                                            >
                                                <DraggableColumnItem
                                                    column={column.headerName}
                                                    index={column.index}
                                                    moveItem={moveItem}
                                                    onAddColumn={handleAddColumn}
                                                >
                                                    {column.headerName}
                                                </DraggableColumnItem>
                                            </DropdownMenuCheckboxItem>
                                        ));
                                })()}
                            </div>
                            <DropdownMenuSeparator />

                            <div style={{ marginLeft: "2px" }}>
                                <Button variant="danger-link" size="medium" onClick={handleUnSelectAllColumn}>
                                    Clear all
                                </Button>
                            </div>
                        </DropdownMenuContent>
                    </DropdownMenu>

                </div>
            </div>


            {/* The Issue Data Table: Display all the issue datas */}
            <div className="shareIssueAreaFormat">
                <div className="shareQueryDeatils">
                    <div className="queryArea">
                        <QueryHeader
                            queryArray={sharedQuery}
                            timestamp={timeStamp}
                            defaultWidth={"calc(100% - 36px)"}
                            isSharedViews={true}
                            fromUserName={sharedBy.first_name + " " + sharedBy.last_name}
                            fromUserEmail={sharedBy.email}
                        />
                    </div>

                    {/* the issue data grid about all the issue detail */}
                    <IssueDataGrid tableViews={"SharedViews"}/>
                </div>

    
            </div>

            {/* open the relative issue details pop window */}
            {clickedSimilarIssue !== null && <IssueDetails />}


        </div>
    );
};

export default IssuePart;