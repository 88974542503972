// Chart Card: Ahieve module Chart Card with chart data item display list
import React, { useState } from "react";
import PropTypes from "prop-types";
import './ChartCard.css'
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuLabelItem } from "../../ui/DropdownMenu/DropdownMenu";
import PageButton from "../SwitchPageButton/PageButton";
import { ChevronLeft, ChevronRight} from "../../icons/Arrows";

Chart.register(ArcElement, Tooltip, Legend);

const ChartCard = ({
  chartArr = [],
  chartType,
  dropDownArray = [],
  selectedOption, // the select option of dropdwonmenu from parents component
  onDropdownChange, // the functon about select dropdwon menu ==> used to show difference chart
  hasDropDownMenu = true, // whether have the selection dropdown menu
  hasViewDetailsButton = true, // whether have the "view details" button
  chartTitle,
  defaultHeight = "500px",
  defaultWidth = "500px",
}) => {
  const chartColors = [
    "#36A2EB", "#FFCE56", "#4BC0C0", "#4dc9f6", "#c9cbcf", 
    "#b37feb", "#2ecc71", "#1abc9c", "#f39c12", "#e74c3c", "#9b59b6",
    "#3498db", "#34495e", "#95a5a6", "#d35400", "#2c3e50"
  ]; // the data chart color

  // used the chart arr data to build chart (chart.js)
  const getChartData = () => ({
    labels: chartArr.map((item) => item.label),
    datasets: [
      {
        data: chartArr.map((item) => item.Count),
        backgroundColor: chartColors,
        hoverBackgroundColor: chartColors,
      },
    ],
  });

  // the chart opetion and style
  const getChartOptions = () => ({
    responsive: true,
    plugins: {
      legend: { display: false },
    },
    backgroundColor: "transparent",
  });

  // count the total issue used for status
  const totalCount = chartArr.reduce((sum, item) => sum + item.Count, 0);

  //the page navigation used for chart data item list
  const [currentPage, setCurrentPage] = useState(0); // the current page
  const itemsPerPage = selectedOption === "Status" ? 4 : 5; // if was status chart 4 item one page

  // the chart data item will display the range
  const paginatedData = chartArr.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  // the page navigtion button function
  const handlePageChange = (direction) => {
    setCurrentPage((prev) => {
      const maxPage = Math.ceil(chartArr.length / itemsPerPage) - 1;
      return direction === "next" ? Math.min(prev + 1, maxPage) : Math.max(prev - 1, 0);
    });
  };

  // the data list item display of chart
  const renderDataList = () => {
    return paginatedData.map((item) => {
      const originalIndex = chartArr.findIndex((data) => data.label === item.label);
      return (
        <div key={item.label} className="dataItem">
          <div className="dataItemLeft">
            <div
              className="colorDot"
              style={{ backgroundColor: chartColors[originalIndex % chartColors.length] }}
            />
            <span className="dataLabel">{item.label}</span>
          </div>
          <div className="dataInfo">
            <span className="dataPercentage">
              {((item.Count / totalCount) * 100).toFixed(1)}%
            </span>
            <span className="dataCount">{item.Count}</span>
          </div>
        </div>
      );
    });
  };


  // the main render 
  return (
    <div className="ChartCard" style={{ width: defaultWidth, height: defaultHeight}}>
      <div className="chartMenuArea">
        { hasDropDownMenu === true ? <DropdownMenu>
          <DropdownMenuTrigger height="32px">
            <span className="dropdown-menu-trigger-text">{selectedOption}</span>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {dropDownArray.map((item) => (
              <DropdownMenuLabelItem
                key={item}
                checked={selectedOption === item}
                onSelect={() => onDropdownChange(item)} // let the parents update user select difference item
              >
                {item}
              </DropdownMenuLabelItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
        : <div className="chartTitle">{chartTitle}</div>  
      }

       { hasViewDetailsButton && <div className="detailsText">View details</div>}
      </div>

      <div className="chartArea">
        {chartType === "doughnut" && <Doughnut data={getChartData()} options={getChartOptions()} />}
      </div>

      {/* the data item list of Status Part ==> including the Total issues title */}
      {selectedOption === "Status" && <div className="chartDataDeatils">
        <span className="chartTitle">Total Issues</span>
        <div className="pageAreaWithSubTitle">
          <span className="chartSubTitle">{totalCount} </span>

          <div className="pageStatusNav">
            <PageButton shape="circle"  Icon={ChevronLeft} onClick={() => handlePageChange("prev")} disable={currentPage === 0}/> 
              <span className="pageText">{currentPage + 1} / {Math.ceil(chartArr.length / itemsPerPage)}</span>
            <PageButton shape="circle"  Icon={ChevronRight}  onClick={() => handlePageChange("next")} disable={(currentPage + 1) * itemsPerPage >= chartArr.length}/> 
          </div>
        </div>
        <div className="dataList">{renderDataList()}</div>
      </div>}

      {/* the data item list of Aircraft, location and  manufacturer */}
      {selectedOption !== "Status" && <div className="chartDataDeatils">
        <div className="pageNav">
          <PageButton shape="circle"  Icon={ChevronLeft} onClick={() => handlePageChange("prev")} disable={currentPage === 0}/> 
            <span className="pageText">{currentPage + 1} / {Math.ceil(chartArr.length / itemsPerPage)}</span>
          <PageButton shape="circle" Icon={ChevronRight} onClick={() => handlePageChange("next")} disable={(currentPage + 1) * itemsPerPage >= chartArr.length}/> 
        </div>
        <div className="dataList">{renderDataList()}</div>
      </div>}
    </div>
  );
};

ChartCard.propTypes = {
    chartArr: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            Count: PropTypes.number.isRequired,
        })
    ).isRequired,// the arrary used to build chart
    chartType: PropTypes.string.isRequired, // which chart will display: doughnut, Pie, Bar, etc..
    hasDropDownMenu: PropTypes.bool,
    hasViewDetailsButton: PropTypes.bool,
    dropDownArray: PropTypes.array, // the dropdown menu arrary ==> also used to change chart data array 
    chartTitle: PropTypes.string,
    selectedOption: PropTypes.string, // the selection of dropdown menu
    onDropdownChange: PropTypes.func, // the function to change the dropdown menu selection
    defaultHeight: PropTypes.string,// the data card default height
    defaultWidth: PropTypes.string, // the data card default width
}

export default ChartCard;