import React from "react";
import PropTypes from "prop-types";
const SvgStar = ({ size = 24, color = "var(--icon-color)", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M11.355 1.776a1.5 1.5 0 0 1 1.29 0c.354.168.548.46.646.62.102.166.206.377.308.583l.015.032 2.14 4.335 4.822.705c.227.033.46.067.65.113.182.044.52.139.789.423a1.5 1.5 0 0 1 .398 1.228c-.051.387-.27.663-.391.805-.127.148-.295.312-.46.472l-3.488 3.397.823 4.799c.04.226.08.458.095.652.014.187.029.538-.158.882a1.5 1.5 0 0 1-1.045.759c-.384.071-.713-.051-.887-.123a8 8 0 0 1-.591-.291L12 18.9l-4.31 2.267c-.204.107-.412.216-.592.29-.173.073-.503.195-.887.124a1.5 1.5 0 0 1-1.045-.76c-.187-.343-.172-.694-.158-.881.016-.194.056-.426.095-.652l.823-4.799-3.463-3.372-.025-.025c-.165-.16-.333-.324-.46-.472-.122-.142-.34-.418-.39-.805a1.5 1.5 0 0 1 .397-1.228c.27-.284.607-.38.79-.423.189-.046.422-.08.649-.113l.034-.005 4.788-.7 2.14-4.335.016-.032c.101-.206.205-.417.307-.582.098-.16.292-.453.646-.621M12 4.259 9.993 8.326l-.013.027c-.048.099-.147.303-.303.471a1.5 1.5 0 0 1-.467.34c-.208.096-.433.127-.541.142l-.03.004-4.49.657 3.247 3.163.022.02c.08.077.243.234.355.435a1.5 1.5 0 0 1 .179.55c.028.228-.013.451-.032.56l-.005.029-.767 4.468 4.014-2.111.027-.014c.096-.052.297-.16.522-.204a1.5 1.5 0 0 1 .578 0c.226.044.426.152.522.204l.027.014 4.014 2.11-.767-4.467q-.001-.014-.005-.03a2 2 0 0 1-.032-.56c.024-.193.084-.38.179-.549.112-.201.276-.358.355-.434q.012-.012.022-.02l3.248-3.164-4.49-.657-.03-.004a1.9 1.9 0 0 1-.542-.143 1.5 1.5 0 0 1-.467-.339 1.9 1.9 0 0 1-.316-.498z"
      clipRule="evenodd"
    />
  </svg>
);
SvgStar.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};
export default SvgStar;
