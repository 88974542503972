// thw switch image component of key specification
import React, { useState } from "react";
import PropTypes from "prop-types";
import SwitchButton from "../SwitchImageButton/SwitchButton";
import "./SwitchImage.css";
import { ChevronLeft, ChevronRight} from "../../icons/Arrows";

const SwitchImage = ({ 
    imageObj,
    defaultWidth = "509px",
    defaultHeight = "305px"
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imageObj.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === imageObj.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
      <div className="SwitchImage">
          <div className="switchImageContainer" style={{ width: defaultWidth, height: defaultHeight }}>
              <div className="imageWrapper">
                  <img
                      src={imageObj[currentIndex]?.route}
                      alt={imageObj[currentIndex]?.title}
                      className="displayImage"
                  />
              </div>

              {/* the left and right switch button ==> only show up when the image more than 1 */}
              {imageObj.length > 1 && <div className="buttonWrapper leftButton">
                  <SwitchButton
                      Icon={ChevronLeft} // left icon
                      onClick={handlePrev}
                      shape="circle"
                  />
              </div>}

              {imageObj.length > 1 && <div className="buttonWrapper rightButton">
                  <SwitchButton
                      Icon={ChevronRight} // right icon
                      onClick={handleNext}
                      shape="circle"
                  />
              </div>}

              {/* the footer area   ==> only show up when the image more than 1 */}
              {imageObj.length > 1 &&  <div className="pagination">
                  {imageObj.map((_, index) => (
                      <div
                          key={index}
                          className={`paginationDot ${index === currentIndex ? "active" : ""
                              }`}
                      />
                  ))}
              </div>}
          </div>

          <span className="imageTitleText">{imageObj[currentIndex]?.title}</span>
      </div>
  );
};

SwitchImage.propTypes = {
  imageObj: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
    })
  ).isRequired,
  defaultWidth: PropTypes.string,
  defaultHeight: PropTypes.string,
};

export default SwitchImage;
