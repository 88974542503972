// the cutomize card used to display the data of key specification area
import React, { useEffect, useState, useRef} from "react";
import PropTypes from "prop-types";
import "./SpeciDataCard.css";
import ViewButton from "../ViewButton/ViewButton";
import { Checkbox } from "../../ui/Checkbox";
import { Button } from "../../../index"
import Spinner from '../../Spinner/Spinner'; // Import the Spinner component
import SwitchImage from "../SwitchImage/SwitchImage";

// the image icon imported
//import {Share, SaveIcon, AIautoFill, Copy} from "../../icons/General/index";
import {Share, AIautoFill} from "../../icons/General/index";
// import { ThumbsDown } from "../../icons/Alerts/index";
import { Links } from "../../icons/General/index";

const SpeciDataCard = ({
    title,
    context,
    accordionTitle,
    sourceLinks = {},
    imageObj = {},
    defaultWidth = "541px",
    onShare,
    openDataCard,
    setOpenDataCard,
    teamMember = [],
    isTeamMemberLoading, 
    showButton = true,
}) => {

    const [isExpanded, setIsExpanded] = useState(false); // the data card was open or not
    const [buttonArray, setButtonArray] = useState([
        {
            label: "Share",
            isClicked: false,
            buttonClick: () => handleShareClick(),
            icon: Share,
            IconSize: 16
        },
        // {
        //     label: "Save",
        //     isClicked: false,
        //     buttonClick: () => console.log("Save clicked"),
        //     icon: SaveIcon,
        //     IconSize: 16
        // },
        // {
        //     label: null,
        //     isClicked: false,
        //     buttonClick: () => console.log("Thumbs down clicked"),
        //     icon: ThumbsDown,
        //     IconSize: 16
        // },
        // {
        //     label: null,
        //     isClicked: false, 
        //     buttonClick: () => console.log("Sparkles clicked"),
        //     icon: AIautoFill,
        //     IconSize: 16
        // }
    ]);

    const [selectTeamMember, setSelectTeamMember] = useState([]);
    const menuRef = useRef(null);

    //  memorize which data card user open it
    useEffect(() => {
        if (Array.isArray(openDataCard) && openDataCard.includes(title)) {
            setIsExpanded(true);
        } else{
            setIsExpanded(false);
        }
    }, [openDataCard, title])

    // the open data card function
    const toggleExpand = () => {
        setIsExpanded((prevIsOpen) => {
            if (prevIsOpen) {
                setOpenDataCard((prevOpenItem) => prevOpenItem.filter((item) => item !== title));
            } else {
                setOpenDataCard((prevOpenItem) => [...prevOpenItem, title]);
            }
            return !prevIsOpen;
        });
    };


    // button Clicked function
    const handleShareClick = () => {
        setButtonArray((prevArray) =>
            prevArray.map((button) =>
                button.label === "Share"
                    ? { ...button, isClicked: !button.isClicked }
                    : button
            )
        );
    };

    // the useEffect used for listner user click outside to closed the share menu
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                closeShareMenu();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // the function to closed share menu
    const closeShareMenu = () => {
        setButtonArray((prevArray) =>
            prevArray.map((button) =>
                button.label === "Share" ? { ...button, isClicked: false } : button
            )
        );
    };

    // share menu selected function: get the user selected share list
    const handleSpeciCheckboxChange = (email) => {
        setSelectTeamMember((prevSelected) =>
            prevSelected.includes(email)
                ? prevSelected.filter((item) => item !== email)
                : [...prevSelected, email]
        );
    };
 
    return (
        <div className="datacard" style={{ width: defaultWidth}}>
            <span className="titleText">{title}</span>
            <span className={`context ${isExpanded ? "expanded" : "collapsed"}`} onClick={toggleExpand}>{context}</span>

            {/* the source linke of key specification part */}
            {Object.keys(sourceLinks).length > 0 && Object.entries(sourceLinks).map(([key, value], index) => (
                <a
                    key={`${key} ${index}`}
                    className="sourceText"
                    href={value}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {key} <Links size={14} color="var(--blue-700)"/>
                </a>
            ))}

            {/* the image data part: only show up when this current data card have image ==> the image component should display as center*/}
            {Object.keys(imageObj).length > 0 && 
                <div className="imageDataArea"> 
                    <SwitchImage imageObj={imageObj}/>
                </div>
            }

           
            {showButton && <div className="buttonArea" style={{position: "relative"}}>
                {buttonArray.map((button, index) => (
                    <ViewButton
                        key={index}
                        label={button.label}
                        onClick={button.buttonClick}
                        icon={button.icon}
                        variant={button.icon !== AIautoFill ? "default" : "AI"}
                        IconSize={button.IconSize}
                        isClicked={button.isClicked}
                    />
                ))}

                {buttonArray.find((button) => button.label === "Share")?.isClicked && (
                    <div
                        ref={menuRef}
                        style={{
                            position: "absolute",
                            bottom: "-100%",
                            right: "0px",
                        }}
                        className="shareMenu"
                    >
                        {/* <div className="dropDownHeader">
                            <Copy size={16} /> Copy to clipboard
                        </div> */}
                        <div className="shareSelectArea">
                            {isTeamMemberLoading ? (
                                <div className="spinner-container">
                                    <Spinner size="small" />
                                    <span>Loading Team Members...</span>
                                </div>
                            ) : teamMember.length > 0 ? (
                                teamMember.map((member, index) => (
                                    <Checkbox
                                        key={index}
                                        id={member.email}
                                        size="xs"
                                        label={member.name}
                                        checked={selectTeamMember.includes(member.email)}
                                        className="dropDownItem"
                                        onChange={() => handleSpeciCheckboxChange(member.email)}
                                    />
                                ))
                            ) : (
                                <span>No team members available</span>
                            )}

                        </div>

                        <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                            <Button
                                variant="primary"
                                size="xsmall"
                                width={"90%"}
                                onClick={() => {
                                    onShare(selectTeamMember, accordionTitle);
                                    setButtonArray((prevArray) =>
                                        prevArray.map((button) =>
                                            button.label === "Share" ? { ...button, isClicked: false } : button
                                        )
                                    );
                                    setSelectTeamMember([]); // Reset the selected team member array
                                }}
                                disabled={selectTeamMember.length === 0}
                            >
                                Send
                            </Button>
                        </div>

                    </div>
                )}
            </div>}
        </div>
    );
};

SpeciDataCard.propTypes = {
    title: PropTypes.string.isRequired,
    context: PropTypes.string.isRequired,
    accordionTitle: PropTypes.string.isRequired,
    defaultWidth: PropTypes.string,
    onShare: PropTypes.func,
    sourceLinks: PropTypes.object,
    imageObj: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            route: PropTypes.string,
        })
    ),
    openDataCard: PropTypes.array.isRequired,
    setOpenDataCard: PropTypes.func.isRequired,
    teamMember: PropTypes.array,
    isTeamMemberLoading: PropTypes.bool,
    showButton: PropTypes.bool,
};

export default SpeciDataCard;