import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import './tooltip.css';
import { tooltipContentMap } from './tooltipContent';

const HighlightedText = ({ className, children }) => {
  // const content = tooltipContentMap[className];

  return (
    // <Tippy 
    //   content={content}
    //   placement="top"
    //   delay={[200, 0]} // 200ms delay before showing, 0ms delay before hiding
    //   duration={[200, 0]} // 200ms animation when showing, 0ms when hiding
    //   arrow={true}
    //   theme="custom"
    //   popperOptions={{
    //     modifiers: [{
    //       name: 'offset',
    //       options: {
    //         offset: [0, 8],
    //       },
    //     }],
    //   }}
    // >
      <span className={className}>
        {children}
      </span>
    // </Tippy>
  );
};

export default HighlightedText;