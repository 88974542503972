// Chart Graph: Ahieve module Chart Card without chart data item display list (Bar Chart)
import React from "react";
import PropTypes from "prop-types";
import './ChartGraph.css'
import { Bar } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuLabelItem } from "../../ui/DropdownMenu/DropdownMenu";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ChartGraph = ({
  chartArr = [],
  chartType,
  dropDownArray = [],
  selectedOption,
  onDropdownChange,
  defaultHeight = "500px",
  defaultWidth = "500px",
}) => {
  // Get the label axis list
  const labels = chartArr.map(item => item.label);
  
  /************************************** 1. the y-axis Horizontal Bar Chart (system overviews)   ***************************************/
  // Generate data for stacked bars
  const yAxisData = {
    labels,
    datasets: chartArr.every(item => item.components?.length >= 1) ? [
      {
        label: "Component 1",
        data: chartArr.map(item => item.components[0]?.count ?? 0),
        backgroundColor: "#9966FF",
        borderWidth: 1,
        stack: "stack1",
        maxBarThickness: 30,
      },
      {
        label: "Component 2", 
        data: chartArr.map(item => item.components[1]?.count ?? 0),
        backgroundColor: "#4BC0C0",
        borderWidth: 1,
        stack: "stack1",
        maxBarThickness: 30,
      },
      {
        label: "Component 3",
        data: chartArr.map(item => item.components[2]?.count ?? 0),
        backgroundColor: "#4dc9f6",
        borderWidth: 1,
        stack: "stack1",
        maxBarThickness: 30,
      }
    ] : [
      {
        label: "Component 1",
        data: [chartArr[0]?.count ?? 0, 0, 0],  // only display the series 1 value
        backgroundColor: "#9966FF",
        borderWidth: 1,
        stack: "stack1",
        maxBarThickness: 30,
      },
      {
        label: "Component 2",
        data: [0, chartArr[1]?.count ?? 0, 0],  // only display the series 2 value
        backgroundColor: "#4BC0C0",
        borderWidth: 1,
        stack: "stack1",
        maxBarThickness: 30,
      },
      {
        label: "Component 3",
        data: [0, 0, chartArr[2]?.count ?? 0],  // only display the series 3 value
        backgroundColor: "#4dc9f6",
        borderWidth: 1,
        stack: "stack1",
        maxBarThickness: 30,
      }
    ]
  };
  
  const yAxisOptions = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false, // allow the chart over grid all the area
    layout: {
      padding: 0,
    },
    plugins: {
      legend: { 
        display: true,
        position: 'top',
        align: 'end', // display in the end of top area
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 11,
          font: {
            size: 11, // the font size
          },
        }
      },
      tooltip: { 
        enabled: true,
        callbacks: {
          label: function(context) {
            return context.raw > 0 ? `${context.dataset.label}: ${context.raw}` : null;
          }
        }
      },
    },
    scales: {
      x: { 
        beginAtZero: true,
        stacked: true,
      },
      y: { 
        ticks: { autoSkip: false },
        stacked:  true,
      }
    }
  };

  /************************************** 2. the x-axis Bar Chart (location status overviews)   ***************************************/
  // Generate data for stacked bars
  const xAxisData = {
    labels,
    datasets: chartArr.every(item => item.Issues?.length >= 1) ? [
      {
        label: "On Hold",
        data: chartArr.map(item => item.Issues[0]?.count ?? 0),
        backgroundColor: "#f97066",
        borderWidth: 1,
        maxBarThickness: 30, // the bar width
        stack: "stack1",
      },
      {
        label: "Ongoing",
        data: chartArr.map(item => item.Issues[1]?.count ?? 0),
        backgroundColor: "#fec84b",
        borderWidth: 1,
        maxBarThickness: 30, 
        stack: "stack1",
      },
      {
        label: "Closed",
        data: chartArr.map(item => item.Issues[2]?.count ?? 0),
        backgroundColor: "#32d583",
        borderWidth: 1,
        maxBarThickness: 30, 
        stack: "stack1",
      }
    ] : [
      {
        label: "On Hold",
        data: [chartArr[0]?.count ?? 0, 0, 0],  // only display the series 1 value
        backgroundColor: "#f97066",
        borderWidth: 1,
        maxBarThickness: 30, 
        stack: "stack1",
      },
      {
        label: "Ongoing",
        data: [0, chartArr[1]?.count ?? 0, 0],  // only display the series 2 value
        backgroundColor: "#fec84b",
        borderWidth: 1,
        maxBarThickness: 30, 
        stack: "stack1",
      },
      {
        label: "Closed",
        data: [0, 0, chartArr[2]?.count ?? 0],  // only display the series 3 value
        backgroundColor: "#32d583",
        borderWidth: 1,
        maxBarThickness: 30, 
        stack: "stack1",
      }
    ]
  };

  const xAxis0ptions = {
    indexAxis: 'x',
    responsive: true,
    maintainAspectRatio: false, // allow the chart over grid all the area
    layout: {
      padding: {
        right: 3,
        left: 3,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        align: 'end', // display in the end of top area
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 12,
          font: {
            size: 11, // the font size
          },
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            return context.raw > 0 ? `${context.dataset.label}: ${context.raw}` : null;
          }
        }
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        stacked: true,
      },
      y: {
        ticks: { autoSkip: false },
        stacked: true,
      }
    }
  };

  return (
    <div className="ChartGraph" style={{ width: defaultWidth, height: defaultHeight}}>
      {/* the dropdown menu used for selection switch system */}
      <div className="chartGraphMenuArea">
        <DropdownMenu>
          <DropdownMenuTrigger height="32px">
            <span className="dropdown-menu-trigger-text">{selectedOption}</span>
          </DropdownMenuTrigger>
          <DropdownMenuContent>

            <div className="selectionMenuArea">
            {dropDownArray.map((item) => (
              <DropdownMenuLabelItem
                key={item}
                checked={selectedOption === item}
                onSelect={() => onDropdownChange(item)}
              >
                {item}
              </DropdownMenuLabelItem>
            ))}
            </div>
          </DropdownMenuContent>
        </DropdownMenu>

        <div className="detailsGraphText">View details</div>
      </div>

      <div className="chartGraphArea">
        {/* the y-axis bar chart */}
        {chartType === "yBarChart" && <Bar data={yAxisData} options={yAxisOptions} />}

        {/* the x-axis bar chart */}
        {chartType === "xBarChart" && <Bar data={xAxisData} options={xAxis0ptions} />}

      </div>
    </div>
  );
};

ChartGraph.propTypes = {
  chartArr: PropTypes.array.isRequired,
  chartType: PropTypes.string.isRequired,
  dropDownArray: PropTypes.array.isRequired,
  selectedOption: PropTypes.string.isRequired,
  onDropdownChange: PropTypes.func.isRequired,
  defaultHeight: PropTypes.string,
  defaultWidth: PropTypes.string,
};

export default ChartGraph;