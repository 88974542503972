// the customize notification component used for notice user search error:
import React from "react";
import PropTypes from "prop-types";
import './NotificationComp.css';
import Button from "../Button/Button";

const NotificationComp = ({
    errorTitle = "Error message",
    errorText,
    hasSearchBar = true,
    contactTitle, // Corrected spelling
    contactText,
    contactEmail,
    onChange,
    onKeyDown,
    onClick,
    value,
    Icon,
}) => {
    return (
        <div className= "search-input-container">
            <div className="svgIcon">
                {Icon && <Icon size={24} color="white" />}
            </div>

            <div>
                <span className="title">{errorTitle}</span>
                <br/>
                <span className="text">{errorText}</span> 
            </div>

            {hasSearchBar && <div style={{display: "flex", alignItems: "center"}}>
                <input
                    type="text"
                    value={value}
                    className="search-input"
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    placeholder="Search new occurrence"
                />
                <Button
                    className="search-button"
                    size="small"
                    variant="primary"
                    onClick={onClick}
                >
                    Search
                </Button>
            </div>}

            {(contactTitle || contactText || contactEmail) && <div>
                <span className="tittle">{contactTitle}</span>
                <br/>
                <span className="text">{contactText}</span>
                <br/>
                <span className="emailText">{contactEmail}</span>
            </div>}
        </div>
    );
};

NotificationComp.propTypes = {
    errorTitle: PropTypes.string.isRequired,
    errorText: PropTypes.string,
    hasSearchBar: PropTypes.bool,
    contactTitle: PropTypes.string,  // Corrected spelling
    contactText: PropTypes.string,
    contactEmail: PropTypes.string,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onClick: PropTypes.func,
    Icon: PropTypes.elementType,
    value: PropTypes.string,  // Changed from object to string
};

export default NotificationComp;
