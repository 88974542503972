import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import styles from "./Checkbox.module.css";

const Checkbox = forwardRef(
  (
    {
      id,
      label,
      renderLabel,
      checked,
      indeterminate,
      onChange,
      disabled,
      size = "sm",
      type = "checkbox",
      state = "default",
      supportingText,
      className,
      ...props
    },
    ref,
  ) => {
    const checkboxClass = `
    ${styles.checkbox}
    ${styles[`checkbox--${size}`]}
    ${styles[`checkbox--${type}`]}
    ${styles[`checkbox--${state}`]}
    ${disabled ? styles["checkbox--disabled"] : ""}
    ${className || ""}
  `.trim();

    return (
      <div className={checkboxClass}>
        <input
          type="checkbox"
          id={id}
          ref={ref}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          className={styles.checkbox__input}
          {...props}
        />
        <label htmlFor={id} className={styles.checkbox__label}>
          <span className={styles.checkbox__control}>
            {type === "checkbox" && checked && !indeterminate && (
              <svg className={styles.checkbox__icon} viewBox="0 0 24 24">
                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
              </svg>
            )}
            {type === "checkbox" && indeterminate && (
              <svg className={styles.checkbox__icon} viewBox="0 0 24 24">
                <path d="M19 13H5v-2h14v2z" />
              </svg>
            )}
            {type === "radio" && checked && (
              <span className={styles.radio__dot}></span>
            )}
          </span>
          <span className={styles.checkbox__text}>
            {renderLabel ? renderLabel() : <span className={styles.checkbox__label_text}>{label}</span>}
            {supportingText && (
              <span className={styles.checkbox__supporting_text}>
                {supportingText}
              </span>
            )}
          </span>
        </label>
      </div>
    );
  },
);

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  renderLabel: PropTypes.func, // allowed the cutomized render label
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(["xs", "sm", "md"]),
  type: PropTypes.oneOf(["checkbox", "radio"]),
  state: PropTypes.oneOf(["default", "hover", "focus", "disabled"]),
  supportingText: PropTypes.string,
  className: PropTypes.string,
};

Checkbox.defaultProps = {
  checked: false,
  indeterminate: false,
  onChange: () => {},
  disabled: false,
  size: "sm",
  type: "checkbox",
  state: "default",
};

Checkbox.displayName = "Checkbox";

export default Checkbox;
