import React, { useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import IssuePart from "./Issue Part/IssuePart";
import DemoKeySpecArea from "../Module Discover/Key Specifications/DemoKeySpecArea";
import { useSharedContentQuery } from '../../api/queries';
import { useAppContext } from "../../AppContext";
//import toast from 'react-hot-toast';
import "./SharedView.css"
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const SharedView = () => {
  const { "*": token } = useParams();
  const navigate = useNavigate(); // jump page
  const { 
    setSharedIssuedArray,
    sharedBy, 
    setSharedBy,
    setIsSharedView,
    setSharedQuery,
    setTimeStamp,
    setSelectedMenuItem,
    setTableViews,
  } = useAppContext();
  
  // call the backend function: to check current URL token
  const sharedContentMutation = useSharedContentQuery(token);

  // use the backend function to check current URL token was correct or not
  useEffect(() => {

    const createInitialTerms = (queryData) => {
      let terms = [];
      
      // If queryData is a string, treat it as a simple query
      if (typeof queryData === 'string' || !isNaN(Number(queryData))) {
        terms.push({
          id: Date.now().toString(),
          value: queryData,
          isIssueId: !isNaN(Number(queryData)),
          fieldKey: 'Iusse ID',
        });
        return terms;
      }

      // Handle structured query object if present
      if (queryData.query) {
        terms.push({
          id: Date.now().toString(),
          value: queryData.query,
          isIssueId: !isNaN(Number(queryData.query)),
          fieldKey: 'Iusse ID',
        });
      }

      // Only process arrays if they exist
      if (queryData.systemArr?.length) {
        terms.push(...queryData.systemArr.map(param => ({
          id: `filter-system-${param.toLowerCase().replace(/\s+/g, '-')}`,
          value: param,
          isIssueId: false,
          fieldKey: 'system',
        })));
      }

      if (queryData.statusArr?.length) {
        terms.push(...queryData.statusArr.map(param => ({
          id: `filter-status-${param.toLowerCase().replace(/\s+/g, '-')}`,
          value: param,
          isIssueId: false,
          fieldKey: 'status',
        })));
      }

      if (queryData.requestorArr?.length) {
        terms.push(...queryData.requestorArr.map(param => ({
          id: `filter-requestor-${param.toLowerCase().replace(/\s+/g, '-')}`,
          value: param,
          isIssueId: false,
          fieldKey: 'requestor',
        })));
      }

      if (queryData.keywordsArr?.length) {
        terms.push(...queryData.keywordsArr.map(param => ({
          id: `filter-keywords-${param.toLowerCase().replace(/\s+/g, '-')}`,
          value: param,
          isIssueId: false,
          fieldKey: 'keywords',
        })));
      }

      return terms;
    };


    if (!token) {
      navigate('/404Error');
      return;
    }

    sharedContentMutation.mutate(undefined, {
      onSuccess: (data) => { 
        setSelectedMenuItem("ShareViews");
        setTableViews("ShareViews"); // setup the level and current area
        setSharedIssuedArray(data.issues);
        setSharedBy(data.sharedBy);
        setSharedQuery(createInitialTerms(data.query));
        setTimeStamp(data.timeStamp);
        // console.log(data); ==> check the data recived and will display
        // if (data.query) {
        //   // based on the space split the string to save in the array
        //   const queryArray = data.query.split(' ');

        //   // travser all the sub-item, if some item all integer should add 'issue id'
        //   const updatedArray = queryArray.map((item) =>
        //     /^\d+$/.test(item) ? `Issue ID: ${item}` : item
        //   );

        //   // saved it into the display query
        //   setSharedQuery(updatedArray);
        // }

        setIsSharedView(true);
      },
      onError: () => {
        //toast.error('Invalid token');
        navigate('/404Error');
      }
    });
  }, // eslint-disable-next-line 
    [token]);

  // if the data was still loading, run the loading animation
  if (sharedContentMutation.isLoading) {
    return (
      <div className="fade-in">
        <div className="dots-loader">
          {Array.from({ length: 16 }).map((_, index) => (
            <div
              key={index}
              className="dot"
              style={{
                transform: `rotate(${index * 22.5}deg) translateY(-20px)`,
                animationDelay: `${index * 0.125}s`
              }}
            />
          ))}
        </div>
        <p style={{ marginLeft: "8px" }}>Astra was Loading shared issues...</p>
      </div>
    );
  }

  //  main render area
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="shareView">
        <IssuePart sharedBy={sharedBy} />

        <DemoKeySpecArea/>
      </div>
    </DndProvider>
  );
};

export default SharedView; 