// this is the achieve details page including all the UI display, chart and data
import React from "react";
import "./achieve.css";
import { Routes, Route, Navigate } from "react-router-dom";
import { SideBar } from "../../Components";
import { useAppContext } from "../../AppContext";
import { ChartIcon, Overview } from "../../Components/icons/General";
import Trends from "./Trends/Trends.jsx"
import Financial from "./Financial impact/Financial";
import StatusOverview from "./Status overview/StatusOverview.jsx";

// the pages below the issue overview
import LocationPage from "./Status overview/Location Page/LocationPage.jsx";
import IssueDetailsPage from "./Status overview/Issue Page/IssueDetailsPage.jsx";
import SystemPage from "./Status overview/System Page/SystemPage.jsx";
import ManufacturerPage from "./Status overview/Manufacturer Page/ManufacturerPage.jsx"

const AchieveDetails = () => {

    const { isAchieveSideBarOpen, setIsAchieveSideBarOpen } = useAppContext();

    return <div className="achieveModule">
        <SideBar
            type="achieve"
            itemObj={[
                { icon: ChartIcon, title: "Trends", path: "/achieve/trends" },
                { icon: Overview, title: "Status overview", path: "/achieve/status-overview" },
            ]}
            isOpen={isAchieveSideBarOpen}
            setIsOpen={setIsAchieveSideBarOpen}
        />

        {/* these page was below to the Achieve module, so do not need added into router.js */}
        <div className="achieveContentArea"
            style={{
                width: isAchieveSideBarOpen ? 'calc(100vw - 200px)' : 'calc(100vw - 52px)',
            }}
        >
            <Routes>
                <Route path="/" element={<Navigate to="/achieve/trends" replace />} />
                <Route path="Trends" element={<Trends />} />

                {/* Issue Overview with below pages */}
                <Route path="status-overview/*" element={<StatusOverview />}>
                    <Route path="system/:systemName" element={<SystemPage />} />
                    <Route path="query/:issueId" element={<IssueDetailsPage />} />
                    <Route path="location/:locationName" element={<LocationPage />} />
                    <Route path="manufacturer/:manufacturerName" element={<ManufacturerPage />} />
                </Route>

                <Route path="financial-impact" element={<Financial />} />
            </Routes>
        </div>
    </div>
}

export default AchieveDetails
