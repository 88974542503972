// Simple singleton for managing tooltips across the application
const TooltipManager = {
  activeTooltips: [],
  
  // Register a tooltip as active with its position and type
  register(id, type, position) {
    // Remove any existing tooltip with the same ID
    this.activeTooltips = this.activeTooltips.filter(t => t.id !== id);
    
    // Add the new tooltip info
    this.activeTooltips.push({ id, type, position });
  },
  
  // Remove a tooltip when it's hidden
  unregister(id) {
    this.activeTooltips = this.activeTooltips.filter(t => t.id !== id);
  },
  
  // Get recommended placement based on existing tooltips
  getRecommendedPlacement(defaultPlacement, type) {
    if (this.activeTooltips.length === 0) {
      return defaultPlacement;
    }
    
    // Define placement preferences based on tooltip type - prioritize top/bottom
    const placements = {
      row: ['top', 'bottom'],
      highlight: ['bottom', 'top']
    };
    
    // If there's already a tooltip of a different type showing, 
    // pick the opposite vertical placement
    const otherTypeTooltips = this.activeTooltips.filter(t => t.type !== type);
    if (otherTypeTooltips.length > 0) {
      const otherPositions = otherTypeTooltips.map(t => t.position);
      
      // If the other tooltip is on top, place this one at the bottom (and vice versa)
      if (otherPositions.includes('top')) {
        return 'bottom';
      } else if (otherPositions.includes('bottom')) {
        return 'top';
      }
      
      // Return first preferred placement for this type
      return placements[type][0] || defaultPlacement;
    }
    
    return placements[type][0] || defaultPlacement;
  }
};

export default TooltipManager; 