// thw switch button used for switch image component of key specification
import React from "react";
import PropTypes from "prop-types";
import "./SwitchButton.css";

const SwitchButton = ({ 
    Icon, 
    onClick, 
    disable = false,
    shape = "square", // default shape is square 
}) => {
    const shapeClass = shape === "circle" ? "isCircle" : "isSquare";

    return (
        <div 
            className={`switchImageButton ${shapeClass} ${disable ? 'disabled' : ''}`} 
            onClick={!disable ? onClick : undefined} 
            role="button"
            tabIndex={disable ? -1 : 0} // Ensure accessibility with keyboard
            aria-disabled={disable}
        >
            <Icon size={20} color={"#667085"}/>
        </div>
    );
};

SwitchButton.propTypes = {
    Icon: PropTypes.elementType.isRequired, // An Icon component
    disable: PropTypes.bool,
    onClick: PropTypes.func.isRequired, // Function to handle button click
    shape: PropTypes.oneOf(["square", "circle"]), // Shape of the button
};

export default SwitchButton;
