import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import Button from "../ui/Button/Button"

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/discover",
      },
      authorizationParams:{
        organization: process.env.REACT_APP_AUTH0_ORGANIZATION_ID,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE
      }
    });
  };

  return (
    <Button
      onClick={() => handleLogin()} 
      type="submit"
      variant="primary"
      size="small"
      width="100%"
    >
      Sign in.
    </Button>
  );
};