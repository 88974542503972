import React from "react";
import './FormatCard.css';
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const FormatCard = ({
    dataArray,
    hasViewDetailsButton = true, 
    chartTitle,
    defaultHeight = "500px",
    defaultWidth = "500px",
    formatArr,
}) => {
    const navigate = useNavigate();

    // the function used to jump below page of issue overview: system location Manufacturer and issue details
    const handleClick = (colValue) => {
        if (formatArr[0] === "Location") {
            navigate(`/achieve/status-overview/location/${colValue}`);
        } else if (formatArr[0] === "Manufacturer") {
            navigate(`/achieve/status-overview/manufacturer/${colValue}`);
        }
    };
    

    return (
        <div className="FormatCard" style={{ width: defaultWidth, height: defaultHeight }}>
            <div className="FormatTitleArea">
                <span className="FormatTitle">{chartTitle}</span>
                {hasViewDetailsButton && <div className="detailsText">View details</div>}
            </div>

            <div className="FormatArea">
                {/* the format header area */}
                <div className="FormatHeader">
                    {formatArr.map((col, index) => (
                        <div key={index} className="FormatColumn">
                            {col}
                        </div>
                    ))}
                </div>

                {/* the data line */}
                <div className="FormatBody">
                    {dataArray.map((row, rowIndex) => (
                        <div
                            key={rowIndex}
                            className={`FormatRow ${rowIndex % 2 === 0 ? "odd" : "even"}`}
                        >
                            {Object.values(row).map((col, colIndex) => (
                                <div 
                                    key={colIndex} 
                                    className={`FormatCell ${colIndex === 0 ? "isBlue" : ""}`}
                                    onClick={colIndex === 0 ? () => handleClick(col) : undefined}
                                >
                                    {colIndex === 2 ? `${(col * 100).toFixed(0)}%` : col}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

FormatCard.propTypes = {
    dataArray: PropTypes.array.isRequired,
    hasViewDetailsButton: PropTypes.bool,
    chartTitle: PropTypes.string,
    defaultHeight: PropTypes.string.isRequired,
    defaultWidth: PropTypes.string.isRequired,
    formatArr: PropTypes.array.isRequired,
};

export default FormatCard;
