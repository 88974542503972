import React from "react";
import './DetailsWindow.css';
import PropTypes from "prop-types";
import { Close } from "../../icons/General";
import Button from "../../ui/Button/Button";

const DetailsWindow = ({
    width,
    height,
    open,
    onClose,
    children,
}) => {

    return <div className="DetailsWindow" style={{width: width, height: height, transform: open ? "translateX(0)" : "translateX(100%)",}}>
         <Button 
            iconOnly
            size="small"
            variant="tertiary-gray"
            style={{ position: "absolute", top: 24, right: 24 }} 
            onClick={onClose}
        >
            <Close size={20} color="#475467"/>
        </Button>
        {children}
    </div>
}

DetailsWindow.propTypes = {
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.node.isRequired
}

export default DetailsWindow;