import React from 'react';
import htmlReactParser from 'html-react-parser';
import HighlightedText from './HighlightedText';

export const transformHighlight = (node) => {
  if (
    node.type === 'tag' &&
    node.name.toLowerCase() === 'highlight' // ==> please be careful, it will be a node
  ) {
    const className = node.attribs?.class || '';  
    const content = node.children
      ? node.children.map((child) =>
          child.type === 'text' ? child.data : htmlReactParser(child.data || '')
        )
      : [];

    return <HighlightedText className={className}>{content}</HighlightedText>;
  }
};

// Helper function to parse content
export const parseWithHighlight = (content) => {
  if (!content || typeof content !== 'string') return null;
  try {
    return htmlReactParser(content, { replace: transformHighlight });
  } catch (error) {
    console.error('Error parsing content:', error);
    return content; //if sync error, return it
  }
};

