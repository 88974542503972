import React, {useState, useEffect} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import "./SideBar.css";
import { SideBarIcon, AddModule } from "../../icons/General";

const SideBar = ({
  type,
  itemObj = [],
  historyList = {},
  isOpen,
  setIsOpen,
}) => {

  const navigate = useNavigate(); // used for jump to othe page
  const location = useLocation(); // get current url location
  const [selectionItem, setSelectionItem] = useState(""); 

  // listener the URL and get which one was clicked
  useEffect(() => {
    // the location, system, and manufacturer also highlight the issue overview item
    if (location.pathname.includes("/achieve/status-overview")) {
      setSelectionItem("Status overview");
    } else {
      const matchedItem = itemObj.find((item) => location.pathname.includes(item.path));
      if (matchedItem) {
        setSelectionItem(matchedItem.title);
      }
    }
  }, [location.pathname, itemObj]);

  // the function of achieve side bar open and closed
  const toggleSideBar = () => {
    setIsOpen(!isOpen);
  };

  // the function used to select of side bar
  const handleSelectionItem = (value, path) => {
    setSelectionItem(value);
    navigate(path);
  }

  return (
    <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
      {/* SideBar Toggle Icon */}
      <div className="sidebar-toggle-icon" onClick={toggleSideBar}>
        <SideBarIcon size={24} color="#667085" />
      </div>

      {/* Achieve module SideBar Items */}
      {type === "achieve" && <div className="sidebar-items">
        {/* Achieve module SideBar Items */}
        {type === "achieve" && (
          <div className="sidebar-items">
            {itemObj.map((item, index) => (
              <div
                className={`sidebar-item ${selectionItem === item.title ? "isClicked" : ""}`}
                key={index}
                onClick={() => handleSelectionItem(item.title, item.path)}
              >
                <div className="itemIcon">
                  <item.icon size={24} color="#667085" />
                </div>
                {isOpen && <div className="item-title">{item.title}</div>}
              </div>
            ))}
          </div>
        )}
      </div>}

      {/* Discover module SideBar Items */}
      {type === "discover" && <div className="sidebar-items">
        <div className="sidebar-item">
          <div className="itemIcon"> <AddModule size={24} color="#667085" /> </div>
          {isOpen && <div className="item-title">New Search</div>}
        </div>
        {/* History List */}
        {isOpen && (
          <div className="history-list">
            {Object.entries(historyList).map(([key, values], index) => (
              <div className="history-section" key={index}>
                <div className="history-title">{key}</div>

                <div className="history-items">
                  {values.map((item, idx) => (
                    <span key={idx} className="history-item">
                      {item}
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>}
    </div>
  );
};

SideBar.propTypes = {
  type: PropTypes.string.isRequired,
  itemObj: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.elementType.isRequired, // React component for the icon
      title: PropTypes.string.isRequired,    // Title of the item
    })
  ).isRequired,
  historyList: PropTypes.array, // History list for additional functionality (future use)
  setIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  selectionItem: PropTypes.string,
  setSelectionItem: PropTypes.func,
};

export default SideBar;
