import React from "react";
import "./ErrorPage.css"
import { useNavigate } from "react-router-dom";
import { Button, ModuleCard } from "../../Components";

import AchieveIcon from "../../Components/icons/General/AchieveModuleIcon";
import DriveIcon from "../../Components/icons/General/DriveModule";
import DiscoverIcon from "../../Components/icons/General/DiscoverModuleIcon";

const ErrorPage = () => {

    const navigate = useNavigate();

    return <div className="ErrorPage">
        <div className="textArea">
            <span className="redError"> 404 Error </span>
            <span className="tittleError">We lost this page</span>
            <span className="contextError">
                We searched high and low, but couldn't find what you're looking for. <br/>
                Let's find a better place for you to go.
            </span>
        </div>

        <div className="buttonArray">
            <Button 
                variant="secondary" 
                size="medium" 
                width={"90px"}
                onClick={() => {
                    navigate('/')
                }}
            >
                Go Back
            </Button>

            <Button 
                variant="primary" 
                size="medium" 
                width={"80px"}
                onClick={() => {
                    navigate('/')
                }}
            >
                Home
            </Button>
        </div>


        <div className="cardArray">
            <ModuleCard
                Icon={AchieveIcon}
                title="Achieve Module"
                context="Milestone progress analytics dashboard."
                actionText="Learn more"
                navigate={navigate}
            />

            <ModuleCard
                Icon={DiscoverIcon}
                title="Discover Module"
                context="Dive in to find relevant issues."
                actionText="Start searching"
                navigate={navigate}
            />

            <ModuleCard
                Icon={DriveIcon}
                title="Drive Module"
                context="Decision Making & Scenario Modeling."
                actionText="Learn more"
                navigate={navigate}
            />
        </div>
    </div>
}

export default ErrorPage;