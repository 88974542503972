import React from "react";
import './StatusOverview.css'
import { useAppContext } from "../../../AppContext";
import { ChartCard, FormatCard, ChartGraph } from "../../../Components";
import { ChevronRight } from "../../../Components/icons/Arrows";

// all the .json data
import locationFormatData from "../../../datasets/achieveData/locationFormat.json";
import manufacturerFormatData from "../../../datasets/achieveData/manufacturerFormat.json"
import StatusData from "../../../datasets/achieveData/status.json";
import AircraftData from "../../../datasets/achieveData/aircraft.json";
import LocationData from "../../../datasets/achieveData/location.json";
import ManufacturerData from "../../../datasets/achieveData/manufacturer.json";
import systemData from "../../../datasets/achieveData/system.json";
import locationStatus from "../../../datasets/achieveData/location_status.json";

const DefaultIssueOverview = () => {

    // const { systemSelection,
    //     setSystemSelection, milestioneSelection, setMilestoneSelection,
    // dailySelection, setDailySelection, timeLineSelection, settimeLineSelection } = useAppContext();

    // get the states from appcontext api
    const { selectedOverviewOption, setSelectedOverviewOption, 
        selectedSystemOption, setSelectedSystemOption,
        selectedLocationOption, setSelectedLocationOption
    } = useAppContext();

    // 1. Overview Chart Card: chart selection filter data function including Status Aircraft Manufacturer Location
    const getChartData = () => {
        switch (selectedOverviewOption) {
            case "Status":
                return StatusData;
            case "Aircraft":
                return AircraftData;
            case "Manufacturer":
                return ManufacturerData;
            case "Location":
                return LocationData;
            default:
                return StatusData;
        }
    };

    //2. System Chart Card: chart selection filter data function including difference systems
    const getSystemChartData = () => {
        switch (selectedSystemOption) {
          case "Systems overview":
            return systemData;
          default:
                const filterObj = systemData.find(item => item.label === selectedSystemOption);
                return filterObj.components;
        }
    };


    // 3. Location Status Chart Card: chart selection filter data function including difference locations 
    const getLocationData = () => {
        switch (selectedLocationOption) {
            case "Location overview":
                return locationStatus;
            default:
                const filterObj = locationStatus.find(item => item.label === selectedLocationOption);
                return filterObj.Issues;
        }
    };

    // the main render area
    return (
        <>
            <div className="pageRoute">
                <button className={`routeButton DisableClicked`}>Achieve</button>
                <ChevronRight size={16} color="#D0D5DD" />
                <button className={`routeButton isClicked`}> Status overview </button>
            </div>

            <div className="issueDetailsTitleArea">
                <div className="issueDetailsTitleAreaLeft">
                    <span className="pageTitle"> Status Overview </span>
                    <span className="pageSubTitle">Gain valuable insights into issues and drive impact. </span>
                </div>
            </div>

            <div className="chartOverview">
                <ChartCard
                    chartType="doughnut"
                    dropDownArray={["Status", "Aircraft", "Manufacturer", "Location"]}
                    chartArr={getChartData()} // dynamic change based user difference selection
                    selectedOption={selectedOverviewOption} // the selection of dropdown menu
                    onDropdownChange={setSelectedOverviewOption} // the update function of selction
                    defaultWidth="400px"
                    defaultHeight="475px"
                />

                <ChartGraph
                    chartType="yBarChart"
                    dropDownArray={systemData ? ["Systems overview", ...systemData.map(item => item.label)] : []}
                    chartArr={getSystemChartData()}
                    selectedOption={selectedSystemOption} // the selection of dropdown menu
                    onDropdownChange={setSelectedSystemOption} // the update function of selction
                    defaultWidth="calc(100% - 412px)"
                    defaultHeight="475px"
                />
            </div>

            <div className="locationChart">
                <ChartGraph
                    chartType="xBarChart"
                    dropDownArray={locationStatus ? ["Location overview", ...locationStatus.map(item => item.label)] : []}
                    chartArr={getLocationData()}
                    selectedOption={selectedLocationOption} // the selection of dropdown menu
                    onDropdownChange={setSelectedLocationOption} // the update function of selction
                    defaultWidth="100%"
                    defaultHeight="470px"
                />
            </div>

            <div className="issueOverviewChart">
                <FormatCard
                    chartTitle="Breakdown of issue by System supplier"
                    defaultHeight="475px"
                    defaultWidth="50%"
                    hasViewDetailsButton={true}
                    formatArr={["Manufacturer", "Issues", "Ratio"]}
                    dataArray={manufacturerFormatData}
                />

                <FormatCard
                    chartTitle="Breakdown of issue by location"
                    defaultHeight="475px"
                    defaultWidth="50%"
                    hasViewDetailsButton={true}
                    formatArr={["Location", "Issues", "Ratio"]}
                    dataArray={locationFormatData}
                />
            </div>
        </>
    )
}

export default DefaultIssueOverview;