// this is the query details will using for 
// 1. Shared Saved Hideden and Starred Button
// 2. the Shared (read-only) page for other user read
import React from "react";
import PropTypes from "prop-types";
import "./QueryHeader.css";
import { Badge } from "../../ui/Badge";

const QueryHeader = ({
    timestamp,
    queryArray = [],
    teamArray = [],
    tableViews, // shared to someone layout
    isSharedViews = false, // get this from someone layout
    fromUserName,
    fromUserEmail,
    defaultWidth = "650px", 
}) => {
    return (
        <div className="queryHeaderArea" style={{ width: defaultWidth }}>
            <div className="queryDetails">
                <div className="queryList">
                    <span className="title"> Query: </span>
                    {queryArray.map((item, index) => (
                        <Badge key={index} type="defaultGray">
                            {`${item.fieldKey}: ${item.value}`}
                        </Badge>
                    ))}
                </div>
                <div style={{display: "flex", alignItems: "center", gap: "6px"}}>
                    <span className="title"> {tableViews === "Shared" ? "Created At:" : "Last Updated:"}</span>
                    <span className="subText">{timestamp}</span>
                </div>
            </div>

            {(isSharedViews) && <div className="queryDetails">
                <p className="sharedTitle" style={{marginTop: "3px"}}>Shared by:</p>
                <p className="subText" style={{marginTop: "3px"}}>{fromUserName} <span className="emailText">{fromUserEmail}</span> </p>
            </div>}

            {(!isSharedViews && tableViews === "Shared") && <div className="queryDetails">
                <p className="sharedTitle" style={{marginTop: "3px"}}>Shared with:</p>
                <p className="subText" style={{marginTop: "3px"}}> 
                    {teamArray.map((user, index) => (
                        <span key={index}>
                            {user.first_name + " " + user.last_name}{index < teamArray.length - 1 && ', '}
                        </span>
                    ))}
                </p>
            </div>}
        </div>
    );
};

QueryHeader.propTypes = {
    timestamp: PropTypes.string,
    queryArray: PropTypes.array.isRequired,
    teamArray: PropTypes.array,
    isShared: PropTypes.bool,
    isSharedViews: PropTypes.bool,
    fromUserName: PropTypes.string,
    fromUserEmail: PropTypes.string,
    defaultWidth: PropTypes.string,
};

export default QueryHeader;