// this is the team page to display all the team member information
import React from 'react';
import './TeamPage.css';
import { 
  useOrganizationUsersQuery
} from '../../../api/queries';

const TeamPage = () => {

  const { data: members = [], isLoading: isTeamMemberLoading } = useOrganizationUsersQuery();

  return (
    <div className="team-table-container">
      <span className='teamTitle'>Team members</span>
      <div className="team-table-wrapper ">
        <table className="team-table">
          <thead>
            <tr>
              <th>Name</th>
              {/* <th>Role</th> */}
              <th>Email Address</th>
            </tr>
          </thead>

          <tbody>
            {members.map((member, index) => (
              <tr key={index}>
                <td>
                  <div className="member-info">
                    <div className="avatar">
                      {member.initials || (member.name ? member.name.split(' ').map(word => word[0]).join('').toUpperCase() : '?')}
                    </div>
                    <div>
                      <span className="member-name">{member.name}</span>
                      {member.groupRole && <span className="groupRole-badge">{member.groupRole}</span>}
                    </div>
                  </div>
                </td>
                {/* <td>{member.role}</td> */}
                <td>{member.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TeamPage;
