import React, { useState } from "react";
import './FormatCompon.css';
import PropTypes from "prop-types";
import { ChevronLeft, ChevronRight } from "../../icons/Arrows";
import SwitchButton from "../../keySpecs/SwitchImageButton/SwitchButton";
import { Badge } from "../../ui/Badge";

const FormatCompon = ({
    width,
    height,
    data,
    columns,
    rowHeight,
    selectedAction,  //user selected action row
    flex = 0,
}) => {
    // the main format start and end Item
    const [currPage, setCurrPage] = useState(1);
    const [rowPage, setRowPage] = useState(25); // control how many rows in one page
    const maxResult = Object.keys(data).length;
    const startItem = (currPage - 1) * rowPage;
    const endItem = Math.min(currPage * rowPage, maxResult);

    // switch to next page
    const handleNextPage = () => {
        setCurrPage(currPage => currPage + 1);
    }

    // switch to previous page
    const handlePrevPage = () => {
        setCurrPage(currPage => currPage - 1);
    }

    // get the nested format status badge color
    const getBadgeStatusColor = (label) => {
        switch (label) {
            case "Investigating":
                return "Current";
            case "Closed":
                return "Closed";
            case "Open":
                return "Open";
            default:
                return "Other";
        }
    }

    // the nested format states:
    const [currNestedPage, setCurrNestedPage] = useState(1);
    const [nestedRowPage, setNestedRowPage] = useState(10); // control how many rows in one page
    
    // switch to next page for nested table
    const handleNestedNextPage = () => {
        setCurrNestedPage(currNestedPage => currNestedPage + 1);
    }

    // switch to previous page for nested table
    const handleNestedPrevPage = () => {
        setCurrNestedPage(currNestedPage => currNestedPage - 1);
    }

    // the nestedColumn Render and customized UI
    const nestedDataColumn = [
        {
            key: "key",
            label: "Issue Key",
            width: "18%",
            render: (value, row) => (
                <div className="product-cell">
                    {value}
                    <Badge type="status" variant={getBadgeStatusColor(row.Status)}>
                        {row.Status}
                    </Badge>
                </div>
            ),
        },
        {
            key: "Failure Mode",
            label: "Failure Mode",
            width: "37%",
        },
        {
            key: "Cost",
            label: "Cost",
            width: "12%",
            render: (value) => (
                <span>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value)}</span>
            ),
        },
        {
            key: "Location",
            label: "Location",
            width: "10%",
            render: (value) => (
                <span style={{color: "#1570EF"}}>{value}</span>
            ),
        },
        {
            key: "Last activity",
            label: "Last activity",
            width: "14%",
        },
        {
            key: "Frequency",
            label: "Frequency",
            width: "8%",
        }
    ];

    return (
        <div className="fortmatCompoent" style={{ minWidth: width, maxWidth: width, minHeight: height, maxHeight: height, flex: flex}}>
            {/* Table Header */}
            <div className="fortmatCompoent-header">
                {columns.map((col) => (
                    <div
                        key={col.key}
                        className="fortmatCompoentHeader-cell"
                        style={{ minWidth: col.width, maxWidth: col.width }}
                    >
                        {col.label}
                    </div>
                ))}
            </div>

            {/* Table Body */}
            <div className="formatCompon-body">
                {Object.values(data).slice(startItem, endItem).map((row, rowIndex) => (
                    <div key={rowIndex} className="rowsArea">
                        <div
                            className={`formatCompon-row ${rowIndex % 2 === 0 ? "even" : "odd"}`}
                            style={{ height: rowHeight, position: "relative" }}
                        >
                            {columns.map((col) => (
                                <div
                                    key={col.key}
                                    className="formatCompon-cell"
                                    style={{ minWidth: col["width"], maxWidth: col["width"] }}
                                >
                                    <span>{col.render ? col.render(row[col.key], row) : String(row[col.key])}</span>
                                </div>
                            ))}
                        </div>

                        {/* Display the dropdown area if this row is expanded */}
                        {row["Action taken"] === selectedAction && row.Occurrences && (
                            <div className="dropdown-menu">
                                <div className="nestedFormat">
                                    {/* Table Header */}
                                    <div className="nestedFormat-header">
                                        {nestedDataColumn.map((col) => (
                                            <div
                                                key={col.key}
                                                className="nestedFormatHeader-cell"
                                                style={{ minWidth: col["width"], maxWidth: col["width"] }}
                                            >
                                                {col.label}
                                            </div>
                                        ))}
                                    </div>

                                    {/* Table Body */}
                                    <div className="nestedFormat-body">
                                        {/* Fix: Use proper nested pagination variables */}
                                        {(() => {
                                            const maxNestedResult = row.Occurrences.length;
                                            const startNestedItem = (currNestedPage - 1) * nestedRowPage;
                                            const endNestedItem = Math.min(currNestedPage * nestedRowPage, maxNestedResult);
                                            
                                            return row.Occurrences.slice(startNestedItem, endNestedItem).map((nestedRow, nestedRowIndex) => (
                                                <div
                                                    key={nestedRowIndex}
                                                    className={`nestedFormat-row ${nestedRowIndex % 2 === 0 ? "even" : "odd"}`}
                                                    style={{ height: rowHeight}}
                                                >
                                                    {nestedDataColumn.map((col) => (
                                                        <div
                                                            key={col.key}
                                                            className="formatTable-cell"
                                                            style={{ minWidth: col["width"], maxWidth: col["width"] }}
                                                        >
                                                            <span>{col.render ? col.render(nestedRow[col.key], nestedRow) : String(nestedRow[col.key])}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            ));
                                        })()}
                                    </div>

                                    {/* Fix: Use proper nested pagination variables for footer */}
                                    {(() => {
                                        const maxNestedResult = row.Occurrences.length;
                                        const startNestedItem = (currNestedPage - 1) * nestedRowPage;
                                        const endNestedItem = Math.min(currNestedPage * nestedRowPage, maxNestedResult);
                                        
                                        return maxNestedResult > nestedRowPage && (
                                            <div className="nestedFormatCompon-footer">
                                                <div className="nestedFormatCompon-left">
                                                    {startNestedItem + 1} - {endNestedItem} of {maxNestedResult} Result
                                                </div>
                                                <div className="nestedFormatCompon-right">
                                                    <SwitchButton
                                                        Icon={ChevronLeft}
                                                        onClick={handleNestedPrevPage}
                                                        shape="circle"
                                                        disable={currNestedPage <= 1}
                                                    />
                                                    <SwitchButton
                                                        Icon={ChevronRight}
                                                        onClick={handleNestedNextPage}
                                                        shape="circle"
                                                        disable={endNestedItem >= maxNestedResult}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })()}
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {/* Table Footer */}
            <div className="formatCompon-footer">
                <div className="formatCompon-left">
                    {startItem + 1} - {endItem} of {maxResult} Result
                </div>
                <div className="formatCompon-right">
                    <SwitchButton
                        Icon={ChevronLeft}
                        onClick={handlePrevPage}
                        shape="circle"
                        disable={currPage <= 1}
                    />
                    <SwitchButton
                        Icon={ChevronRight}
                        onClick={handleNextPage}
                        shape="circle"
                        disable={endItem >= maxResult}
                    />
                </div>
            </div>
        </div>
    );
};

FormatCompon.propTypes = {
    width: PropTypes.string.isRequired,
    height: PropTypes.string,
    data: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            width: PropTypes.string.isRequired,
        })
    ).isRequired,
    rowHeight: PropTypes.string.isRequired,
    selectedAction: PropTypes.string,
    flex: PropTypes.number,
};

export default FormatCompon;
